
import axios from 'axios';
import { logAction } from '../utils/logger';
const API_URL = `${process.env.REACT_APP_API_BASE_URL}/api/SystemLogs`;

export const logChange = async (logData) => {
  
  try {
    await axios.post(API_URL, logData, {
      headers: { 'Content-Type': 'application/json' },
    });
  } catch (error) {
    console.error('Logging error:', error);
  }
};
