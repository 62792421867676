import React, { useState } from "react";

export default function Autocomplete({
  options,
  label,
  setValue,
  name,
  error,
  value,
}) {
const [selectedValue,setSelectedValue] = useState(value)
const changeSelectedItem = (e)=>{
  setSelectedValue(e.target.value)
  setValue(name,e.target.value)
}
  return (
    <div className="flex flex-col gap-2">
      <label className="text-sm font-medium font-sans text-main">
        {label}
        <span className="text-primary">*</span>
      </label>
      <select
        value={selectedValue}
        onChange={changeSelectedItem}
        className="w-full  p-2 border border-gray-300 rounded">
        <option>
          Select an option
        </option>
        {options.map((option, i) => (
          <option key={i} value={option.id} className="bodyLarge p-2">
            {option.name}
          </option>
        ))}
      </select>
      {error ? <p className="text-red-600 body2">{error}</p> : ""}
    </div>
  );
}
