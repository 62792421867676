import React from 'react';
import Table from '../common/Table';
import Dropdown from "../Dropdown/dropDown"

const columns = [
  { Header: 'Name', accessor: 'name' }

];

const RolesTable = ({ roles = [], openEditModal, deleteRole,openPermissions }) => {
  const renderRowActions = ({ id }) => (
    <div className='z-[1000]'>
    <Dropdown permissions={true}  onEdit={()=>openEditModal(id)} onDelete={()=>deleteRole(id) } openPermissions={openPermissions} id={id} />
  </div>
  );

  return (
    <Table
      columns={columns}
      data={roles}
      renderRowActions={renderRowActions}
    />
  );
};

export default RolesTable;
