import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

// Existing keyframes
const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const slideIn = keyframes`
  from { transform: translateX(-100%); }
  to { transform: translateX(0); }
`;

const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

const glow = keyframes`
  0% { box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); }
  50% { box-shadow: 0 0 20px rgba(0, 123, 255, 0.8); }
  100% { box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); }
`;

// New keyframe for subtle background animation
const subtleBackground = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

// Styled components with animations
export const AnimatedSidebar = styled.div`
  animation: ${fadeIn} 0.3s ease-out, ${slideIn} 0.3s ease-out;
`;

export const AnimatedMenuItem = styled.div`
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: translateX(5px);
  }
`;

export const AnimatedIcon = styled.span`
  transition: all 0.2s ease-in-out;
  &:hover {
    animation: ${pulse} 0.5s infinite;
  }
`;

export const AnimatedDropdown = styled.div`
  max-height: ${props => (props.isOpen ? '1000px' : '0')};
  overflow: hidden;
  transition: max-height 0.6s ease-in-out, opacity 0.9s ease;
  opacity: ${props => (props.isOpen ? '1' : '0')};
`;

export const AnimatedArrow = styled.span`
  transition: transform 0.3s ease-in-out;
  transform: ${props => props.isOpen ? 'rotate(180deg)' : 'rotate(0deg)'};
`;

export const SelectedItem = styled.div`
  background-color: rgba(0, 123, 255, 0.1);
  border-radius: 4px;
 
`;

// New styled component for selected dropdown item
export const SelectedDropdownItem = styled.div`
  background: linear-gradient(270deg, rgba(0, 123, 255, 0.2), rgba(0, 123, 255, 0.1));
  background-size: 200% 200%;
  animation: ${subtleBackground} 3s ease infinite;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: translateX(5px);
    background: linear-gradient(270deg, rgba(0, 123, 255, 0.3), rgba(0, 123, 255, 0.2));
  }
`;

// New styled component for dropdown item hover effect
export const AnimatedDropdownItem = styled.div`
  transition: all 0.2s ease-in-out;
  
  &:hover {
    transform: translateX(5px);
    background-color: rgba(0, 123, 255, 0.05);
  }
`;