import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { FaPlus } from "react-icons/fa6";
import { FaFilter } from "react-icons/fa";
import { splitWordFunc } from "../../utils/splitWordFunc";
import { FiSearch } from "react-icons/fi";
import { customStyles } from "../../styles/customStyles";
import HelperTypesForm from "../../components/forms/helperTypeF";
import HelperTypesTable from "../../components/tables/helperTypeT";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import {
  addHelperTypes as addHelperTypesService,
  getHelperTypesById,
  updateHelperTypes,
  deleteHelperTypes,
  getHelperTypes,
} from "../../services/helperTypeS";
import Layout from "../../components/Layout/layout";

Modal.setAppElement("#root");

const AddHelperTypes = () => {
  const [helpertypes, setHelperTypes] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newHelperTypes, setNewHelperTypes] = useState({
    typeID: "",
    typeName: "",
    notes: "",
  });
  const [selectedColumn, setSelectedColumn] = useState("name");
  const [filteredHelperTypes, setFilteredHelperTypes] = useState(helpertypes);
  const [searchTerm, setSearchTerm] = useState("");
  const [update,setUpdate] = useState(false)
  const [errors, setErrors] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [selectedHelperTypesID, setSelectedHelperTypesID] = useState(null);

  useEffect(() => {
    const fetchedhelpertypes = async () => {
      try {
        const fetchedHelperTypes = await getHelperTypes();
        setHelperTypes(fetchedHelperTypes);
        setFilteredHelperTypes(fetchedHelperTypes);
      } catch (error) {
        toast.error("Error fetching helpertypes:", error.response.data);
      }
    };

    fetchedhelpertypes();
  }, [update]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewHelperTypes((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setNewHelperTypes((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const addNewHelperTypes = async () => {
    try {
      const helpertypesPayload = {
        typeID: String(newHelperTypes.typeID), // Ensure HelperTypesID is a string
        typeName: newHelperTypes.typeName,
        notes: newHelperTypes.notes,
      };

      const addedhelpertypes = await addHelperTypesService(helpertypesPayload);
      setUpdate((prev)=>!prev)
      setIsModalOpen(false);
    } catch (error) {
      toast.error("Error adding helpertypes:", error.response.data);
      setErrors(error.response.data.errors || {});
      alert(
        `Failed to add helpertypes: ${
          error.response.data.title
        }\nDetails: ${JSON.stringify(error.response.data.errors, null, 2)}`
      );
    }
  };

  const openAddHelperTypesModal = () => {
    setEditMode(false);
    setIsModalOpen(true);
    setNewHelperTypes({
      typeID: "",
      typeName: "",
      notes: "",
    });
  };

  const openEditHelperTypesModal = async (typeID) => {
    try {
      const fetchedHelperTypes = await getHelperTypesById(
        String(typeID)
      );
      setEditMode(true);
      setIsModalOpen(true);
      setSelectedHelperTypesID(typeID);
      setNewHelperTypes({
        ...fetchedHelperTypes,
      });
    } catch (error) {
      toast.error(
        `Error fetching helpertypes with ID ${typeID}:`,
        error.response.data
      );
    }
  };

  const updateExistingHelperTypes = async () => {
    try {
      const helpertypesPayload = { ...newHelperTypes };

      const updatedHelperTypes = await updateHelperTypes(
        selectedHelperTypesID,
        helpertypesPayload
      );
      setHelperTypes((prev) =>
        prev.map((inst) =>
          inst.typeID === selectedHelperTypesID ? updatedHelperTypes : inst
        )
      );
      setIsModalOpen(false);
    } catch (error) {
      toast.error(
        `Error updating helpertypes with ID ${selectedHelperTypesID}:`,
        error.response.data
      );
      setErrors(error.response.data.errors || {});
      alert(
        `Failed to update helpertypes: ${
          error.response.data.title
        }\nDetails: ${JSON.stringify(error.response.data.errors, null, 2)}`
      );
    }
  };

  const deleteExistingHelperTypes = async (typeID) => {
    try {
      await deleteHelperTypes(typeID);
      setUpdate((prev)=>!prev)
    } catch (error) {
      toast.error(
        `Error deleting helpertypes with ID ${typeID}:`,
        error.response.data
      );
      alert(`Failed to delete helpertypes: ${error.response.data.title}`);
    }
  };

  const closeAddHelperTypesModal = () => {
    setIsModalOpen(false);
    setEditMode(false);
    setErrors({});
  };

  const deleteHelperTypesHandler = (typeID) => {
    if (
      window.confirm(
        `Are you sure you want to delete helpertypes with ID ${typeID}?`
      )
    ) {
      deleteExistingHelperTypes(typeID);
    }
  };
   //Search
   const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    filterHelpertypes(value, selectedColumn);
  };
  const handleFilterParameterChange = (event) => {
    const value = event.target.value;
    setSelectedColumn(value);
    filterHelpertypes(searchTerm, value);
  };

  const filterHelpertypes = (searchTerm, column) => {
    const filtered = helpertypes.filter((helpertype) =>
      helpertype[column]
        .toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
    setFilteredHelperTypes(filtered);
  };

  return (
    <Layout>
  <div className="bg-white rounded-lg shadow-lg">
    <div className="px-6 py-4 border-b border-gray-200">
      <h1 className="text-2xl font-semibold text-gray-800">Helper Types</h1>
    </div>

    <div className="p-6">
      <div className="space-y-4 md:space-y-0 md:flex md:items-center md:justify-between mb-6">
        <button
          onClick={openAddHelperTypesModal}
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-all duration-200"
        >
          <FaPlus className="mr-2 h-4 w-4" />
          <span>Type</span>
        </button>

        <div className="flex flex-col md:flex-row gap-4 flex-1 md:max-w-2xl">
          <div className="relative flex-1">
            <select
              className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-md bg-white shadow-sm"
              value={selectedColumn}
              onChange={handleFilterParameterChange}
            >
              {helpertypes.length > 0 ? (
                Object.keys(helpertypes[0])?.map((op) => (
                  <option key={op} value={op}>
                    {splitWordFunc(op)}
                  </option>
                ))
              ) : (
                <option>No filter property</option>
              )}
            </select>
            <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <FaFilter className="h-4 w-4 text-gray-400" />
            </div>
          </div>

          <div className="relative flex-1">
            <input
              type="text"
              placeholder="Search..."
              onChange={handleSearchChange}
              className="block w-full pl-3 pr-10 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
            <div className="absolute inset-y-0 right-0 flex items-center pr-3">
              <FiSearch className="h-5 w-5 text-gray-400" />
            </div>
          </div>
        </div>
      </div>

      <HelperTypesTable
        helpertypes={filteredHelperTypes}
        openEditModal={openEditHelperTypesModal}
        deleteHelperTypes={deleteHelperTypesHandler}
      />
    </div>

    <Modal
      style={customStyles}
      isOpen={isModalOpen}
      onRequestClose={closeAddHelperTypesModal}
      contentLabel={editMode ? "Edit Helper Types" : "Add Helper Types"}
    >
      <h2 className="text-xl font-semibold text-gray-800 mb-4">
        {editMode ? "Edit Helper Types" : "Add Helper Types"}
      </h2>
      <HelperTypesForm
        formValues={newHelperTypes}
        handleInputChange={handleInputChange}
        handleDateChange={handleDateChange}
        errors={errors}
      />
      <div className="flex justify-end mt-4">
        <button
          onClick={editMode ? updateExistingHelperTypes : addNewHelperTypes}
          className="bg-indigo-600 text-white px-5 py-2 rounded mr-2 transition-all duration-200 hover:bg-indigo-700"
        >
          {editMode ? "Update" : "Save"}
        </button>
        <button
          onClick={closeAddHelperTypesModal}
          className="border border-gray-300 text-gray-700 px-5 py-2 rounded hover:bg-gray-100 transition-all duration-200"
        >
          Cancel
        </button>
      </div>
    </Modal>
  </div>
</Layout>

  );
};

export default AddHelperTypes;
