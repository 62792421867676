import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import axios from "axios"
import { FaPlus } from "react-icons/fa6";
import { FaFilter } from "react-icons/fa";
import { splitWordFunc } from "../../utils/splitWordFunc";
import { FiSearch } from "react-icons/fi";
import { customStyles } from '../../styles/customStyles';
import ParticipantActivityForm from '../../components/forms/ajsF';
import ParticipantActivityTable from '../../components/tables/aJsT';
import { addParticipantActivity, getParticipantActivityById, updateParticipantActivity, deleteParticipantActivity, getParticipantActivity } from '../../services/ajS';
import Layout from '../../components/Layout/layout';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 

Modal.setAppElement('#root');

const AddParticipantActivity = () => {
  const [ParticipantActivity, setParticipantActivity] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [update,setUpdate] = useState(false)
  const [newParticipantActivity, setNewParticipantActivity] = useState({ 
    adminNumber: '',
    studentName: '',
    awardLevel: '',    
    awardCenter: '',
    activityName: '',
    notes: '',
  });
  const [selectedColumn, setSelectedColumn] = useState("name");
  const [filteredParticipantActivity, setFilteredParticipantActivity] = useState(ParticipantActivity);
  const [searchTerm, setSearchTerm] = useState("");
  const [errors, setErrors] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [selectedID, setSelectedID] = useState(null);

  useEffect(() => {
    const fetchParticipantActivity = async () => {
      try {
        const fetchedParticipantActivity = await getParticipantActivity();
        setParticipantActivity(fetchedParticipantActivity);
        setFilteredParticipantActivity(fetchedParticipantActivity);
      } catch (error) {
        toast.error('Error fetching ParticipantActivity:', error.response?.data);
      }
    };

    fetchParticipantActivity();
  }, [update]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewParticipantActivity((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setNewParticipantActivity((prevData) => ({
      ...prevData,
      [name]: files[0], 
    }));

  };

  const addNewParticipantActivity = async () => {
    try {
    
      const formData = new FormData()
      formData.append("AdminNumber", newParticipantActivity.adminNumber);
      formData.append("StudentName", newParticipantActivity.studentName);
      formData.append("AwardLevel", newParticipantActivity.awardLevel);
      formData.append("AwardCenter", newParticipantActivity.awardCenter);
      formData.append("ActivityName", newParticipantActivity.activityName);
      formData.append("MedicalForm", newParticipantActivity.medicalForm);
      formData.append("Notes", newParticipantActivity.notes);

      const addedParticipantActivity = await axios.post(process.env.REACT_APP_API_BASE_URL + "/api/ParticipantActivity", formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
     
      setUpdate(prev=>!prev)
      setFilteredParticipantActivity(prev => [...prev, addedParticipantActivity]);
      setIsModalOpen(false);
      setErrors({});
    } catch (error) {
      toast.error('Error adding Participant Activity:', error);
      setErrors(error.response?.data.errors || {});
      alert(`Failed to add Participant Activity: ${error.response?.data.title}\nDetails: ${JSON.stringify(error.response?.data.errors, null, 2)}`);
    }
  };

  const openAddParticipantActivityModal = () => {
    setEditMode(false);
    setIsModalOpen(true);
    setNewParticipantActivity({
       
      adminNumber: '',
      studentName: '',
      awardLevel: '',    
      awardCenter: '',
      activityName: '',
      medicalForm: '',
      notes: '',
    });
  };

  const openEditParticipantActivityModal = async (ParticipantActivity) => {
    try {
      const fetchedParticipantActivity = await getParticipantActivityById(ParticipantActivity.id);
      setEditMode(true);
      setIsModalOpen(true);
      setSelectedID(ParticipantActivity.id);
      setNewParticipantActivity({
        ...fetchedParticipantActivity,
      });
    } catch (error) {
      toast.error(`Error fetching ParticipantActivity with ID ${ParticipantActivity.id}:`, error.response?.data);
    }
  };
  

  const updateExistingParticipantActivity = async (ID) => {
    try {
      const ParticipantActivityPayload = { ...newParticipantActivity };
      const updatedParticipantActivity = await updateParticipantActivity(selectedID, ParticipantActivityPayload);
      setParticipantActivity((prev) => prev.map((act) => (act.id === selectedID ? updatedParticipantActivity : act)));
      setFilteredParticipantActivity(prev => prev.map(act => act.id === selectedID ? updatedParticipantActivity : act));
      setIsModalOpen(false);
      setErrors({});
    } catch (error) {
      toast.error(`Error updating ParticipantActivity with ID ${selectedID}:`, error.response?.data);
      setErrors(error.response?.data.errors || {});
      alert(`Failed to update ParticipantActivity: ${error.response?.data.title}\nDetails: ${JSON.stringify(error.response?.data.errors, null, 2)}`);
    }
  };

  const deleteExistingParticipantActivity = async (ID) => {
    try {
      await deleteParticipantActivity(ID);
      setUpdate(prev=>!prev)
    } catch (error) {
      toast.error(`Error deleting ParticipantActivity with ID ${ID}:`, error.response?.data);
      alert(`Failed to delete ParticipantActivity: ${error.response?.data.title}`);
    }
  };

  const closeAddParticipantActivityModal = () => {
    setIsModalOpen(false);
    setEditMode(false);
    setErrors({});
  };

  const deleteParticipantActivityHandler = (ID) => {
    if (window.confirm(`Are you sure you want to delete ParticipantActivity with ID ${ID}?`)) {
      deleteExistingParticipantActivity(ID);
    }
  };
   //Search
   const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    filterParticipantActivity(value, selectedColumn);
  };
  const handleFilterParameterChange = (event) => {
    const value = event.target.value;
    setSelectedColumn(value);
    filterParticipantActivity(searchTerm, value);
  };

  const filterParticipantActivity = (searchTerm, column) => {
    const filtered = ParticipantActivity.filter((activity) =>
      activity[column] 
        .toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
    setFilteredParticipantActivity(filtered);
  };

  return (
<Layout>
  <div className="bg-white rounded-lg shadow-lg">
    <div className="px-6 py-4 border-b border-gray-200">
      <h1 className="text-2xl font-semibold text-gray-800">Adventurous Journey</h1>
    </div>

    <div className="p-6">
      <div className="space-y-4 md:space-y-0 md:flex md:items-center md:justify-between mb-6">
        <button
          onClick={openAddParticipantActivityModal}
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-all duration-200"
        >
          <FaPlus className="mr-2 h-4 w-4" />
          Add Activity
        </button>

        <div className="flex flex-col md:flex-row gap-4 flex-1 md:max-w-2xl">
          <div className="relative flex-1">
            <select
              className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-md bg-white shadow-sm"
              value={selectedColumn}
              onChange={handleFilterParameterChange}
            >
              {ParticipantActivity.length > 0 ? (
                Object.keys(ParticipantActivity[0])?.map((op) => (
                  <option key={op} value={op}>
                    {splitWordFunc(op)}
                  </option>
                ))
              ) : (
                <option>No filter property</option>
              )}
            </select>
            <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <FaFilter className="h-4 w-4 text-gray-400" />
            </div>
          </div>

          <div className="relative flex-1">
            <input
              type="text"
              placeholder="Search activities..."
              onChange={handleSearchChange}
              className="block w-full pl-3 pr-10 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
            <div className="absolute inset-y-0 right-0 flex items-center pr-3">
              <FiSearch className="h-5 w-5 text-gray-400" />
            </div>
          </div>
        </div>
      </div>
      <ParticipantActivityTable
        ParticipantActivity={filteredParticipantActivity}
        updateParticipantActivity={openEditParticipantActivityModal}
        deleteParticipantActivity={deleteParticipantActivityHandler}
      />
    </div>

    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeAddParticipantActivityModal}
      contentLabel={editMode ? "Edit Participant Activity" : "Add Participant Activity"}
      style={customStyles}
    >
      <h2 className="text-xl font-semibold text-gray-800 mb-4">
        {editMode ? "Edit Activity" : "Add Activity"}
      </h2>
      <ParticipantActivityForm
        formValues={newParticipantActivity}
        setFormValues={setNewParticipantActivity}
        handleInputChange={handleInputChange}
        handleFileChange={handleFileChange}
        handleSubmit={editMode ? updateExistingParticipantActivity : addNewParticipantActivity}
        errors={errors}
      />
      <div className="flex justify-end mt-4">
        <button
          onClick={editMode ? updateExistingParticipantActivity : addNewParticipantActivity}
          className="bg-indigo-600 text-white px-5 py-2 rounded mr-2 transition-all duration-200 hover:bg-indigo-700"
        >
          {editMode ? "Update" : "Save"}
        </button>
        <button
          onClick={closeAddParticipantActivityModal}
          className="border border-gray-300 text-gray-700 px-5 py-2 rounded hover:bg-gray-100 transition-all duration-200"
        >
          Close
        </button>
      </div>
    </Modal>
  </div>
</Layout>
  );
};

export default AddParticipantActivity;
