import apiClient from './apiClient';
import { logAction } from '../utils/logger';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 

const addStages = async (StagesData) => {
  try {
    const response = await apiClient.post('/api/AwardCStages', StagesData);
    if(response){
      logAction("Created Stage","Award Stages",`Added the following stage ${JSON.stringify(response.data)}`)
    }
    return response.data;
  } catch (error) {
    toast.error('Error in addInstitutionStages:', error.response.data);
    throw error;
  }
};

const getStagesById = async (stagesId) => {
  try {
    const response = await apiClient.get(`/api/AwardCStages/${stagesId}`);
    return response.data;
  } catch (error) {
    toast.error(`Error fetching InstitutionStages with ID ${stagesId}:`, error.response.data);
    throw error;
  }
};

const updateStages = async (stagesId, updatedData) => {
  try {
    const response = await apiClient.put(`/api/AwardCStages/${stagesId}`, updatedData);
    if(response){
      logAction("Updated Stage","Award Stages",`Updated the following stage ${JSON.stringify(response.data)}`)
    }
    return response.data;
  } catch (error) {
    toast.error(`Error updating InstitutionStages with ID ${stagesId}:`, error.response.data);
    throw error;
  }
};
const deleteStages = async (stagesId) => {
  try {
   const response = await apiClient.delete(`/api/AwardCStages/${stagesId}`);
    if(response){
      logAction("Deleted Stage","Award Stages",`Deleted the following stage ${JSON.stringify(response.data)}`)
    }
  } catch (error) {
    toast.error(`Error deleting InstitutionStages with ID ${stagesId}:`, error.response.data);
    throw error;
  }
};

const getStages = async () => {
  try {
    const response = await apiClient.get('/api/AwardCStages');
    return response.data;
  } catch (error) {
    toast.error('Error fetching InstitutionStagess:', error.response.data);
    throw error;
  }
};

export { addStages, getStagesById, updateStages, deleteStages, getStages };
