import React from 'react';

const IntegrationSettings = () => {
  return (
    <div className="w-full max-w-4xl mx-auto">
      <h2 className="text-2xl font-Satoshi font-semibold text-white bg-primary p-4 mb-6 rounded-lg">Integration Settings</h2>
  
      {/* Add the content of the Integration settings here */}
    </div>
  );
};

export default IntegrationSettings;
