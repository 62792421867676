import apiClient from './apiClient';
import { logAction } from '../utils/logger';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 

const addTraining = async (TrainingData) => {
  try {
    const response = await apiClient.post('/api/Training', TrainingData);
    if(response){
      logAction("Created Training","Training",`Created the following Training ${JSON.stringify(response.data)}`)
    }
    return response.data;
  } catch (error) {
    toast.error('Error in addTraining:', error.response.data);
    throw error;
  }
};

const getTrainingById = async (trainingID) => {
  try {
    const response = await apiClient.get(`/api/Training/${trainingID}`);
    return response.data;
  } catch (error) {
    toast.error(`Error fetching Training with ID ${trainingID}:`, error.response.data);
    throw error;
  }
};

const updateTraining = async (trainingID, updatedData) => {
  try {
    const response = await apiClient.put(`/api/Training/${trainingID}`, updatedData);
    if(response){
      logAction("Updated Training","Training",`Updated the following Training ${JSON.stringify(response.data)}`)
    }
    return response.data;
  } catch (error) {
    toast.error(`Error updating Training with ID ${trainingID}:`, error.response.data);
    throw error;
  }
};

const deleteTraining = async (trainingID) => {
  try {
    const response =  await apiClient.delete(`/api/Training/${trainingID}`);
    if(response){
      logAction("Deleted Training","Training",`Deleted the following Training ${JSON.stringify(response.data)}`)
    }
  } catch (error) {
    toast.error(`Error deleting Training with ID ${trainingID}:`, error.response.data);
    throw error;
  }
};

const getTraining = async () => {
  try {
    const response = await apiClient.get('/api/Training');
    return response.data;
  } catch (error) {
    toast.error('Error fetching Training:', error.response.data);
    throw error;
  }
};

export { addTraining, getTrainingById, updateTraining, deleteTraining, getTraining };
