import Modal from "react-modal";
import { customStyles } from "../../styles/customStyles";
import styles from "../../styles/modal.module.css";
import InputContainer from "../surfaces/InputContainer";
import {generateNameValueConfig} from "../../utils/splitWordFunc"
const ViewComponent = ({ moduleName, isModalOpen, inputConfig,handleClose }) => {

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={() => {}}
      contentLabel={moduleName}
      style={customStyles}>
      <h2 className="subtitle1 mb-4">{`View ${moduleName}`}</h2>
      <form className={styles.form}>
        <div className="grid grid-cols-1 gap-2  md:grid-cols-2">
          {generateNameValueConfig(inputConfig).map((field, index) => {
            return (
              <InputContainer2
                disabled={true}
                key={index}
                value={field.value}
                label={field.name}
              />
            );
          })}
        </div>
        <div className="flex justify-end mt-4">
          <button
          onClick={handleClose}
            className="outline outline-1 outline-primary text-primary px-5 p-2 rounded">
            close
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default ViewComponent;


const InputContainer2 = ({label,placeholder,type,name,error,registerFunction=()=>{},disabled=false,value})=> {
  return(
      <div className='flex flex-col gap-2'>
               <label className='text-sm font-medium font-sans text-main'>{label}<span className='text-primary'></span></label>
                 <input disabled={disabled} value={value}  {...registerFunction(name)} placeholder={placeholder} type={type}  className="w-full p-2 border border-gray-300 rounded"/>
              {error ? <p className='text-red-600 body2'>{error}</p> : "" }
              </div>
  );
}