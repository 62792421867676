import React, { useState, useEffect, useMemo } from 'react';
import Table from '../common/Table';
import Dropdown from '../Dropdown/dropDown';
import { getUsersByUsername } from '../../services/usersS';

const AjApprovalTable = ({ AjApproval, openEditModal, deleteAjApproval, openApprovalModal }) => {
  const [userInstitution, setUserInstitution] = useState("");
  const loggedInUser = JSON.parse(localStorage.getItem("user"))?.user;
 
  const role = loggedInUser?.role.name;

  const columns = [
    { 
      Header: 'Award Center', 
      accessor: 'awardCentre',
      Cell: ({ row }) => (
        <div className="flex items-center">
          <span className="mr-2">{row.original.awardCentre}</span>
          {(role === "Admin" || role === "Staff") && (
            <button
            onClick={() => openApprovalModal(row.original)}
            className="bg-blue-500 hover:bg-blue-600 text-white text-xs py-1 px-2 rounded"
          >
            Review
          </button>)}
        </div>
      )
    },      
    { Header: 'Award Leader', accessor: 'awardLeader' },
    { Header: 'Region', accessor: 'region' },    
    { Header: 'Activity Name', accessor: 'activityName' },
    { Header: 'Activity Date', accessor: 'activityDate' },  
    { Header: 'Apply Date', accessor: 'applyDate' },        
    { Header: 'Assessors', accessor: 'assessors' },        
    { Header: 'No. of Participants', accessor: 'participantsNo' },    
    { Header: 'Upload Form2', accessor: 'uploadForm2',
      Cell: ({ value }) => value ? (
        <a
          href={process.env.REACT_APP_API_BASE_URL + value}
          target="_blank"
          rel="noopener noreferrer"
          download
          className="text-blue-600 hover:text-blue-800 underline"
        >
          Download Form
        </a>
      ) : (
        <span className="text-gray-400">No form</span>
      )
     },      
    { Header: 'Upload Form3', accessor: 'uploadForm3',
      Cell: ({ value }) => value ? (
        <a
          href={process.env.REACT_APP_API_BASE_URL + value}
          target="_blank"
          rel="noopener noreferrer"
          download
          className="text-blue-600 hover:text-blue-800 underline"
        >
          Download Form
        </a>
      ) : (
        <span className="text-gray-400">No form</span>
      )
     },      
    { Header: 'Upload Form', 
      accessor: 'uploadForm',
      Cell: ({ value }) => value ? (
        <a
          href={process.env.REACT_APP_API_BASE_URL + value}
          target="_blank"
          rel="noopener noreferrer"
          download
          className="text-blue-600 hover:text-blue-800 underline"
        >
          Download Form
        </a>
      ) : (
        <span className="text-gray-400">No form</span>
      )
     },      
    { Header: 'Notes', accessor: 'notes' },
  ];

   // Fetch user's institution if they are an Award Leader or Adult Helper
   const fetchUserInstitution = async () => {
    if (loggedInUser?.role.name === "Award Leader" || loggedInUser?.role.name === "Adult Helper") {
      try {
        const userData = await getUsersByUsername(loggedInUser.username);
        if (userData?.awardCenter?.institutionName) {
          setUserInstitution(userData.awardCenter.institutionName);
        }
      } catch (error) {
        console.error("Error fetching user institution:", error);
      }
    }
  };
  // Fetch user's institution when component mounts
  useEffect(() => {
    fetchUserInstitution();
    
  }, []);

const filteredAjApproval = () => {
  if (loggedInUser?.role.name === "Award Leader" || role === "Adult Helper" && userInstitution) {
    return AjApproval.filter(approval => approval.awardCentre.trim() == userInstitution.trim())
 }
 return AjApproval
} 
  
  const renderRowActions = (activity) => (
    <div className='z-30'>
    <Dropdown  
      onEdit={()=>openEditModal(activity)}
      onDelete={()=>deleteAjApproval(activity.id)}
    />
  </div> 
  );



  return (
    <Table
      columns={columns}
      data={filteredAjApproval()}
      renderRowActions={renderRowActions}
    />
  );
};

export default AjApprovalTable;