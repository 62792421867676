import React from 'react';
import Table from '../common/Table'; // Assuming Table component handles empty data
import Dropdown from "../Dropdown/dropDown"

const columns = [
  { Header: 'Status ID', accessor: 'projectStatusID' },
  { Header: 'Status Name', accessor: 'statusName' },
  { Header: 'Notes', accessor: 'notes' },
];

const ProjectStatusTable = ({ projectstatuses = [], openEditModal, deleteStatus }) => {

  // Console log data received for debugging
  console.log('Project statuses received:', projectstatuses);

  const renderRowActions = ({projectStatusID}) => (
    <div className='z-50'>
    <Dropdown  onEdit={()=>openEditModal(projectStatusID)} onDelete={()=>deleteStatus(projectStatusID)} />
  </div>
  );

  return (
    <Table
      columns={columns}
      // Check if data exists before rendering rows
      data={projectstatuses}
      renderRowActions={renderRowActions}
    />
  );
};

export default ProjectStatusTable;
