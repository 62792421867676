import apiClient from './apiClient';
import { logAction } from '../utils/logger';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 

const addFeedback = async (FeedbackData) => {
  try {
    const response = await apiClient.post('/api/Feedback', FeedbackData);
    if(response){
      logAction("Created Feedback","Feedback",`Created the following Feedback ${JSON.stringify(response.data)}`)
    }
    return response.data;
  } catch (error) {
    toast.error('Error in addFeedbacks:', error.response.data);
    throw error;
  }
};

const getFeedbackById = async (respondentID) => {
  try {
    const response = await apiClient.get(`/api/Feedback/${respondentID}`);
    return response.data;
  } catch (error) {
    toast.error(`Error fetching Feedbacks with ID ${respondentID}:`, error.response.data);
    throw error;
  }
};

const updateFeedback = async (respondentID, updatedData) => {
  try {
    const response = await apiClient.put(`/api/Feedback/${respondentID}`, updatedData);
    if(response){
      logAction("Updated Feedback","Feedback",`Updated the following Feedback ${JSON.stringify(response.data)}`)
    }
    return response.data;
  } catch (error) {
    toast.error(`Error updating Feedbacks with ID ${respondentID}:`, error.response.data);
    throw error;
  }
};

const deleteFeedback = async (respondentID) => {
  try {
    const response = await apiClient.delete(`/api/Feedback/${respondentID}`);
    if(response){
      logAction("Deleted Feedback","Feedback",`Deleted the following Feedback ${JSON.stringify(response.data)}`)
    }
  } catch (error) {
    toast.error(`Error deleting Feedbacks with ID ${respondentID}:`, error.response.data);
    throw error;
  }
};

const getFeedback = async () => {
  try {
    const response = await apiClient.get('/api/Feedback');
    return response.data;
  } catch (error) {
    toast.error('Error fetching Feedbacks:', error.response.data);
    throw error;
  }
};

export { addFeedback, getFeedbackById, updateFeedback, deleteFeedback, getFeedback };
