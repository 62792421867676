import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 

const NotificationSettings = () => {

 // State for configuration fields
 const [mailDriver, setMailDriver] = useState('smtp');
 const [mailHost, setMailHost] = useState('mail.presidentsawards.co.ke');
 const [mailUsername, setMailUsername] = useState('info@presidentsawards.co.ke');
 const [mailPassword, setMailPassword] = useState('ef53cbb80a905fx');
 const [mailPort, setMailPort] = useState('456');
 const [mailFromAddress, setMailFromAddress] = useState('info@presidentsawards.co.ke');

 // Function to save configurations
 const saveConfigurationsToEnv = async () => {
   const config = {
     REACT_APP_MAIL_DRIVER: mailDriver,
     REACT_APP_MAIL_HOST: mailHost,
     REACT_APP_MAIL_USERNAME: mailUsername,
     REACT_APP_MAIL_PASSWORD: mailPassword,
     REACT_APP_MAIL_PORT: mailPort,
     REACT_APP_MAIL_FROM_ADDRESS: mailFromAddress,
   };

   try {
     // Send the configurations to the backend API to handle saving to .env
     const response = await axios.post('http://192.168.100.24:5248/api/saveconfig', config);

     if (response.status === 200) {
       // Optionally, download the updated .env file
       const blob = new Blob([response.data], { type: 'text/plain' });
       const url = URL.createObjectURL(blob);

       const link = document.createElement('a');
       link.href = url;
       link.download = '.env';
       document.body.appendChild(link);
       link.click();
       document.body.removeChild(link);

       URL.revokeObjectURL(url); // Clean up URL object

       alert('Configuration file saved successfully.');
     } else {
       alert('Failed to save configuration.');
     }
   } catch (error) {
     toast.error('Error saving configuration:', error);
     alert('Failed to save configuration. Please try again.');
   }
 };


  // State for managing active tab
  const [activeTab, setActiveTab] = useState('general');
  
  // States for General tab
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [editingEvent, setEditingEvent] = useState(null);
  
  // States for Templates tab
  const [emailTemplateExpanded, setEmailTemplateExpanded] = useState(true);
  const [smsTemplateExpanded, setSmsTemplateExpanded] = useState(false);
  
  // Sample data for General tab
  const events = [
    { id: 1, name: 'Password Reset Request', email: true, sms: true },
    { id: 2, name: 'Your password reset code', email: true, sms: true },
    { id: 3, name: 'License Renewal Required', email: true, sms: true },
    { id: 4, name: 'License renewal due soon', email: true, sms: true },
    { id: 5, name: 'Certificate Issued', email: true, sms: true },
  ];

  // Handler for edit button click in General tab
  const handleEdit = (event) => {
    setEditingEvent(event);
    setShowEditPopup(true);
  };

  // Edit Popup component for General tab
  const EditPopup = ({ event, onClose, onSave }) => {
    const [name, setName] = useState(event.name);
    const [email, setEmail] = useState(event.email);
    const [sms, setSms] = useState(event.sms);

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
        <div className="bg-white p-6 rounded-lg shadow-lg w-96">
          <h2 className="text-xl font-semibold mb-4">Edit Setting</h2>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-1">Display Name</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full p-2 border rounded"
            />
          </div>
          <div className="mb-4">
            <p className="text-sm font-medium text-gray-700 mb-2">Send notification via</p>
            <div className="flex space-x-4">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={email}
                  onChange={() => setEmail(!email)}
                  className="mr-2"
                />
                Email
              </label>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={sms}
                  onChange={() => setSms(!sms)}
                  className="mr-2"
                />
                SMS
              </label>
            </div>
          </div>
          <div className="flex justify-end space-x-2">
            <button onClick={onClose} className="px-4 py-2 text-sm font-medium text-gray-700">
              Cancel
            </button>
            <button
              onClick={() => onSave({ ...event, name, email, sms })}
              className="px-4 py-2 bg-indigo-600 text-white rounded-md text-sm font-medium"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="w-full max-w-4xl mx-auto">
      <h2 className="text-2xl font-Satoshi font-semibold text-white bg-primary p-4 mb-6 rounded-lg">Email & SMS Settings</h2>
      
      {/* Tab navigation */}
      <div className="mb-4 border-b">
        <button
          className={`mr-4 py-2 px-4 ${activeTab === 'general' ? 'text-indigo-600 border-b-2 border-indigo-600' : ''}`}
          onClick={() => setActiveTab('general')}
        >
          General
        </button>
        <button
          className={`mr-4 py-2 px-4 ${activeTab === 'templates' ? 'text-indigo-600 border-b-2 border-indigo-600' : ''}`}
          onClick={() => setActiveTab('templates')}
        >
          Templates
        </button>
        <button
          className={`py-2 px-4 ${activeTab === 'configurations' ? 'text-indigo-600 border-b-2 border-indigo-600' : ''}`}
          onClick={() => setActiveTab('configurations')}
        >
          Configurations
        </button>
      </div>
      
      {/* General Tab Content */}
      {activeTab === 'general' && (
        <div className="bg-white p-6 rounded-lg shadow">
          <input
            type="text"
            placeholder="Filter"
            className="w-full p-2 border rounded mb-4"
          />
          <table className="w-full">
            <thead>
              <tr className="border-b">
                <th className="text-left py-2">Event ▲</th>
                <th className="text-center py-2">Email</th>
                <th className="text-center py-2">SMS</th>
                <th className="text-center py-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {events.map((event) => (
                <tr key={event.id} className="border-b">
                  <td className="py-2">{event.name}</td>
                  <td className="text-center py-2">{event.email ? '✓' : ''}</td>
                  <td className="text-center py-2">{event.sms ? '✓' : ''}</td>
                  <td className="text-center py-2">
                    <button onClick={() => handleEdit(event)} className="text-indigo-600">
                      ✎
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
    
        </div>
      )}
      
      {/* Templates Tab Content */}
      {activeTab === 'templates' && (
        <div className="bg-white p-6 rounded-lg shadow">
          {/* Email Templates Section */}
          <div className="mb-4">
            <div className="flex justify-between items-center border-b pb-2 mb-2">
              <h3 className="text-lg font-semibold">Email Templates</h3>
              <button onClick={() => setEmailTemplateExpanded(!emailTemplateExpanded)}>
                {emailTemplateExpanded ? '▲' : '▼'}
              </button>
            </div>
            {emailTemplateExpanded && (
              <div className="space-y-4">
                <select className="w-full p-2 border rounded">
                  <option>Template</option>
                </select>
                <input className="w-full p-2 border rounded" placeholder="Subject" />
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">Body</label>
                  <div className="border rounded p-2 mb-2">
                    {/* Rich text editor toolbar (simplified) */}
                    <div className="flex flex-wrap gap-2 mb-2">
                      {['↺', '↻', 'B', 'I', 'U', 'S', 'x', 'x²', '≡', '⋮', '☰', '▤', '▥', '▦', '▧', '▨'].map((icon, index) => (
                        <button key={index} className="border rounded px-2">{icon}</button>
                      ))}
                      <select className="border rounded">
                        <option>default</option>
                      </select>
                      <select className="border rounded">
                        <option>Times New F</option>
                      </select>
                      <select className="border rounded">
                        <option>3</option>
                      </select>
                    </div>
                    <div className="flex flex-wrap gap-2">
                      {['A', 'A', 'Clear Class', '🔗', '↩', '🖼', '🎥', '—', '×', '</>'].map((icon, index) => (
                        <button key={index} className="border rounded px-2">{icon}</button>
                      ))}
                    </div>
                  </div>
                  <textarea className="w-full p-2 border rounded" rows="4" placeholder="Enter text here..."></textarea>
                </div>
                <textarea className="w-full p-2 border rounded" rows="2" placeholder="Tags"></textarea>
                {/* New Update Email Settings button */}
                <button className="bg-indigo-600 text-white py-2 px-4 rounded">UPDATE EMAIL SETTINGS</button>
              </div>
            )}
          </div>
          {/* SMS Templates Section */}
          <div className="mb-4">
            <div className="flex justify-between items-center border-b pb-2 mb-2">
              <h3 className="text-lg font-semibold">SMS Templates</h3>
              <button onClick={() => setSmsTemplateExpanded(!smsTemplateExpanded)}>
                {smsTemplateExpanded ? '▲' : '▼'}
              </button>
            </div>
            {smsTemplateExpanded && (
              <div className="space-y-4">
                <input className="w-full p-2 border rounded" placeholder="Name" />
                <select className="w-full p-2 border rounded">
                  <option>Template</option>
                </select>
                <textarea className="w-full p-2 border rounded" rows="4" placeholder="Body (SMS)"></textarea>
                <textarea className="w-full p-2 border rounded" rows="2" placeholder="Tags"></textarea>
                <button className="bg-indigo-600 text-white py-2 px-4 rounded">UPDATE SMS SETTINGS</button>
              </div>
            )}
          </div>
        </div>
      )}
      
       {/* Configurations Tab Content */}
  {activeTab === 'configurations' && (
  <div className="bg-white p-6 rounded-lg shadow">
  <h3 className="text-lg font-semibold mb-4">Email Configuration</h3>
  <div className="space-y-4">
    <div>
      <label htmlFor="mailDriver" className="block text-sm font-medium text-gray-700 mb-1">Mail Driver</label>
      <input
        id="mailDriver"
        className="w-full p-2 border rounded"
        value={mailDriver}
        onChange={(e) => setMailDriver(e.target.value)}
      />
    </div>
    <div>
      <label htmlFor="mailHost" className="block text-sm font-medium text-gray-700 mb-1">Mail Host</label>
      <input
        id="mailHost"
        className="w-full p-2 border rounded"
        value={mailHost}
        onChange={(e) => setMailHost(e.target.value)}
      />
    </div>
    <div>
      <label htmlFor="mailUsername" className="block text-sm font-medium text-gray-700 mb-1">Mail Username</label>
      <input
        id="mailUsername"
        className="w-full p-2 border rounded"
        value={mailUsername}
        onChange={(e) => setMailUsername(e.target.value)}
      />
    </div>
    <div>
      <label htmlFor="mailPassword" className="block text-sm font-medium text-gray-700 mb-1">Mail Password</label>
      <input
        id="mailPassword"
        type="password"
        className="w-full p-2 border rounded"
        value={mailPassword}
        onChange={(e) => setMailPassword(e.target.value)}
      />
    </div>
    <div>
      <label htmlFor="mailPort" className="block text-sm font-medium text-gray-700 mb-1">Mail Port</label>
      <input
        id="mailPort"
        className="w-full p-2 border rounded"
        value={mailPort}
        onChange={(e) => setMailPort(e.target.value)}
      />
    </div>
    <div>
      <label htmlFor="mailFromAddress" className="block text-sm font-medium text-gray-700 mb-1">Mail From Address</label>
      <input
        id="mailFromAddress"
        className="w-full p-2 border rounded"
        value={mailFromAddress}
        onChange={(e) => setMailFromAddress(e.target.value)}
      />
    </div>
  </div>

  <div className="mt-6">
    <button
      onClick={saveConfigurationsToEnv}
      className="bg-indigo-600 text-white py-2 px-4 rounded"
    >
      Save Configurations
    </button>
  </div>
</div>
  )}
      
      {/* Edit Popup */}
      {showEditPopup && (
        <EditPopup
          event={editingEvent}
          onClose={() => setShowEditPopup(false)}
          onSave={(updatedEvent) => {
            setShowEditPopup(false);
          }}
        />
      )}
    </div>
  );
};

export default NotificationSettings;