import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { FaPlus } from "react-icons/fa6";
import { FaFilter } from "react-icons/fa";
import { splitWordFunc } from "../../utils/splitWordFunc";
import { FiSearch } from "react-icons/fi";
import { customStyles } from '../../styles/customStyles';
import FundingTypeForm from '../../components/forms/fundingTypeF';
import FundingTypeTable from '../../components/tables/fundingTypeT';
import { addFundingType as addFundingTypeService, getFundingTypeById, updateFundingType, deleteFundingType, getFundingType } from '../../services/fundingTypeS';
import Layout from '../../components/Layout/layout';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 

Modal.setAppElement('#root');

const AddFundingType = () => {
  const [fundingtype, setFundingType] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newFundingType, setNewFundingType] = useState({
    fundingID: '',
    fundingName: '',
    notes: '',
  });
  const [selectedColumn, setSelectedColumn] = useState("fundingName");
  const [filteredFundingTypes, setFilteredFundingTypes] = useState(fundingtype);
  const [searchTerm, setSearchTerm] = useState("");
  const [update,setUpdate] = useState(false)
  const [errors, setErrors] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [selectedFundingTypeID, setSelectedFundingTypeID] = useState(null);

  useEffect(() => {
    const fetchedfundingtype = async () => {
      try {
        const fetchedFundingType = await getFundingType();
        setFundingType(fetchedFundingType);
        setFilteredFundingTypes(fetchedFundingType);
      } catch (error) {
        toast.error('Error fetching fundingtype:', error.response.data);
      }
    };

    fetchedfundingtype();
  }, [update]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewFundingType((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setNewFundingType((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const addNewFundingType = async () => {
    try {
      const fundingtypePayload = {
        fundingID: String(newFundingType.fundingID), // Ensure FundingTypeID is a string
        fundingName: newFundingType.fundingName,
        notes: newFundingType.notes,
      };
  
      await addFundingTypeService(fundingtypePayload);
      setUpdate(prev=>!prev)
      setIsModalOpen(false);
    } catch (error) {
      toast.error('Error adding fundingtype:', error.response.data);
      setErrors(error.response.data.errors || {});
      alert(`Failed to add fundingtype: ${error.response.data.title}\nDetails: ${JSON.stringify(error.response.data.errors, null, 2)}`);
    }
  };
  

  const openAddFundingTypeModal = () => {
    setEditMode(false);
    setIsModalOpen(true);
    setNewFundingType({
      fundingID: '',
      fundingName: '',
      notes: '',
    });
  };

  const openEditFundingTypeModal = async (fundingID) => {
    try {
      const fetchedFundingType = await getFundingTypeById(String(fundingID));
      setEditMode(true);
      setIsModalOpen(true);
      setSelectedFundingTypeID(fundingID);
      setNewFundingType({
        ...fetchedFundingType,
      });
    } catch (error) {
      toast.error(`Error fetching fundingtype with ID ${fundingID}:`, error.response.data);
    }
  };

  const updateExistingFundingType = async () => {
    try {
      const fundingtypePayload = { ...newFundingType };
      await updateFundingType(selectedFundingTypeID, fundingtypePayload);
     setUpdate(prev=>!prev);
      setIsModalOpen(false);
    } catch (error) {
      toast.error(`Error updating fundingtype with ID ${selectedFundingTypeID}:`, error.response.data);
      setErrors(error.response.data.errors || {});
      alert(`Failed to update fundingtype: ${error.response.data.title}\nDetails: ${JSON.stringify(error.response.data.errors, null, 2)}`);
    }
  };

  const deleteExistingFundingType = async (fundingID) => {
    try {
      await deleteFundingType(fundingID);
      setUpdate(prev=>!prev);
    } catch (error) {
      toast.error(`Error deleting fundingtype with ID ${fundingID}:`, error.response.data);
      alert(`Failed to delete fundingtype: ${error.response.data.title}`);
    }
  };

  const closeAddFundingTypeModal = () => {
    setIsModalOpen(false);
    setEditMode(false);
    setErrors({});
  };

  const deleteFundingTypeHandler = (fundingID) => {
    if (window.confirm(`Are you sure you want to delete fundingtype with ID ${fundingID}?`)) {
      deleteExistingFundingType(fundingID);
    }
  };


  //Search
  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    filterFundingTypes(value, selectedColumn);
  };
  const handleFilterParameterChange = (event) => {
    const value = event.target.value;
    setSelectedColumn(value);
    filterFundingTypes(searchTerm, value);
  };

  const filterFundingTypes = (searchTerm, column) => {
    const filtered = fundingtype.filter((fundingType) =>
      fundingType[column]
        .toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
    setFilteredFundingTypes(filtered);
  };

  return (
    <Layout>
    <div className="bg-white rounded-lg shadow-lg">
      <div className="px-6 py-4 border-b border-gray-200">
        <h1 className="text-2xl font-semibold text-gray-800">Funding Type</h1>
      </div>
  
      <div className="p-6">
        <div className="space-y-4 md:space-y-0 md:flex md:items-center md:justify-between mb-6">
          <button
            onClick={openAddFundingTypeModal}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-all duration-200"
          >
            <FaPlus className="mr-2 h-4 w-4" />
            <span>Add Type</span>
          </button>
  
          <div className="flex flex-col md:flex-row gap-4 flex-1 md:max-w-2xl">
            <div className="relative flex-1">
              <select
                className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-md bg-white shadow-sm"
                value={selectedColumn}
                onChange={handleFilterParameterChange}
              >
                {fundingtype.length > 0 ? (
                  Object.keys(fundingtype[0])?.map((op) => (
                    <option key={op} value={op}>
                      {splitWordFunc(op)}
                    </option>
                  ))
                ) : (
                  <option>No filter property</option>
                )}
              </select>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <FaFilter className="h-4 w-4 text-gray-400" />
              </div>
            </div>
  
            <div className="relative flex-1">
              <input
                type="text"
                placeholder="Search..."
                onChange={handleSearchChange}
                className="block w-full pl-3 pr-10 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
              <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                <FiSearch className="h-5 w-5 text-gray-400" />
              </div>
            </div>
          </div>
        </div>
  
        <FundingTypeTable
          fundingtype={filteredFundingTypes}
          openEditModal={openEditFundingTypeModal}
          deleteFundingType={deleteFundingTypeHandler}
        />
      </div>
  
      <Modal
        style={customStyles}
        isOpen={isModalOpen}
        onRequestClose={closeAddFundingTypeModal}
        contentLabel={editMode ? "Edit Funding Type" : "Add Funding Type"}
      >
        <h2 className="text-xl font-semibold text-gray-800 mb-4">
          {editMode ? "Edit Funding Type" : "Add Funding Type"}
        </h2>
        <FundingTypeForm
          formValues={newFundingType}
          handleInputChange={handleInputChange}
          handleDateChange={handleDateChange}
          errors={errors}
        />
        <div className="flex justify-end mt-4">
          <button
            onClick={editMode ? updateExistingFundingType : addNewFundingType}
            className="bg-indigo-600 text-white px-5 py-2 rounded mr-2 transition-all duration-200 hover:bg-indigo-700"
          >
            {editMode ? "Update" : "Save"}
          </button>
          <button
            onClick={closeAddFundingTypeModal}
            className="border border-gray-300 text-gray-700 px-5 py-2 rounded hover:bg-gray-100 transition-all duration-200"
          >
            Cancel
          </button>
        </div>
      </Modal>
    </div>
  </Layout>
  
  );
};

export default AddFundingType;
