import { getUsersByUsername } from "../services/usersS";
export const getLoggedInUser = async()=>{
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
        const parsedUsername = JSON.parse(storedUser);
        try {
          const loggedInUser = await getUsersByUsername(parsedUsername?.user?.username)
          return loggedInUser
        } catch (error) {
          return []
        }
      }
      return []
}

export const returnParticipantsWithAwards = (participants, awards) => {
  // Iterate over each participant and find matching awards
  const participantsWithAwards = participants
    .map((participant) => {
      // Find matching awards by comparing adminNumber
      const matchingAward = awards.find(
        (award) => award.adminNumber === participant.adminNumber
      );

      // If a matching award is found, return participant with award details
      if (matchingAward) {
        return {
          ...participant, // Include participant details
          award: matchingAward // Add matching award details
        };
      }

      // If no award is found, return null (this participant won't be included)
      return null;
    })
    .filter((p) => p !== null); // Filter out null values (participants without awards)

  return participantsWithAwards;
};
