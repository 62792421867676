import React, { useState } from 'react';
import { useForm } from "react-hook-form"
import { useNavigate } from 'react-router-dom';
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import apiClient from '../../services/apiClient';
import AuthLayout from '../../components/surfaces/authLayout';
import Button from "../../components/common/Button/Button"
import InputContainer from "../../components/surfaces/InputContainer"

const inputConfig = [{label: 'Email',placeholder: "Enter Email",type: "email",name: "email"}];

const schema = yup
  .object({
    email: yup.string().required("Email is required")
  })
  .required()

const ForgotPassword = ()=>{
const [error,setError] = useState("")
const [successMessage,setSuccessMessage] = useState("")
const navigate = useNavigate()
    const {
        setValue,
        handleSubmit,
        formState: { errors },
      } = useForm({
        resolver: yupResolver(schema),
      })


      const onSubmit = async(data) => {
        try {
          const response = await apiClient.post('api/User/RequestPasswordReset', data)
          const OurData = response.data
          setSuccessMessage(OurData)
          setTimeout(()=>{
            setSuccessMessage("")
            navigate("/signin")
          },3000)
        } catch (error) {
          setError(error.response.data)
          setTimeout(()=>{
            setError("")
          },2000)
        } 
           }
      return(
          <AuthLayout>
              <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col gap-10'>
              {error && (
              <div role="alert">
                <div class="bg-red-500 text-white font-bold rounded-t px-4 py-2">
                  
                </div>
                <div class="border border-t-0 border-red-400 rounded-b bg-red-100 px-4 py-3 text-red-700">
                  <p>{error}</p>
                </div>
              </div>
            )}
            {successMessage && (
              <div role="alert">
                <div class="bg-primary text-white font-bold rounded-t px-4 py-2">
                  
                </div>
                <div class="border border-t-0 border-primary rounded-b bg-greys px-4 py-3 text-main">
                  <p>{successMessage}</p>
                  <p className='text-center'>Please check your email.</p>
                </div>
              </div>
            )}
              <div>
              <div className='flex flex-col gap-2'>
                <h2 color='secondary' className='text-main subtitle1'>Forgot Password</h2>
                <p className='text-greys body1' >Enter your email to recieve a reset link!</p>
               </div>
              </div>
               <div className='flex flex-col gap-5'>
                {inputConfig.map((field,index)=><InputContainer error={errors[field.name]?.message} name={field.name} setValue={setValue} key={index} label={field.label} placeholder={field.placeholder} type={field.type} />)}
               <div className='flex flex-row justify-between'>
               </div>
               <div>
                <Button className="bg-primary text-white w-full">Reset Password</Button>
               </div>
               <div>
                <p className='flex flex-row gap-2'>
                    <span className='body2 text-main'>Not registered yet?</span>
                    <a className='bodyLarge text-primary cursor-pointer' href='/signup'>Create an Account</a>
                </p>
               </div>
               </div>
              </form>
          </AuthLayout>
      )
}
    
export default ForgotPassword;
















