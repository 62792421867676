import apiClient from './apiClient';
import { logAction } from '../utils/logger';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 

const addInstitution = async (institutionData) => {
  try {
    const response = await apiClient.post('/api/AwardCenter', institutionData);
    if(response){
      logAction("Created AwardCenter","AwardCenter",`Created the following AwardCenter ${JSON.stringify(response.data)}`)
    }
    return response.data;
  } catch (error) {
    toast.error('Error in addInstitution:', error.response.data);
    throw error;
  }
};

const getInstitutionById = async (institutionId) => {
  try {
    const response = await apiClient.get(`/api/AwardCenter/${institutionId}`);
    return response.data;
  } catch (error) {
    toast.error(`Error fetching institution with ID ${institutionId}:`, error.response.data);
    throw error;
  }
};

const updateInstitution = async (institutionId, updatedData) => {
  try {
    const response = await apiClient.put(`/api/AwardCenter/${institutionId}`, updatedData);
    if(response){
      logAction("updated AwardCenter","AwardCenter",`Updated the following AwardCenter ${JSON.stringify(response.data)}`)
    }
    return response.data;
  } catch (error) {
    toast.error(`Error updating institution with ID ${institutionId}:`, error.response.data);
    throw error;
  }
};

const deleteInstitution = async (institutionId) => {
  try {
    const response = await apiClient.delete(`/api/AwardCenter/${institutionId}`);
    if(response){
      logAction("Deleted AwardCenter","AwardCenter",`Deleted the following AwardCenter ${JSON.stringify(response.data)}`)
    }
  } catch (error) {
    toast.error(`Error deleting institution with ID ${institutionId}:`, error.response.data);
    throw error;
  }
};

const getInstitutions = async () => {
  try {
    const response = await apiClient.get('/api/AwardCenter');
    return response.data;
  } catch (error) {
    toast.error('Error fetching institutions:', error.response.data);
    throw error;
  }
};

export { addInstitution, getInstitutionById, updateInstitution, deleteInstitution, getInstitutions };
