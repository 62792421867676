import PermissionTable from "../../../../components/tables/permissionsT";
import Modal from "react-modal";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { addPermission } from "../../../../services/permissionsS";
import Edit from "./Edit"
import Delete from "./Delete"
import { smallFormModal } from "../../../../styles/smallFormModal";
import InputContainer from "../../../../components/surfaces/InputContainer";
import { useState } from "react";
const Permissions = ({permissions,deletePermission,update})=>{
const [modalState,setModalState] = useState(false)
const [editModal,setEditModal] = useState("")
const [deleteModal,setDeleteModal] = useState("")

  const closeModal = ()=>{ 
    setModalState(false)
   }
   const openModal = ()=>{
    setModalState(true)

   }
   const openEditModal = (id)=>{
    setEditModal(id)
   }
   const openDeleteModal = (id)=>{
    setDeleteModal(id)
   }
   const closeEdit = ()=>{
    setEditModal("")
   }
   const closeDelete = ()=>{
    setDeleteModal("")
   }
  const schema = yup
    .object({
      name: yup.string().required("Permission name is required"),
      notes: yup.string().required("notes is required"),
    })
  const {
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema)
  });
  const inputConfig = [{
    disabled: false,
    type: "text",
    element: "input",
    label: "Permission Name",
    name: "name",
    placeholder: "Enter Permission Name",
  },{
    disabled: false,
    type: "text",
    element: "input",
    label: "Permission Notes",
    name: "notes",
    placeholder: "Enter Notes Name",
  }]
  
  const onSubmit = async (data) => {
    try {
      const response = await addPermission(data);
      update();
      closeModal();
    } catch (error) {
      console.error("Error occurred while submitting data:", error);
      // You can also add additional error handling logic here if needed
    }
  };
  

  return (
    <>
      <button onClick={openModal} class="border border-blue-500 text-blue-500 hover:bg-blue-500 hover:text-white font-bold py-2 px-4 rounded m-2">
        Add Permission
      </button>
      <PermissionTable permissions={permissions}  openEditModal={openEditModal} deletePermission={openDeleteModal} />;
     
       <Modal
        style={smallFormModal}
        isOpen={modalState}
        onRequestClose={closeModal}
        contentLabel={"Add User"}>
        <h2 className="subtitle2 mb-4">
        Add Permission
        </h2>
        <form onSubmit={handleSubmit(onSubmit)}>
        {inputConfig.map((field,index)=>{
        return ( <InputContainer
          disabled={field.disabled}
          error={errors[field.name]?.message}
          name={field.name}
          key={index}
          label={field.label}
          setValue={setValue}
          placeholder={field.placeholder}
          type={field.type}
        />)
        })}
        <div className="flex justify-end mt-4">
          <button
          type="submit"
            className="bg-primary px-5 text-white p-2 rounded mr-2">
            Save
          </button>
          <button
          onClick={closeModal}
            className="outline outline-1 outline-primary text-primary px-5 p-2 rounded">
            Cancel
          </button>
        </div>
        </form>
      </Modal>
     <Edit editModal={editModal} update={update} closeEdit={closeEdit} />
     <Delete deleteModal={deleteModal} update={update} closeDelete={closeDelete} />

    </>
  );
}

export default Permissions