import React from 'react';
import Table from '../common/Table';
import Dropdown from "../Dropdown/dropDown"

const columns = [
  { Header: 'Category ID', accessor: 'categoryID' },
  { Header: 'Category Name', accessor: 'categoryName' },
  { Header: 'Notes', accessor: 'notes' },
];

const TrainingCategoryTable = ({ trainingcategory, openEditModal, deleteTrainingCategory }) => {
  const renderRowActions = ({ categoryID }) => (
    <div className='z-50'>
    <Dropdown  onEdit={()=>openEditModal(categoryID)} onDelete={()=>deleteTrainingCategory(categoryID)} />
  </div>
  );

  return (
    <Table
      columns={columns}
      data={trainingcategory}
      renderRowActions={renderRowActions}
    />
  );
};

export default TrainingCategoryTable;
