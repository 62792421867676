function BoxWrapper({ children, onclick, className, loading }) {
  return (
    <div
      onClick={onclick}
      className={`${className} gap-1 bg-cover bg-center rounded-md p-4 flex cursor-pointer px-3 hover:scale-105 hover:z-10 transition-all ease-in-out duration-300`}
      style={{
        boxShadow:
          "0 4px 6px -1px rgba(59, 130, 246, 0.1), 0 2px 4px -1px rgba(59, 130, 246, 0.06)",
      }}
    >
      {loading ? (
        <div className="animate-pulse flex space-x-4 w-full">
          <div className="rounded-md bg-gray-300 h-20 w-20"></div>
          <div className="flex-1 space-y-4 py-1">
            <div className="h-4 bg-gray-300 rounded w-3/4"></div>
            <div className="h-4 bg-gray-300 rounded w-1/2"></div>
          </div>
        </div>
      ) : (
        children
      )}
    </div>
  );
}

export default BoxWrapper;
