import React, { useEffect, useState } from 'react';
import axios from 'axios';
import SystemLogsTable from '../../components/tables/SystemLogsT'; // Ensure the path is correct

const SystemLogs = () => {
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchLogs = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/SystemLogs`);
        setLogs(response.data);
      } catch (err) {
        setError('Failed to fetch system logs');
      } finally {
        setLoading(false);
      }
    };

    fetchLogs();
  }, []);

  return (
    <div className="w-full max-w-4xl mx-auto">
      <h2 className="text-2xl font-semibold text-white bg-primary p-4 mb-6 rounded-lg">
        System Logs
      </h2>

      {/* Display loading, error or table */}
      {loading && <p>Loading logs...</p>}
      {error && <p className="text-red-500">{error}</p>}
      {!loading && logs.length > 0 ? (
        <SystemLogsTable logs={logs} />
      ) : (
        !loading && <p>No system logs found.</p>
      )}
    </div>
  );
};

export default SystemLogs;
