import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import Layout from '../../components/Layout/layout';

// Importing icons from react-icons/fa (Font Awesome)
import { 
  FaCog, 
  FaBell, 
  FaPlug, 
  FaUsers, 
  FaClipboardList, 
  FaSync 
} from 'react-icons/fa';

/**
 * Settings Component
 * 
 * This component renders the settings page with a sidebar navigation
 * containing various settings options. Each navigation tab includes an
 * icon and text, with enhanced styles for active and hover states.
 */
const Settings = () => {
  return (
    <Layout>
      <div className="flex h-auto">
        <div className="w-full flex flex-col md:flex-row bg-white bg-opacity-50 rounded-lg shadow-lg p-6 gap-3 ml-4">
          
          {/* Left-side navigation */}
          <div className="md:w-1/4 w-full rounded-lg p-3">
            <ul className="space-y-4">
              
              {/* General Settings */}
              <li className="text-lg font-semibold text-primary tracking-wide">
                <NavLink
                  to="/settings/company"
                  className={({ isActive }) =>
                    isActive
                      ? "flex items-center space-x-2 font-satoshi tracking-[1px] py-2 px-4 rounded bg-primary text-white transition-colors duration-200"
                      : "flex items-center space-x-2 font-satoshi tracking-[1px] py-2 px-4 rounded text-primary hover:bg-[#EDEADE] transition-colors duration-200"
                  }
                >
                  <FaCog className="text-lg" />
                  <span>General Settings</span>
                </NavLink>
              </li>
              
              {/* Notification Settings */}
              <li className="text-lg font-semibold text-primary tracking-wide">
                <NavLink
                  to="/settings/notification"
                  className={({ isActive }) =>
                    isActive
                      ? "flex items-center space-x-2 font-satoshi tracking-[1px] py-2 px-4 rounded bg-primary text-white transition-colors duration-200"
                      : "flex items-center space-x-2 font-satoshi tracking-[1px] py-2 px-4 rounded text-primary hover:bg-[#EDEADE] transition-colors duration-200"
                  }
                >
                  <FaBell className="text-lg" />
                  <span>Notification</span>
                </NavLink>
              </li>
              
              {/* Integrations */}
              <li className="text-lg font-semibold text-primary tracking-wide">
                <NavLink
                  to="/settings/integrations"
                  className={({ isActive }) =>
                    isActive
                      ? "flex items-center space-x-2 font-satoshi tracking-[1px] py-2 px-4 rounded bg-primary text-white transition-colors duration-200"
                      : "flex items-center space-x-2 font-satoshi tracking-[1px] py-2 px-4 rounded text-primary hover:bg-[#EDEADE] transition-colors duration-200"
                  }
                >
                  <FaPlug className="text-lg" />
                  <span>Integrations</span>
                </NavLink>
              </li>
              
              {/* Users/Roles */}
              <li className="text-lg font-semibold text-primary tracking-wide">
                <NavLink
                  to="/settings/users"
                  className={({ isActive }) =>
                    isActive
                      ? "flex items-center space-x-2 font-satoshi tracking-[1px] py-2 px-4 rounded bg-primary text-white transition-colors duration-200"
                      : "flex items-center space-x-2 font-satoshi tracking-[1px] py-2 px-4 rounded text-primary hover:bg-[#EDEADE] transition-colors duration-200"
                  }
                >
                  <FaUsers className="text-lg" />
                  <span>Users/Roles</span>
                </NavLink>
              </li>
              
              {/* System Logs */}
              <li className="text-lg font-semibold text-primary tracking-wide">
                <NavLink
                  to="/settings/system-logs"
                  className={({ isActive }) =>
                    isActive
                      ? "flex items-center space-x-2 font-satoshi tracking-[1px] py-2 px-4 rounded bg-primary text-white transition-colors duration-200"
                      : "flex items-center space-x-2 font-satoshi tracking-[1px] py-2 px-4 rounded text-primary hover:bg-[#EDEADE] transition-colors duration-200"
                  }
                >
                  <FaClipboardList className="text-lg" />
                  <span>System Logs</span>
                </NavLink>
              </li>
              
              {/* Synchronization */}
              <li className="text-lg font-semibold text-primary tracking-wide">
                <NavLink
                  to="/settings/synchronization"
                  className={({ isActive }) =>
                    isActive
                      ? "flex items-center space-x-2 font-satoshi tracking-[1px] py-2 px-4 rounded bg-primary text-white transition-colors duration-200"
                      : "flex items-center space-x-2 font-satoshi tracking-[1px] py-2 px-4 rounded text-primary hover:bg-[#EDEADE] transition-colors duration-200"
                  }
                >
                  <FaSync className="text-lg" />
                  <span>Synchronization</span>
                </NavLink>
              </li>
              
            </ul>
          </div>
          
          {/* Divider */}
          <div className="hidden md:block border border-blue-300"></div>
          
          {/* Right-side content */}
          <div className="grow pl-4">
            <Outlet />
          </div>
          
        </div>
      </div>
    </Layout>
  );
};

export default Settings;
