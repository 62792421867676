import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {formatDateFromFormValues} from "../../utils/splitWordFunc"
import InputContainer from "../surfaces/InputContainer";
import Autocomplete from "../common/AutoComplete/autoComplete";
import styles from "../../styles/modal.module.css";
import { getStatus } from "../../services/awardCStatusS";
import { getStages } from "../../services/awardCStageS";
import { getCounty, getSubCounty } from "../../services/countiesS";
import { getCType } from "../../services/awardCTypeS";
import { addInstitution, updateInstitution } from "../../services/awardCenterS";


const InstitutionForm = ({
  formValues,
  editMode,
  closeAddInstitutionModal,
}) => {
  const [stages, setStages] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [counties, setCounties] = useState([]);
  const [subCounties, setSubCounties] = useState([]);
  const [awardCType, setawardCType] = useState([]);

  //useForm hook validation using yup
  const schema = yup
    .object({
      institutionID: yup.string().required("Institution ID is required"),
      stage: yup.string().required("Stage is required"),
      institutionName: yup.string().required("Institution Name is required"),
      status: yup.string().required("Status is required"),
      institutionEmail: yup
        .string()
        .email("Please enter a valid email address")
        .required("Institution Email is required"),
      institutionContact: yup
        .string()
        .matches(/^[0-9]+$/, "Institution Contact must be a valid number")
        .required("Institution Contact is required"),
        region: yup.string().required("Region is required"),
        county: yup.string().required("County is required"),
      subCounty: yup.string().required("Sub-County is required"),
      contactPerson: yup.string().required("Contact Person is required"),
      awardCType: yup.string().required("Award Type is required"),
      contactNumber: yup
        .string()
        .matches(/^[0-9]+$/, "Contact Number must be a valid number")
        .required("Contact Number is required"),
      licenseStartDate: yup.date().required("License Start Date is required"),
      licenseEndDate: yup.date().nullable(),
      source: yup.string().required("Source is required"),
      // notes: yup.string().nullable(),
      marginalised: yup.string().required("Marginalized Status is required"),
    })
   
  const {
    register,
    setValue,
    watch,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: formValues
  });

  // Watch the 'county' input field
  const selectedCounty = watch("county");
  const licenceStartDate = watch("licenseStartDate");

  const registerFunction = (name) =>{
    register(name)
  };
  
 //Fetch Stages
  const fetchStages = async () => {
    try {
      const fetchedStages = await getStages();
      setStages(fetchedStages);
    } catch (error) {
      error("Error fetching Institution Stages:", error);
    }
  };

  //Fetch Statuses
  const fetchStatuses = async () => {
    try {
      const fetchedStatuses = await getStatus();
      setStatuses(fetchedStatuses);
    } catch (error) {
      error("Error fetching Institution Statuses:", error);
    }
  };

  //Fetch Award center types
  const fetchAwardCenterTypes = async () => {
    try {
      const fetchedAwardCenterTypes = await getCType();
      setawardCType(fetchedAwardCenterTypes);
    } catch (error) {
      console.error("Error fetching Award Center Types:", error);
    }
  };

  //Fetch Counties
  const fetchCounties = async () => {
    try {
      const countyData = await getCounty();
      setCounties(countyData);
    } catch (error) {
      console.error("Error fetching counties:", error);
    }
  };
  useEffect(() => {
    fetchCounties();
    fetchStatuses();
    fetchAwardCenterTypes();
    fetchStages();
  }, []);

  useEffect(() => {
    // This effect runs every time 'selectedCounty' changes
    const fetchSubCounties = async () => {
      if (selectedCounty) {
        try {
          const subCountyData = await getSubCounty(selectedCounty);
          console.log(selectedCounty)
          setSubCounties(subCountyData);
          console.log(subCounties)
        } catch (error) {
          console.error("Error fetching sub-counties:", error);
          setSubCounties([]);
        }
      }
    };

    fetchSubCounties();
  }, [selectedCounty]);

        // Calculate one year later
  useEffect(() => {
    if (licenceStartDate) {
      const startDate = new Date(licenceStartDate);
      const endDate = new Date(startDate);
      endDate.setFullYear(startDate.getFullYear() + 1);
      const formattedEndDate = endDate.toISOString().split("T")[0];
      setValue("licenseEndDate", formattedEndDate);
    }
  }, [licenceStartDate, setValue]);
 
  const inputConfig = [
    // {
    //   type: "text",
    //   element: "input",
    //   label: "Institution ID",
    //   name: "institutionID",
    //   placeholder: "Enter Institution ID",
    //   disabled: true,
    // },
    {
      type: "text",
      element: "input",
      label: "Institution Name",
      name: "institutionName",
      placeholder: "Enter Institution Name",
    },
    {
      type: "text",
      element: "select",
      label: "Stage",
      name: "stage",
      placeholder: "Enter Stage",
      options: stages.map((stage) => ({
        name: stage.stageName,
        id: stage.stageName,
      })),
    },
    {
      type: "text",
      element: "select",
      label: "Status",
      name: "status",
      placeholder: "Select status",
      options: statuses.map((status) => ({ name: status.statusName,id:  status.StatusID })),
    },
    {
      type: "text",
      element: "input",
      label: "Institution Email",
      name: "institutionEmail",
      placeholder: "Enter Institution Email",
    },
    {
      type: "text",
      element: "input",
      label: "Institution Contact",
      name: "institutionContact",
      placeholder: "Enter Institution Contact",
    },
    {
      type: "text",
      element: "select",
      label: "Region",
      name: "region",
      placeholder: "Region",
      options: [
        { name: "Central" },
        { name: "Coast" },
        { name: "Lower Eastern" },
        { name: "Nairobi" },
        { name: "North Eastern" },
        { name: "North Rift" },
        { name: "Nyanza" },
        { name: "South Rift" },
        { name: "Upper Eastern" },
        { name: "Western" },
      ],
    },
    {
      type: "text",
      element: "select",
      label: "County",
      name: "county",
      placeholder: "Select county",
      options: counties.map((county) => ({
        name: county.countyName,
        id: county.countyID,
      })),
    },
    {
      type: "text",
      element: "select",
      label: "Sub-County",
      name: "subCounty",
      placeholder: "Select Sub-County",
      options: subCounties.map((subCounty) => ({
        name: subCounty.SubCountyName,
        id: subCounty.SubCountyName,
      })),
    },
    {
      type: "text",
      element: "input",
      label: "Contact Person",
      name: "contactPerson",
      placeholder: "Enter Contact Person",
    },
    {
      type: "text",
      element: "input",
      label: "Contact Number",
      name: "contactNumber",
      placeholder: "Enter Contact Number",
    },
    {
      type: "date",
      element: "input",
      label: "License Start Date",
      name: "licenseStartDate",
      placeholder: "Enter License Start Date",
    },
    {
      disabled: true,
      type: "text",
      element: "input",
      label: "License End Date",
      name: "licenseEndDate",
      placeholder: "License End Date",
    },
    {
      type: "number",
      element: "select",
      label: "Select Award Center Type",
      name: "awardCType",
      placeholder: "Select  Award Type",
      options: awardCType.map((award) => ({
        name: award.centerName,
        id: award.id,
      })),
    },
    {
      type: "text",
      element: "select",
      label: "How did you learn about us?",
      name: "source",
      placeholder: "How did you learn about us?",
      options: [
        { name: "Social Media" },
        { name: "Our Website" },
        { name: "Educational Forums" },
        { name: "Referral" },
        { name: "Google" },
        { name: "President's Award Team" },
      ],
    },
    {
      type: "text",
      element: "input",
      label: "Notes",
      name: "notes",
      placeholder: "Enter Notes",
    },
    {
      type: "text",
      element: "select",
      label: "Marginalized Status",
      name: "marginalised",
      placeholder: "Select Marginalized Status",
      options: [
        { name: "Yes", id: "Yes" },
        { name: "No", id: "yes" },
      ],
    },
  ];

  const onSubmit = async (data) => {
    try {
      console.log(data)
      // Filter out optional fields that are null or undefined
      const payload = {
        ...data,
        notes: data.notes || undefined, // Ensure `notes` is not sent if undefined or null
      };
      if (editMode) {
         await updateInstitution(data.institutionID, data);
         return closeAddInstitutionModal()
      }
      await addInstitution(data);
      return closeAddInstitutionModal()
    } catch (error) {
     
    }
  };


  return (
    <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
      <div className="grid grid-cols-1 gap-2  md:grid-cols-2">
        {inputConfig.map((field, index) => {
          if (field.element === "input") {
            return (
              <InputContainer
                disabled={field.disabled}
                error={errors[field.name]?.message}
                name={field.name}
                key={index}
                value={editMode ? formValues[field.name] : undefined}
                label={field.label}
                setValue={setValue}
                placeholder={field.placeholder}
                type={field.type}
              />
            );
          } else {
            return (
              <div>
                <Autocomplete
                  key={index}
                  value={formValues && formValues[field.name]}
                  setValue={setValue}
                  options={[...field.options]}
                  label={field.label}
                  name={field.name}
                  error={errors[field.name]?.message}
                />
              </div>
            );
          }
        })}
      </div>
      <div className="flex justify-end mt-4">
        <button className="bg-primary px-5 text-white p-2 rounded mr-2">
          {editMode ? "Update" : "Save"}
        </button>
        <button
          onClick={closeAddInstitutionModal}
          className="outline outline-1 outline-primary text-primary px-5 p-2 rounded">
          Cancel
        </button>
      </div>
    </form>
  );
};


export default React.memo(InstitutionForm);