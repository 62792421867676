import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import { FaPlus } from "react-icons/fa6";
import { splitWordFunc } from "../../utils/splitWordFunc";
import { FiSearch } from "react-icons/fi";
import { FaFilter } from "react-icons/fa";
import { customStyles } from "../../styles/customStyles";
import StagesForm from "../../components/forms/awardCenterStagesF";
import StagesTable from "../../components/tables/awardCStagesT";
import {
  addStages as addStagesService,
  getStagesById,
  updateStages,
  deleteStages,
  getStages,
} from "../../services/awardCStageS";
import Layout from "../../components/Layout/layout";

Modal.setAppElement("#root");

const AddStages = () => {
  const [stages, setStages] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newStage, setNewStage] = useState({
    stageID: "",
    stageName: "",
    notes: "",
  });
  const [selectedColumn, setSelectedColumn] = useState("");
  const [filteredStages, setFilteredStages] = useState(stages);
  const [searchTerm, setSearchTerm] = useState("");
  const [errors, setErrors] = useState({});
  const [update,setUpdate] = useState(false)
  const [editMode, setEditMode] = useState(false);
  const [selectedStageID, setSelectedStageID] = useState(null);

  useEffect(() => {
    const fetchStages = async () => {
      try {
        const fetchedStages = await getStages();
        setStages(fetchedStages);
        setFilteredStages(fetchedStages);
      } catch (error) {
        toast.error("Error fetching stages:", error.response.data);
      }
    };

    fetchStages();
  }, [update]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewStage((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setNewStage((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const addNewStage = async () => {
    try {
      const stagePayload = {
        stageID: String(newStage.stageID), // Ensure stageID is a string
        stageName: newStage.stageName,
        notes: newStage.notes,
      };

      await addStagesService(stagePayload);
     setUpdate(prev=>!prev)
      setIsModalOpen(false);
    } catch (error) {
      toast.error("Error adding stage:", error.response.data);
      setErrors(error.response.data.errors || {});
      alert(
        `Failed to add stage: ${
          error.response.data.title
        }\nDetails: ${JSON.stringify(error.response.data.errors, null, 2)}`
      );
    }
  };

  const openAddStagesModal = () => {
    setEditMode(false);
    setIsModalOpen(true);
    setNewStage({
      stageID: "",
      stageName: "",
      notes: "",
    });
  };

  const openEditStageModal = async (stageID) => {
    try {
      const fetchedStage = await getStagesById(String(stageID));
      setEditMode(true);
      setIsModalOpen(true);
      setSelectedStageID(stageID);
      setNewStage({
        ...fetchedStage,
      });
    } catch (error) {
      toast.error(
        `Error fetching stage with ID ${stageID}:`,
        error.response.data
      );
    }
  };

  const updateExistingStage = async () => {
    try {
      const stagePayload = { ...newStage };
      const updatedStage = await updateStages(selectedStageID, stagePayload);
      setStages((prev) =>
        prev.map((inst) =>
          inst.stageID === selectedStageID ? updatedStage : inst
        )
      );
      setIsModalOpen(false);
    } catch (error) {
      toast.error(
        `Error updating stage with ID ${selectedStageID}:`,
        error.response.data
      );
      setErrors(error.response.data.errors || {});
      alert(
        `Failed to update stage: ${
          error.response.data.title
        }\nDetails: ${JSON.stringify(error.response.data.errors, null, 2)}`
      );
    }
  };

  const deleteExistingStage = async (stageID) => {
    try {
      await deleteStages(stageID);
      setUpdate(prev=>!prev)
    } catch (error) {
      toast.error(
        `Error deleting stage with ID ${stageID}:`,
        error.response.data
      );
      alert(`Failed to delete stage: ${error.response.data.title}`);
    }
  };

  const closeAddStagesModal = () => {
    setIsModalOpen(false);
    setEditMode(false);
    setErrors({});
  };

  const deleteStageHandler = (stageID) => {
    if (
      window.confirm(
        `Are you sure you want to delete stage with ID ${stageID}?`
      )
    ) {
      deleteExistingStage(stageID);
    }
  };

  //new
  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    filterStages(value, selectedColumn);
  };
  const handleFilterParameterChange = (event) => {
    const value = event.target.value;
    setSelectedColumn(value);
    filterStages(searchTerm, value);
  };

  const filterStages = (searchTerm, column) => {
    const filtered = stages?.filter((stages) =>
      stages[column].toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredStages(filtered);
  };
  return (
    <Layout>
    <div className="bg-white rounded-lg shadow-lg">
      <div className="px-6 py-4 border-b border-gray-200">
        <h1 className="text-2xl font-semibold text-gray-800">Stages</h1>
      </div>

      <div className="p-6">
        <div className="space-y-4 md:space-y-0 md:flex md:items-center md:justify-between">
          <button
            onClick={openAddStagesModal}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-all duration-200"
          >
            <FaPlus className="mr-2 h-4 w-4" />
            Add Stage
          </button>

          <div className="flex flex-col md:flex-row gap-4 flex-1 md:max-w-2xl md:ml-6">
            <div className="relative flex-1">
              <select
                className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-md bg-white shadow-sm"
                value={selectedColumn}
                onChange={handleFilterParameterChange}
              >
                {stages.length > 0 ? (
                  Object.keys(stages[0]).map((op, index) => (
                    <option key={index} value={op}>
                      {splitWordFunc(op)}
                    </option>
                  ))
                ) : (
                  <option>No filter property</option>
                )}
              </select>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <FaFilter  className="h-4 w-4 text-gray-400" />
              </div>
            </div>

            <div className="relative flex-1">
              <input
                type="text"
                placeholder="Search stages..."
                onChange={handleSearchChange}
                className="block w-full pl-3 pr-10 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
              <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                <FiSearch className="h-5 w-5 text-gray-400" />
              </div>
            </div>
          </div>
        </div>

        <div className="mt-8">
          <StagesTable
            stages={filteredStages}
            openEditModal={openEditStageModal}
            deleteStage={deleteStageHandler}
          />
        </div>
      </div>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeAddStagesModal}
        contentLabel={editMode ? "Edit Stage" : "Add Stage"}
        style={customStyles}
      >
        <h2 className="text-xl font-semibold text-gray-800 mb-4">
          {editMode ? "Edit Stage" : "Add Stage"}
        </h2>
        <StagesForm
          formValues={newStage}
          handleInputChange={handleInputChange}
          handleDateChange={handleDateChange}
          errors={errors}
        />
        <div className="flex justify-end mt-4">
          <button
            onClick={editMode ? updateExistingStage : addNewStage}
            className="bg-indigo-600 px-5 text-white p-2 rounded mr-2 hover:bg-indigo-700 transition-all duration-200"
          >
            {editMode ? "Update" : "Save"}
          </button>
          <button
            onClick={closeAddStagesModal}
            className="outline outline-1 outline-indigo-600 px-5 text-indigo-600 p-2 rounded hover:bg-gray-100 transition-all duration-200"
          >
            Cancel
          </button>
        </div>
      </Modal>
    </div>
  </Layout>
  );
};

export default AddStages;
