import React, { useState, useEffect } from "react";
import { customStyles } from "../../styles/customStyles";
import { FaPlus } from "react-icons/fa6";
import { FiSearch } from "react-icons/fi";
import { FaFilter } from "react-icons/fa";
import { splitWordFunc } from "../../utils/splitWordFunc";
import InstitutionForm from "../../components/forms/awardCenterF";
import InstitutionTable from "../../components/tables/awardCenterT";
import { getUsersByUsername } from "../../services/usersS";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import {
  addInstitution as addInstitutionService,
  getInstitutionById,
  updateInstitution,
  deleteInstitution,
  getInstitutions,
} from "../../services/awardCenterS";
import { getLoggedInUser } from "../../utils/getLoggedInUser";
import Layout from "../../components/Layout/layout";
import Modal from "react-modal";
import ViewComponent from "../../components/ViewComponent";

const AddInstitution = () => {
  const [institutions, setInstitutions] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [user, setUser] = useState({});
  const [viewData, setViewData] = useState({});
  const [selectedColumn, setSelectedColumn] = useState("");
  const [update, setUpdate] = useState(false);
  const [editableInstitution, setEditableInstitution] = useState({});
  const [filteredInstitutions, setFilteredInstitutions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchInstitutions = async () => {
      try {
        const userName = localStorage.getItem("user");
        const loggedInUser = JSON.parse(userName).user;

        let fetchedInstitutions;

        if (loggedInUser.role.name === "Award Leader" || loggedInUser.role.name === "Adult Helper") {
          const userData = await getUsersByUsername(loggedInUser.username);
          fetchedInstitutions = [userData.awardCenter];
        } else {
          fetchedInstitutions = await getInstitutions();
        }

        setInstitutions(fetchedInstitutions);
        setFilteredInstitutions(fetchedInstitutions);

        // Set initial selected column if not set
        if (!selectedColumn && fetchedInstitutions.length > 0) {
          setSelectedColumn(Object.keys(fetchedInstitutions[0])[0]);
        }
      } catch (error) {
        toast.error("Error fetching institutions:", error);
      }
    };
    fetchInstitutions();
  }, [update]);

  // Updated filter function
  const filterInstitutions = (term, column) => {
    if (!term || !column) {
      setFilteredInstitutions(institutions);
      return;
    }

    const filtered = institutions.filter((institution) => {
      const value = institution[column];
      return value && value.toString().toLowerCase().includes(term.toLowerCase());
    });

    setFilteredInstitutions(filtered);
  };

  const handleFilterParameterChange = (event) => {
    const value = event.target.value;
    setSelectedColumn(value);
    filterInstitutions(searchTerm, value);
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    filterInstitutions(value, selectedColumn);
  };

  const generateInstitutionID = () => {
    if (institutions.length > 0) {
      const lastInstitution = institutions[institutions.length - 1];
      const lastID = parseInt(lastInstitution.institutionID.split("-")[2], 10);

      if (!isNaN(lastID)) {
        const newID = `PAK-AC-${String(lastID + 1).padStart(3, "0")}`;
        return newID;
      }
    }
    return "PAK-AC-001";
  };

  const openEditInstitutionModal = async (institutionID) => {
    try {
      const fetchedInstitution = await getInstitutionById(institutionID);
      setEditableInstitution(fetchedInstitution);
      setEditMode(true);
      setIsModalOpen(true);
    } catch (error) {
      toast.error(`Error fetching institution with ID ${institutionID}:`, error);
    }
  };

  const openViewModal = async (institutionID) => {
    try {
      const fetchedInstitution = await getInstitutionById(institutionID);
      setViewModal(true);
      setViewData(fetchedInstitution);
    } catch (error) {
      toast.error(`Error fetching institution with ID ${institutionID}:`, error);
    }
  };

  const handleViewClose = () => {
    setViewModal(false);
  };

  const deleteExistingInstitution = async (institutionId) => {
    try {
      await deleteInstitution(institutionId);
      setUpdate((prev) => !prev);
      setIsModalOpen(false);
    } catch (error) {
      toast.error(`Error deleting institution with ID ${institutionId}:`, error);
      alert(`Failed to delete institution: ${error.message}`);
    }
  };

  const closeAddInstitutionModal = () => {
    setIsModalOpen(false);
    setEditMode(false);
    setUpdate((prev) => !prev);
  };

  const deleteInstitutionHandler = (institutionId) => {
    if (window.confirm(`Are you sure you want to delete institution with ID ${institutionId}?`)) {
      deleteExistingInstitution(institutionId);
    }
  };

  return (
    <Layout>
      <div className="bg-white rounded-lg shadow-lg">
        <div className="px-6 py-4 border-b border-gray-200">
          <h1 className="text-2xl font-semibold text-gray-800">
            Award Centers
          </h1>
        </div>

        <div className="p-6">
          <div className="space-y-4 md:space-y-0 md:flex md:items-center md:justify-between">
            <button
              disabled={["Award Leader", "Adult Helper"].includes(user?.role?.name)}
              title={
                ["Award Leader", "Adult Helper"].includes(user?.role?.name)
                  ? "You are not allowed to add award centers"
                  : "Add Award Center"
              }
              onClick={() => {
                setIsModalOpen(true);
                setEditableInstitution({});
              }}
              className={`inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white transition-all duration-200 ${["Award Leader", "Adult Helper"].includes(user?.role?.name)
                  ? "bg-gray-400 cursor-not-allowed"
                  : "bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                }`}
            >
              <FaPlus className="mr-2 h-4 w-4" />
              Add Center
            </button>


            <div className="flex flex-col md:flex-row gap-4 flex-1 md:max-w-2xl md:ml-6">
              <div className="relative flex-1">
                <select
                  className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-md bg-white shadow-sm"
                  value={selectedColumn}
                  onChange={handleFilterParameterChange}
                >
                  {institutions.length > 0 ? (
                    Object.keys(institutions[0]).map((op, index) => (
                      <option key={index} value={op}>
                        {splitWordFunc(op)}
                      </option>
                    ))
                  ) : (
                    <option>No filter property</option>
                  )}
                </select>
                <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <FaFilter className="h-4 w-4 text-gray-400" />
                </div>
              </div>

              <div className="relative flex-1">
                <input
                  type="text"
                  placeholder="Search award centers..."
                  value={searchTerm}
                  onChange={handleSearchChange}
                  className="block w-full pl-3 pr-10 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
                <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                  <FiSearch className="h-5 w-5 text-gray-400" />
                </div>
              </div>
            </div>
          </div>

          <div className="mt-8">
            <InstitutionTable
              openViewModal={openViewModal}
              institutions={filteredInstitutions}
              openEditModal={openEditInstitutionModal}
              deleteInstitution={deleteInstitutionHandler}
            />
          </div>
        </div>

        <Modal
          isOpen={isModalOpen}
          onRequestClose={closeAddInstitutionModal}
          contentLabel={editMode ? "Edit Institution" : "Add Institution"}
          style={customStyles}
        >
          <h2 className="text-xl font-semibold text-gray-800 mb-4">
            {editMode ? "Edit Award Center" : "Add Award Center"}
          </h2>
          <InstitutionForm
            editMode={editMode}
            formValues={
              editMode
                ? editableInstitution
                : { institutionID: generateInstitutionID() }
            }
            closeAddInstitutionModal={closeAddInstitutionModal}
          />
        </Modal>

        <ViewComponent
          handleClose={handleViewClose}
          isModalOpen={viewModal}
          inputConfig={viewData}
          moduleName="Award Center"
        />
      </div>
    </Layout>
  );
};

export default AddInstitution;