import React, { useState } from 'react';
import Calendar from 'react-calendar';
import Modal from 'react-modal';
import 'react-calendar/dist/Calendar.css';
import Layout from '../../components/Layout/layout';

const CalendarPage = () => {
  const [date, setDate] = useState(new Date());
  const [events, setEvents] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [eventTitle, setEventTitle] = useState('');

  const openModal = (date) => {
    setSelectedDate(date);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setEventTitle('');
  };

  const addEvent = () => {
    if (!eventTitle.trim()) return;
    
    const dateString = selectedDate.toDateString();
    const newEvents = { ...events };
    if (!newEvents[dateString]) {
      newEvents[dateString] = [];
    }
    newEvents[dateString].push(eventTitle);
    setEvents(newEvents);
    closeModal();
  };

  const renderEvents = (date) => {
    const dateString = date.toDateString();
    return events[dateString]?.map((event, index) => (
      <div 
        key={index} 
        className="bg-blue-100 p-1.5 rounded-md mt-1 text-xs text-center text-blue-800 font-medium shadow-sm"
      >
        {event}
      </div>
    ));
  };

  const tileClassName = ({ date, view }) => {
    const dateString = date.toDateString();
    return events[dateString] 
      ? 'bg-blue-50 text-blue-800 rounded-full hover:bg-blue-100 transition-colors' 
      : 'hover:bg-gray-50 transition-colors';
  };

  return (
    <Layout>
      <div className="min-h-screen bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <div className="mb-8">
            <h1 className="text-3xl font-bold text-gray-900">Calendar</h1>
            <p className="mt-2 text-sm text-gray-600">
              Manage your events and schedules
            </p>
          </div>

          <div className="bg-white rounded-xl shadow-lg p-6">
            <Calendar
              onClickDay={openModal}
              onChange={setDate}
              value={date}
              calendarType='islamic'
              tileContent={({ date, view }) => 
                view === 'month' && renderEvents(date)
              }
              className="w-full border-none"
              tileClassName={tileClassName}
            />
          </div>

          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            contentLabel="Add Event"
            className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-xl shadow-xl p-8 w-full max-w-md"
            overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-50"
          >
            <div className="space-y-6">
              <div>
                <h2 className="text-2xl font-bold text-gray-900">Add Event</h2>
                <p className="mt-1 text-sm text-gray-500">
                  {selectedDate?.toLocaleDateString('en-US', {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                  })}
                </p>
              </div>

              <div>
                <label 
                  htmlFor="eventTitle" 
                  className="block text-sm font-medium text-gray-700"
                >
                  Event Title
                </label>
                <input
                  id="eventTitle"
                  type="text"
                  value={eventTitle}
                  onChange={(e) => setEventTitle(e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                  placeholder="Enter event title"
                />
              </div>

              <div className="flex justify-end space-x-3">
                <button
                  onClick={closeModal}
                  className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  Cancel
                </button>
                <button
                  onClick={addEvent}
                  className="px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  Add Event
                </button>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </Layout>
  );
};

export default CalendarPage;
