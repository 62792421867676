import React from 'react';
import Table from '../common/Table';
import Dropdown from "../Dropdown/dropDown"

const columns = [
  { Header: 'Funding ID', accessor: 'fundingID' },
  { Header: 'Funding Name', accessor: 'fundingName' },
  { Header: 'Notes', accessor: 'notes' },
];

const FundingTypeTable = ({ fundingtype, openEditModal, deleteFundingType }) => {
  const renderRowActions = ({ fundingID }) => (
    <div className='z-50'>
    <Dropdown  onEdit={()=>openEditModal(fundingID)} onDelete={()=>deleteFundingType(fundingID)} />
  </div>
  );

  return (
    <Table
      columns={columns}
      data={fundingtype}
      renderRowActions={renderRowActions}
    />
  );
};

export default FundingTypeTable;
