import React, { useEffect, useState, useMemo, useCallback } from "react";
import Layout from "../../components/Layout/layout";
import { getInstitutions } from "../../services/awardCenterS";
import { getParticipant } from "../../services/participantS";
import { getAwards } from "../../services/participantAwardS";
import { getProjects } from "../../services/projectService";
import { GoOrganization, GoProjectSymlink } from "react-icons/go";
import { getLoggedInUser, returnParticipantsWithAwards } from "../../utils/getLoggedInUser";
import { IoManOutline, IoWomanOutline } from "react-icons/io5";
import { RiGroupFill } from "react-icons/ri";
import { MdGroups2 } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import BoxWrapper from "./components/BoxWrapper/boxWrapper";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 

const AWARD_LEVELS = ["Bronze", "Silver", "Gold", "Bronze & Gold", "Silver & Gold", "Silver & Bronze"];
const RESTRICTED_ROLES = ["Award Leader", "Adult Helper"];
const Chart = React.lazy(() => import('./components/Chart/chart'));
const UserMap = React.lazy(() => import('./Map'));
const AwardGainedParticipantCard = React.lazy(() => import('./components/AwardGainedParticipantsCard'));
const ProjectsSummaryCard = React.lazy(() => import('./components/ProjectsSummaryCard'));

const INITIAL_DASHBOARD_DATA = {
  awardCenters: [],
  participants: [],
  participantsAwards: [],
  projects: [],
};

const GenderCount = React.memo(({ participants, gender, Icon }) => (
  <strong className="text-xl text-gray-700 flex justify-center items-center gap-1">
    <span>
      <Icon className={`text-primary text-bold ${gender === 'male' ? 'text-[30px]' : 'text-[24px]'}`} />
    </span>
    <span className="font-[600]">
      {participants.filter(p => p.gender.trim().toLowerCase() === gender).length}
    </span>
  </strong>
));

const DashboardCard = React.memo(({ config, loading, onNavigate }) => {
  const handleClick = useCallback(() => {
    onNavigate(config.path);
  }, [config.path, onNavigate]);

  const totalCount = config.secondaryText?.length || 0;
  const showGenderCount = config.children && Array.isArray(config.secondaryText);

  // Placeholder state for better UX when loading
  if (loading) {
    return (
      <BoxWrapper 
        className={`${config.bgColor} ${config.bgImage} flex justify-center gap-4 animate-pulse`}
      >
        <div className="flex flex-col justify-center items-center w-full">
          <div className="h-6 bg-gray-200 rounded w-3/4 mb-2"></div>
          <div className="h-4 bg-gray-300 rounded w-1/2"></div>
        </div>
      </BoxWrapper>
    );
  }

  return (
    <BoxWrapper
      className={`${config.bgColor} ${config.bgImage} flex justify-center gap-4`}
      onclick={handleClick}
    >
      <div className="flex flex-col justify-center items-center">
        {!showGenderCount && (
          <span className="subtitle1 font-[600] font-Satoshi mr-4 text-[20px] text-main">
            {totalCount}
          </span>
        )}
        {showGenderCount && (
          <div className="flex items-center gap-2 mt-3 mb-3">
            <GenderCount participants={config.secondaryText} gender="male" Icon={IoManOutline} />
            <GenderCount participants={config.secondaryText} gender="female" Icon={IoWomanOutline} />
          </div>
        )}
        <span className={`${showGenderCount ? 'text-[20px] text-bold' : 'font-[500] text-[20px]'} font-Satoshi ${config.textColor}`}>
          {config.primaryText}
        </span>
      </div>
      <div className="rounded-full flex items-center justify-center">
        {config.Icon}
      </div>
      {showGenderCount && (
        <span className="subtitle2 text-main">{totalCount}</span>
      )}
    </BoxWrapper>
  );
});

// Improved data fetching hook with progressive loading
const useDataFetching = () => {
  const [dashboardData, setDashboardData] = useState(INITIAL_DASHBOARD_DATA);
  const [loadingStates, setLoadingStates] = useState({
    institutions: true,
    participants: true,
    awards: true,
    projects: true
  });

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      try {
        const [loggedInUser] = await Promise.all([getLoggedInUser()]);
        
        if (!isSubscribed) return;

        const isRestrictedRole = RESTRICTED_ROLES.includes(loggedInUser?.role?.name);

        // Fetch data progressively
        const fetchInstitutions = async () => {
          try {
            const institutions = await getInstitutions();
            if (isSubscribed) {
              setDashboardData(prev => ({
                ...prev,
                awardCenters: isRestrictedRole ? [loggedInUser.awardCenters] : institutions
              }));
              setLoadingStates(prev => ({ ...prev, institutions: false }));
            }
          } catch (error) {
            toast.error('Error fetching institutions:', error);
          }
        };

        const fetchParticipants = async () => {
          try {
            const participants = await getParticipant();
            if (isSubscribed) {
              const institutionFilter = (item) => 
                isRestrictedRole ? item.institutionName === loggedInUser.awardCenter.institutionName : true;
              
              setDashboardData(prev => ({
                ...prev,
                participants: participants.filter(institutionFilter)
              }));
              setLoadingStates(prev => ({ ...prev, participants: false }));
            }
          } catch (error) {
            toast.error('Error fetching participants:', error);
          }
        };

        const fetchAwards = async () => {
          try {
            const participantsAwards = await getAwards();
            if (isSubscribed) {
              const institutionFilter = (item) => 
                isRestrictedRole ? item.institutionName === loggedInUser.awardCenter.institutionName : true;
              
              setDashboardData(prev => ({
                ...prev,
                participantsAwards: participantsAwards.filter(institutionFilter)
              }));
              setLoadingStates(prev => ({ ...prev, awards: false }));
            }
          } catch (error) {
            toast.error('Error fetching awards:', error);
          }
        };

        const fetchProjects = async () => {
          try {
            const projects = await getProjects();
            if (isSubscribed) {
              setDashboardData(prev => ({
                ...prev,
                projects
              }));
              setLoadingStates(prev => ({ ...prev, projects: false }));
            }
          } catch (error) {
            toast.error('Error fetching projects:', error);
          }
        };

        // Trigger all fetch operations
        fetchInstitutions();
        fetchParticipants();
        fetchAwards();
        fetchProjects();

      } catch (error) {
        toast.error('Error in main data fetching:', error);
      }
    };

    fetchData();
    return () => {
      isSubscribed = false;
    };
  }, []);

  // Determine if overall loading is complete
  const loading = Object.values(loadingStates).some(state => state);

  return [dashboardData, loading, loadingStates];
};

const DashboardStatsGrid = () => {
  const [dashboardData, loading, loadingStates] = useDataFetching();
  const navigate = useNavigate();

  const filterParticipants = useCallback((participants, status, levelName) => {
    if (!participants) return [];
    return participants.filter(
      (participant) =>
        participant.status.toLowerCase() === status.toLowerCase() &&
        participant.levelName.toLowerCase() === levelName.toLowerCase()
    );
  }, []);

  const dashboardCardsConfig = useMemo(() => {
    const { awardCenters, participants, participantsAwards, projects } = dashboardData;
    const activeParticipants = returnParticipantsWithAwards(
      participants,
      participantsAwards.filter(p => p.status === "In Progress")
    );

    return [
      {
        path: "/institutions/addInstitutions",
        bgColor: "bg-white",
        bgImage: "bg-card-bg-3",
        Icon: <GoOrganization className="text-4xl text-primary" />,
        textColor: "text-main",
        primaryText: "Total Centers",
        secondaryText: awardCenters,
      },
      {
        path: "/participants/participant",
        bgColor: "bg-white",
        bgImage: "bg-card-bg-1",
        Icon: <MdGroups2 className="text-4xl text-primary" />,
        textColor: "text-main",
        primaryText: "Total Participants",
        secondaryText: participants,
        children: true
      },
      {
        path: "/participants/participant",
        bgColor: "bg-white",
        bgImage: "bg-card-bg-2",
        Icon: <RiGroupFill className="text-4xl text-primary" />,
        textColor: "text-main",
        primaryText: "Active Participants",
        secondaryText: activeParticipants,
        children: true
      },
      {
        path: "/project/project",
        bgColor: "bg-white",
        bgImage: "bg-card-bg-2",
        Icon: <GoProjectSymlink className="text-4xl text-primary" />,
        textColor: "text-main",
        primaryText: "Projects",
        secondaryText: projects,
      }
    ];
  }, [dashboardData]);

  const handleNavigate = useCallback((path) => {
    navigate(path);
  }, [navigate]);

  const barData = useMemo(() => ({
    labels: AWARD_LEVELS,
    datasets: [{
      label: "Highest Award gained per Award Level",
      data: [10, 15, 20, 10],
      backgroundColor: [
        "rgba(205, 127, 50, 0.2)",
        "rgba(192, 192, 192, 0.2)",
        "rgba(255, 215, 0, 1)",
        "rgba(255, 215, 0, 0.2)",
      ],
      borderColor: [
        "rgba(205, 127, 50, 1)",
        "rgba(192, 192, 192, 1)",
        "rgba(255, 215, 0, 1)",
        "rgba(255, 215, 0, 1)",
      ],
      borderWidth: 1,
    }],
  }), []);

  return (
    <Layout>
      <div className="px-2 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-2 md:gap-3">
        {dashboardCardsConfig.map((config, i) => (
          <DashboardCard
            key={i}
            config={config}
            loading={loadingStates[Object.keys(loadingStates)[i]]}
            onNavigate={handleNavigate}
          />
        ))}
      </div>

      <div className="w-full grid grid-cols-1 z-0 pb-6 h-full md:grid-cols-1 lg:grid-cols-2">
        <React.Suspense fallback={<div>Loading chart...</div>}>
          <Chart 
            participants={dashboardData.participantsAwards} 
            loading={loadingStates.awards} 
          />
        </React.Suspense>
        <div className="w-full h-full grid grid-row-2 mt-10">
          <div className="w-full grid grid-cols-1 md:grid-cols-2 p-2 gap-1">
            <React.Suspense fallback={<div>Loading awards...</div>}>
              <AwardGainedParticipantCard 
                loading={loadingStates.awards} 
                barData={barData} 
                filterParticipants={filterParticipants} 
                participantsAwards={dashboardData.participantsAwards} 
              />
            </React.Suspense>
            <React.Suspense fallback={<div>Loading projects...</div>}>
              <ProjectsSummaryCard 
                centers={dashboardData.awardCenters} 
                loading={loadingStates.projects} 
              />
            </React.Suspense>
          </div>
          <div className="w-full p-2 h-full">
            <React.Suspense fallback={<div>Loading map...</div>}>
              <UserMap loading={loadingStates.participants} />
            </React.Suspense>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default React.memo(DashboardStatsGrid);