import apiClient from './apiClient';
import { logAction } from '../utils/logger';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 

const addTrainingLevel = async (TrainingLevelData) => {
  try {
    const response = await apiClient.post('/api/TrainingLevel', TrainingLevelData);
    if(response){
      logAction("Created Training Level","Training Level",`Created the following Training Level ${JSON.stringify(response.data)}`)
    }
    return response.data;
  } catch (error) {
    toast.error('Error in addTrainingLevel:', error.response.data);
    throw error;
  }
};

const getTrainingLevelById = async (trainingLevelID) => {
  try {
    const response = await apiClient.get(`/api/TrainingLevel/${trainingLevelID}`);
    return response.data;
  } catch (error) {
    toast.error(`Error fetching TrainingLevel with ID ${trainingLevelID}:`, error.response.data);
    throw error;
  }
};

const updateTrainingLevel = async (trainingLevelID, updatedData) => {
  try {
    const response = await apiClient.put(`/api/TrainingLevel/${trainingLevelID}`, updatedData);
    if(response){
      logAction("Updated Training Level","Training Level",`Updated the following Training Level${JSON.stringify(response.data)}`)
    }
    return response.data;
  } catch (error) {
    toast.error(`Error updating TrainingLevel with ID ${trainingLevelID}:`, error.response.data);
    throw error;
  }
};

const deleteTrainingLevel = async (trainingLevelID) => {
  try {
  const response =  await apiClient.delete(`/api/TrainingLevel/${trainingLevelID}`);
  if(response){
    logAction("Deleted Training Level","Training Level",`Deleted the following Training Level ${JSON.stringify(response.data)}`)
  }
  } catch (error) {
    toast.error(`Error deleting TrainingLevel with ID ${trainingLevelID}:`, error.response.data);
    throw error;
  }
};

const getTrainingLevel = async () => {
  try {
    const response = await apiClient.get('/api/TrainingLevel');
    return response.data;
  } catch (error) {
    toast.error('Error fetching TrainingLevel:', error.response.data);
    throw error;
  }
};

export { addTrainingLevel, getTrainingLevelById, updateTrainingLevel, deleteTrainingLevel, getTrainingLevel };
