import Modal from "react-modal";
import { getRoleById, deleteRole } from "../../../../../services/rolesS";
import { smallFormModal } from "../../../../../styles/smallFormModal";
import { useEffect, useState } from "react";

const Delete = ({ deleteModal, closeDelete, update }) => {
  const [role, setRole] = useState({});

  useEffect(() => {
    if (deleteModal) getSingleRole();
  }, [deleteModal]);

  const getSingleRole = async () => {
    const fetchedRole = await getRoleById(deleteModal);
    setRole(fetchedRole);
  };

  const deleteRoles = async () => {
    await deleteRole(deleteModal);
    closeDelete();
    update();
  };

  return (
    <Modal
      style={smallFormModal}
      isOpen={deleteModal}
      onRequestClose={closeDelete}
      contentLabel={"Delete Role"}>
      <h2 className="subtitle2 mb-4">
        Are you sure you want to delete Role: <span className="text-primary">{role?.name}</span>?
      </h2>
      <div className="flex justify-end mt-4">
        <button onClick={deleteRoles} className="bg-primary px-5 text-white p-2 rounded mr-2">
          Delete
        </button>
        <button onClick={closeDelete} className="outline outline-1 outline-primary text-primary px-5 p-2 rounded">
          Cancel
        </button>
      </div>
    </Modal>
  );
};

export default Delete;
