import React from 'react';
import { Navigate } from 'react-router-dom';

const isTokenExpired = () => {
  const now = Date.now();
  const expiryTime = localStorage.getItem('tokenExpiry');
  return now > expiryTime;
};
const UnprotectedRoute = ({ element: Component, ...rest }) => {
  let token;
  if(isTokenExpired()){
   token = true

  }else{

    token = localStorage.getItem('token')
  }
  

  return token ? <Navigate to="/dashboard" /> : <Component {...rest} /> ;
};

export default UnprotectedRoute;
