import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { IoMenu } from "react-icons/io5";
import Sidebar from "./sidebar";
import Navbar from "./navbar";
import userContext from "../../hooks/Context";
import Footer from "./footer";
import { smallScreenCustomStyles } from "../../styles/smallScreenCustomStyles";
import Modal from "react-modal";

function Layout({ children }) {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [breadcrum, setBreadcrum] = useState(false);
  const [user, setUser] = useState({});
  const [logoutModal, setLogoutModal] = useState(false);
  const navigate = useNavigate();
  const dropdownRef = useRef(null);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const toggleDropdown = () => {
    setBreadcrum((prev) => !prev);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setBreadcrum(false);
    }
  };

  const handleLogout = () => {
    setBreadcrum(false);
    setLogoutModal(true);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      const parsedUsername = JSON.parse(storedUser);
      setUser(parsedUsername);
    }
  }, []);

  return (
    <userContext.Provider
      value={{
        user // Use the actual user object instead of a string
      }}>
      <div className="bg-siteBg">
        <div className="relative max-width-screen h-full">
          {/* Mobile Menu Icon */}
          <div className="w-full md:hidden py-3 pl-4 sticky top-0 z-[100] bg-white mb-1">
            <IoMenu
              type="button"
              className="md:hidden text-main text-[40px] mr-5"
              id="menu-button"
              aria-expanded={isSidebarOpen}
              aria-haspopup="true"
              onClick={toggleSidebar}
            />
          </div>
          <Navbar
            dropdownRef={dropdownRef}
            toggleDropdown={toggleDropdown}
            breadcrum={breadcrum}
            handleLogout={handleLogout}
          />
          <div className="w-full h-[100vh] grid grid-cols-1 md:grid-cols-[18%_auto] md:p-2 md:gap-3 bg-siteBg">
            <Sidebar
              isOpen={isSidebarOpen}
              onClose={toggleSidebar}
              user={user}
            />
            <div className="p-3 rounded grow overflow-auto bg-siteBg">
              {/* Main Content */}
              <div className="grow pb-10">{children}</div>
              <Footer />
            </div>
          </div>

          {/* Logout Modal */}
          <Modal
            style={smallScreenCustomStyles}
            isOpen={logoutModal}
            onRequestClose={() => setLogoutModal(false)}
            contentLabel="Logout Modal">
            <div className="flex justify-center items-center flex-col h-full">
              <h2 className="subtitle2 mb-4">Goodbye!!</h2>
              Are you sure you want to Logout?
              <div className="flex justify-end mt-4">
                <button
                  onClick={() => {
                    localStorage.removeItem("user"); // Ensure token/session is cleared
                    localStorage.removeItem("token"); // Ensure token/session is cleared
                    setLogoutModal(false);
                    navigate("/signin");
                  }}
                  className="bg-primary px-5 text-white p-2 rounded mr-2">
                  Yes
                </button>
                <button
                  onClick={() => setLogoutModal(false)}
                  className="outline outline-1 outline-primary text-primary px-5 p-2 rounded">
                  No
                </button>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </userContext.Provider>
  );
}

export default Layout;
