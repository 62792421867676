import Modal from "react-modal";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { getPermisionById, updatePermission } from "../../../../../services/permissionsS";
import { smallFormModal } from "../../../../../styles/smallFormModal";
import InputContainer from "../../../../../components/surfaces/InputContainer";
import { useEffect, useState } from "react";
const Edit = ({ editModal, closeEdit,update }) => {
  const [permission, SetPermission] = useState({});
  const schema = yup.object({
    name: yup.string().required("Permission name is required"),
    notes: yup.string().required("notes is required"),
  });

  const {
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    getSinglePermission();
  }, [editModal]);

  const getSinglePermission = async () => {
    const fetchedPermission = await getPermisionById(editModal);
    SetPermission(fetchedPermission);
  };

  const onSubmit = async(data) => {
    const updatedPermission = await updatePermission(editModal,data)
    console.log(updatedPermission)
    closeEdit()
    update()
  };

  return (
    <>
      <Modal
        style={smallFormModal}
        isOpen={editModal}
        onRequestClose={closeEdit}
        contentLabel={"Add User"}>
        <h2 className="subtitle2 mb-4">Edit Permission</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputContainer
            disabled={false}
            // error={errors[field.name]?.message}
            name="name"
            label="Permission Name"
            setValue={setValue}
            value={permission.name}
            placeholder="Enter Permission Name"
            type="text"
          />
          <InputContainer
            disabled={false}
            // error={errors[field.name]?.message}
            value={permission.notes}
            name="notes"
            label="Permission Notes"
            setValue={setValue}
            placeholder="Enter Permission Notes"
            type="text"
          />
          <div className="flex justify-end mt-4">
            <button
              type="submit"
              className="bg-primary px-5 text-white p-2 rounded mr-2">
              Save
            </button>
            <button
              onClick={closeEdit}
              className="outline outline-1 outline-primary text-primary px-5 p-2 rounded">
              Cancel
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default Edit;
