import apiClient from './apiClient';
import { logAction } from '../utils/logger';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 

const addPartnerType = async (PartnerTypeData) => {
  try {
    const response = await apiClient.post('/api/PartnerTypes', PartnerTypeData);
    if(response){
      logAction("Created Partner Type","Partner Type",`Created the following Partner Type ${JSON.stringify(response.data)}`)
    }
    return response.data;
  } catch (error) {
    toast.error('Error in addPartnerTypes:', error.response.data);
    throw error;
  }
};

const getPartnerTypeById = async (typeID) => {
  try {
    const response = await apiClient.get(`/api/PartnerTypes/${typeID}`);
    return response.data;
  } catch (error) {
    toast.error(`Error fetching PartnerTypes with ID ${typeID}:`, error.response.data);
    throw error;
  }
};

const updatePartnerType = async (typeID, updatedData) => {
  try {
    const response = await apiClient.put(`/api/PartnerTypes/${typeID}`, updatedData);
    if(response){
      logAction("Updated Partner Type","Partner Type",`Updated the following Partner Type ${JSON.stringify(response.data)}`)
    }
    return response.data;
  } catch (error) {
    toast.error(`Error updating PartnerTypes with ID ${typeID}:`, error.response.data);
    throw error;
  }
};

const deletePartnerType = async (typeID) => {
  try {
    const response = await apiClient.delete(`/api/PartnerTypes/${typeID}`);
    if(response){
      logAction("Deleted Partner Type","Partner Type",`Deleted the following Partner Type ${JSON.stringify(response.data)}`)
    }
  } catch (error) {
    toast.error(`Error deleting PartnerTypes with ID ${typeID}:`, error.response.data);
    throw error;
  }
};

const getPartnerType = async () => {
  try {
    const response = await apiClient.get('/api/PartnerTypes');
    return response.data;
  } catch (error) {
    toast.error('Error fetching PartnerTypes:', error.response.data);
    throw error;
  }
};

export { addPartnerType, getPartnerTypeById, updatePartnerType, deletePartnerType, getPartnerType };
