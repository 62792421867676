import React from 'react';
import Input from '../common/Input';
import styles from '../../styles/modal.module.css'; // Correct import for CSS modules

const TestimonialsForm = ({ formValues, handleInputChange, errors }) => {
  return (
    <form className={styles.form}>
      <div className="space-y-4">
        <div>
          <label htmlFor="UserID">User ID:</label>
          <Input
            name="UserID"
            placeholder="User ID"
            value={formValues.userID}
            onChange={handleInputChange}
          />
          {errors.userID && <p className="text-red-500">{errors.userID[0]}</p>}
        </div>
        <div>
          <label htmlFor="Username">User Name:</label>
          <Input
            name="Username"
            placeholder="Name"
            value={formValues.username}
            onChange={handleInputChange}
          />
          {errors.username && <p className="text-red-500">{errors.username[0]}</p>}
        </div>
        <div>
          <label htmlFor="Role">User Role:</label>
          <Input
            name="Role"
            placeholder="Role"
            value={formValues.role}
            onChange={handleInputChange}
          />
          {errors.role && <p className="text-red-500">{errors.role[0]}</p>}
        </div>
        <div>
          <label htmlFor="Description">Testimonial:</label>
          <Input
            name="Description"
            placeholder="Description"
            value={formValues.description}
            onChange={handleInputChange}
          />
          {errors.description && <p className="text-red-500">{errors.description[0]}</p>}
        </div>
        <div>
          <label htmlFor="Notes">Internal Notes:</label>
          <Input
            name="Notes"
            placeholder="Notes"
            value={formValues.notes}
            onChange={handleInputChange}
          />
          {errors.notes && <p className="text-red-500">{errors.Notes[0]}</p>}
        </div>
      </div>
    </form>
  );
};

export default TestimonialsForm;
