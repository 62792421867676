import React, { useState } from 'react';
import axios from 'axios';

const Synchronization = () => {
  const [loading, setLoading] = useState(false);
  const [syncData, setSyncData] = useState({
    totalRecords: 0,
    lastSync: '',
    syncSuccess: false,
    syncMessage: '',
  });

  // Sync image URL for loading spinner
  const syncImageUrl = `${process.env.PUBLIC_URL}/assets/sync.png`;

  const handleSync = async () => {
    setLoading(true); // Start showing loading state

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/synchronization/sync-award-centers`);
      
      // Assuming response structure contains relevant data
      setSyncData({
        totalRecords: response.data.totalRecords || 100, // Update this based on your actual response
        lastSync: new Date().toLocaleString(),
        syncSuccess: true,
        syncMessage: response.data.message || 'Synchronization completed successfully.',
      });
    } catch (error) {
      setSyncData({
        totalRecords: 0,
        lastSync: new Date().toLocaleString(),
        syncSuccess: false,
        syncMessage: 'Synchronization failed. Please try again.',
      });
    } finally {
      setLoading(false); // End loading state
    }
  };

  return (
    <div className="w-full max-w-4xl mx-auto">
      <h2 className="text-2xl font-Satoshi font-semibold text-white bg-primary p-4 mb-6 rounded-lg">Synchronization</h2>

      <div className="p-6 bg-white rounded-lg shadow-lg relative">
        {/* Button to trigger the synchronization */}
        <div className="flex justify-center items-center mb-8">
          <button
            onClick={handleSync}
            className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded focus:outline-none focus:ring relative"
            disabled={loading} // Disable button when loading
          >
            {/* Conditionally display spinner or button text */}
            {loading ? (
              <div className="flex items-center justify-center">
                {/* Sync image spinning as loading animation */}
                <img
                  src={syncImageUrl}
                  alt="Loading..."
                  className="animate-spin w-6 h-6"
                />
                <span className="ml-2">Synchronizing...</span>
              </div>
            ) : (
              'Synchronize Database'
            )}
          </button>
        </div>

        {/* Show the result after syncing */}
        {syncData.lastSync && (
          <div className="text-center mt-6">
            <p className={`text-lg font-semibold ${syncData.syncSuccess ? 'text-green-500' : 'text-red-500'}`}>
              {syncData.syncSuccess ? 'Success!' : 'Error'}: {syncData.syncMessage}
            </p>
            <p className="text-gray-600">Last Sync Time: {syncData.lastSync}</p>
          </div>
        )}

        {/* Sync statistics */}
        <div className="mt-12 grid grid-cols-3 gap-6 text-center">
          <div className="bg-green-100 p-4 rounded-lg">
            <h3 className="text-xl font-semibold text-gray-800">Total Records</h3>
            <p className="text-3xl font-bold text-green-500">{syncData.totalRecords}</p>
          </div>
          <div className="bg-yellow-100 p-4 rounded-lg">
            <h3 className="text-xl font-semibold text-gray-800">Pending Syncs</h3>
            <p className="text-3xl font-bold text-yellow-500">{Math.floor(Math.random() * 20)}</p>
          </div>
          <div className="bg-blue-100 p-4 rounded-lg">
            <h3 className="text-xl font-semibold text-gray-800">Records Processed</h3>
            <p className="text-3xl font-bold text-blue-500">
              {syncData.totalRecords - Math.floor(Math.random() * 20)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Synchronization;
