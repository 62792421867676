import * as XLSX from 'xlsx';

const companyDetails = {
  name: "President's Award - Kenya",
  address: "15 Elgon Road, opposite the Kadhi's Court in Upper Hill, Nairobi",
  email: "info@presidentsaward.or.ke",
  phone: "0722 714 122, 0787 419 325",
};

export const formatExcelReport = (title, data, headers) => {
  // Create a new workbook and worksheet
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.aoa_to_sheet([]);

  // Initialize !rows if it doesn't exist
  ws['!rows'] = [];

  // Add the company details and title to the sheet
  const companyInfo = [
    [{ v: companyDetails.name, t: 's', s: { font: { name: 'Arial', sz: 12, bold: true }, alignment: { horizontal: 'center', vertical: 'center' } } }],
    [{ v: companyDetails.address, t: 's', s: { font: { name: 'Arial', sz: 12, bold: true }, alignment: { horizontal: 'center', vertical: 'center' } } }],
    [{ v: `Email: ${companyDetails.email}`, t: 's', s: { font: { name: 'Arial', sz: 12, bold: true }, alignment: { horizontal: 'center', vertical: 'center' } } }],
    [{ v: `Phone: ${companyDetails.phone}`, t: 's', s: { font: { name: 'Arial', sz: 12, bold: true }, alignment: { horizontal: 'center', vertical: 'center' } } }],
    [], // Empty row for spacing
    [{ v: title, t: 's', s: { font: { name: 'Arial', sz: 14, bold: true }, alignment: { horizontal: 'center', vertical: 'center' } } }],
    [], // Empty row before table headers
  ];

  // Ensure we have enough rows
  for (let i = 0; i < companyInfo.length; i++) {
    ws['!rows'][i] = { hpx: 20 }; // Set a default height
  }

  XLSX.utils.sheet_add_aoa(ws, companyInfo, { origin: 'A1' });

  // Determine the number of columns to merge (based on headers length)
  const totalColumns = headers.length;

  // Merge cells for company details and title
  ws['!merges'] = [
    { s: { c: 0, r: 0 }, e: { c: totalColumns - 1, r: 0 } }, // Company name
    { s: { c: 0, r: 1 }, e: { c: totalColumns - 1, r: 1 } }, // Address
    { s: { c: 0, r: 2 }, e: { c: totalColumns - 1, r: 2 } }, // Email
    { s: { c: 0, r: 3 }, e: { c: totalColumns - 1, r: 3 } }, // Phone
    { s: { c: 0, r: 5 }, e: { c: totalColumns - 1, r: 5 } }, // Title
  ];

  // Add the headers to the sheet using the provided headers
  const headerRow = headers.map(header => header.Header);
  XLSX.utils.sheet_add_aoa(ws, [headerRow], { origin: 'A8' });

  // Apply header row styles
  ws['!cols'] = headers.map(() => ({ wch: 20 })); // Set width to 20 characters for all columns
  ws['!rows'][7] = {
    hpx: 25,
    s: {
      fill: { fgColor: { rgb: '4472C4' } },
      font: {
        name: 'Arial',
        sz: 12,
        bold: true,
        color: { rgb: 'FFFFFF' }
      },
      alignment: { horizontal: 'center', vertical: 'center' }
    }
  };

  // Add the table data
  const tableData = data.map(row => headers.map(header => row[header.accessor]));
  XLSX.utils.sheet_add_aoa(ws, tableData, { origin: 'A9' });

  // Apply styles to the table data
  for (let R = 8; R < companyInfo.length + tableData.length + 8; ++R) {
    for (let C = 0; C < totalColumns; ++C) {
      const cell_address = XLSX.utils.encode_cell({ r: R, c: C });
      const cell = ws[cell_address];
      
      if (cell) {
        cell.s = {
          font: { name: 'Arial', sz: 11 },
          alignment: { horizontal: 'center', vertical: 'center' },
          border: {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' }
          }
        };
      }
    }
  }

  // Append the worksheet to the workbook
  XLSX.utils.book_append_sheet(wb, ws, 'Report');

  // Generate the file
  XLSX.writeFile(wb, `${title}.xlsx`);
};