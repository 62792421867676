import Modal from "react-modal";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { getRoleById, updateRole } from "../../../../../services/rolesS";
import { smallFormModal } from "../../../../../styles/smallFormModal";
import InputContainer from "../../../../../components/surfaces/InputContainer";
import { useEffect, useState } from "react";

const Edit = ({ editModal, closeEdit, update }) => {
  const [role, setRole] = useState({});
  
  const schema = yup.object({
    name: yup.string().required("Role name is required"),
  });

  const {
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (editModal) getSingleRole();
  }, [editModal]);

  const getSingleRole = async () => {
    const fetchedRole = await getRoleById(editModal);
    setRole(fetchedRole);
  };

  const onSubmit = async (data) => {
    await updateRole(editModal, data);
    closeEdit();
    update();
  };

  return (
    <Modal
      style={smallFormModal}
      isOpen={editModal}
      onRequestClose={closeEdit}
      contentLabel={"Edit Role"}>
      <h2 className="subtitle2 mb-4">Edit Role</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputContainer
          disabled={false}
          error={errors.name?.message}
          name="name"
          label="Role Name"
          setValue={setValue}
          value={role.name}
          placeholder="Enter Role Name"
          type="text"
        />
        <div className="flex justify-end mt-4">
          <button type="submit" className="bg-primary px-5 text-white p-2 rounded mr-2">
            Save
          </button>
          <button onClick={closeEdit} className="outline outline-1 outline-primary text-primary px-5 p-2 rounded">
            Cancel
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default Edit;
