import apiClient from './apiClient';
import { logAction } from '../utils/logger';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 

const addCType = async (CTypeData) => {
  try {
    const response = await apiClient.post('/api/AwardCType', CTypeData);
    if(response){
      logAction("Created Center Type","Center Type",`Created the following Center Type ${JSON.stringify(response.data)}`)
    }
    return response.data;
  } catch (error) {
    toast.error('Error in adding Award CType Type:', error.response.data);
    throw error;
  }
};

const getCTypeById = async (awardCTypeID) => {
  try {
    const response = await apiClient.get(`/api/AwardCType/${awardCTypeID}`);
    return response.data;
  } catch (error) {
    toast.error(`Error fetching Award CType Type with awardCTypeID ${awardCTypeID}:`, error.response.data);
    throw error;
  }
};

const updateCType = async (awardCTypeID, updatedData) => {
  try {
    const response = await apiClient.put(`/api/AwardCType/${awardCTypeID}`, updatedData);
    if(response){
      logAction("Updated Center Type","Center Type",`Updated the following Center Type ${JSON.stringify(response.data)}`)
    }
    return response.data;
  } catch (error) {
    toast.error(`Error updating Award CType Type with awardCTypeID ${awardCTypeID}:`, error.response.data);
    throw error;
  }
};

const deleteCType = async (awardCTypeID) => {
  try {
    const response = await apiClient.delete(`/api/AwardCType/${awardCTypeID}`);
    if(response){
      logAction("Created Center Type","Center Type",`Created the following Center Type ${JSON.stringify(response.data)}`)
    }
  } catch (error) {
    toast.error(`Error deleting Award CType Type with awardCTypeID ${awardCTypeID}:`, error.response.data);
    throw error;
  }
};

const getCType = async () => {
  try {
    const response = await apiClient.get('/api/AwardCType');
    return response.data;
  } catch (error) {
    toast.error('Error fetching Award CType Type:', error.response.data);
    throw error;
  }
};

export { addCType, getCTypeById, updateCType, deleteCType, getCType };
