import React from 'react';
import Table from '../common/Table';
import Dropdown from "../Dropdown/dropDown"
const columns = [
  { Header: 'Stage No', accessor: 'stageID' },
  { Header: 'Stage Name', accessor: 'stageName' },
  { Header: 'Notes', accessor: 'notes' },
];

const StatusTable = ({ stages, openEditModal, deleteStages }) => {
  const renderRowActions = ({ stageID }) => (
    <div className='z-50'>
      <Dropdown onEdit={()=>openEditModal(stageID)} onDelete={()=>deleteStages(stageID)} />
    </div>
  );

  return (
    <Table
      columns={columns}
      data={stages}
      renderRowActions={renderRowActions}
    />
  );
};

export default StatusTable;
