import React, {useState,useEffect} from "react" 
const InputContainer = ({label,placeholder,type,name,error,setValue,disabled=false,value})=> {
    const [selectedValue,setSelectedValue] = useState(value)

    useEffect(() => {
      setSelectedValue(value); 
    }, [value]);

    const changeSelectedItem = (e)=>{
      setSelectedValue(e.target.value)
      setValue(name,e.target.value)
    }
    return(
        <div className='flex flex-col gap-2'>
                 <label className='text-sm font-medium font-sans text-main'>{label}<span className='text-primary'>*</span></label>
                   <input disabled={disabled} value={selectedValue != undefined && (type == "date" || name == "licenseEndDate")? new Date(selectedValue || "").toISOString().split("T")[0] : selectedValue}  onChange={changeSelectedItem} placeholder={placeholder} type={type}  className="w-full p-2 border border-gray-300 rounded"/>
                {error ? <p className='text-red-600 body2'>{error}</p> : "" }
                </div>
    );
}

export default InputContainer;
