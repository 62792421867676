
 export  function filterMenuItemsByPermissions(permissions, menuItems) {
    // Helper function to check if a menu item or dropdown item has permission
    const hasPermission = (permissionName) => {
      return permissions?.some(permission => permission.name === permissionName);
    };
  
    // Function to filter the dropdown items based on permissions
    const filterDropdown = (dropdown) => {
      return dropdown.filter(subItem => hasPermission(subItem.permission));
    };
  
    // Map over the menuItems and filter based on permissions
    return menuItems
      .map(item => {
        // Filter dropdown items if they exist
        if (item.dropdown) {
          const filteredDropdown = filterDropdown(item.dropdown);
          
          // If no dropdown items match the permission, don't include this item
          if (filteredDropdown.length === 0) {
            return null;
          }
          
          // Return the item with only the allowed dropdowns
          return { ...item, dropdown: filteredDropdown };
        }
  
        // Return the item if it has permission
        return hasPermission(item.permission) ? item : null;
      })
      // Remove null values from the array (items without permission)
      .filter(item => item !== null);
  }
  