import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { FaFilter } from "react-icons/fa";
import { customStyles } from "../../styles/customStyles";
import { FaPlus } from "react-icons/fa6";
import { splitWordFunc } from "../../utils/splitWordFunc";
import { FiSearch } from "react-icons/fi";
import TrainingForm from "../../components/forms/trainingF";
import TrainingTable from "../../components/tables/trainingT";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import {
  addTraining as addTrainingService,
  getTrainingById,
  updateTraining,
  deleteTraining,
  getTraining,
} from "../../services/trainingS";
import Layout from "../../components/Layout/layout";

Modal.setAppElement("#root");

const AddTraining = () => {
  const [trainings, setTrainings] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newTraining, setNewTraining] = useState({
    trainingID: "",
    trainingName: "",
    institutionName: "",
    venue: "",
    date: "",
    categories: "",
    subCounty: "",
    county: "",
    Region: "",
    coordinator: "",
    trainingLevel: "",
    trainingType: "",
    notes: "",
  });
  const [selectedColumn, setSelectedColumn] = useState("name");
  const [filteredTrainings, setFilteredTrainings] = useState(trainings);
  const [searchTerm, setSearchTerm] = useState("");
  const [update, setUpdate] = useState(false);
  const [errors, setErrors] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [selectedTrainingId, setSelectedTrainingId] = useState(null);

  useEffect(() => {
    const fetchTrainings = async () => {
      try {
        const fetchedTrainings = await getTraining();
        setTrainings(fetchedTrainings);
        setFilteredTrainings(fetchedTrainings); // Initialize filtered participants
      } catch (error) {
        toast.error("Error fetching trainings:", error.response.data);
      }
    };

    fetchTrainings();
  }, [update]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewTraining((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setNewTraining((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const addNewTraining = async () => {
    try {
      const trainingPayload = { ...newTraining };
      const addedTraining = await addTrainingService(trainingPayload);
      setUpdate((prev) => !prev);
      setIsModalOpen(false);
      setErrors({});
    } catch (error) {
      toast.error("Error adding training:", error.response.data);
      setErrors(error.response.data.errors || {});
      alert(
        `Failed to add training: ${
          error.response.data.title
        }\nDetails: ${JSON.stringify(error.response.data.errors, null, 2)}`
      );
    }
  };

  const openAddTrainingModal = () => {
    setEditMode(false);
    setIsModalOpen(true);
    setNewTraining({
      trainingID: "",
      trainingName: "",
      institutionName: "",
      venue: "",
      date: "",
      categories: "",
      subCounty: "",
      county: "",
      Region: "",
      coordinator: "",
      trainingLevel: "",
      trainingType: "",
      notes: "",
    });
  };

  const openEditTrainingModal = async (trainingID) => {
    try {
      const fetchedTraining = await getTrainingById(trainingID);
      setEditMode(true);
      setIsModalOpen(true);
      setSelectedTrainingId(trainingID);
      setNewTraining({
        ...fetchedTraining,
        date: fetchedTraining.date,
      });
    } catch (error) {
      toast.error(
        `Error fetching training with ID ${trainingID}:`,
        error.response.data
      );
    }
  };

  const updateExistingTraining = async () => {
    try {
      const trainingPayload = { ...newTraining };

      const updatedTraining = await updateTraining(
        selectedTrainingId,
        trainingPayload
      );
      setUpdate((prev) => !prev);
      setIsModalOpen(false);
      setErrors({});
    } catch (error) {
      toast.error(
        `Error updating training with ID ${selectedTrainingId}:`,
        error.response.data
      );
      setErrors(error.response.data.errors || {});
      alert(
        `Failed to update training: ${
          error.response.data.title
        }\nDetails: ${JSON.stringify(error.response.data.errors, null, 2)}`
      );
    }
  };

  const deleteExistingTraining = async (trainingID) => {
    try {
      await deleteTraining(trainingID);
      setUpdate((prev) => !prev);
    } catch (error) {
      toast.error(
        `Error deleting training with ID ${trainingID}:`,
        error.response.data
      );
      alert(`Failed to delete training: ${error.response.data.title}`);
    }
  };

  const closeAddTrainingModal = () => {
    setIsModalOpen(false);
    setEditMode(false);
    setErrors({});
  };

  const deleteTrainingHandler = (trainingID) => {
    if (
      window.confirm(
        `Are you sure you want to delete training with ID ${trainingID}?`
      )
    ) {
      deleteExistingTraining(trainingID);
    }
  };

  //Search
  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    filterTrainings(value, selectedColumn);
  };
  const handleFilterParameterChange = (event) => {
    const value = event.target.value;
    setSelectedColumn(value);
    filterTrainings(searchTerm, value);
  };

  const filterTrainings = (searchTerm, column) => {
    const filtered = trainings.filter((training) =>
      training[column]
        .toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
    setFilteredTrainings(filtered);
  };

  return (
    <Layout>
    <div className="bg-white rounded-lg shadow-lg">
      <div className="px-6 py-4 border-b border-gray-200">
        <h1 className="text-2xl font-semibold text-gray-800">Trainings</h1>
      </div>
  
      <div className="p-6">
        <div className="space-y-4 md:space-y-0 md:flex md:items-center md:justify-between mb-6">
          <button
            onClick={openAddTrainingModal}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-all duration-200"
          >
            <FaPlus className="mr-2 h-4 w-4" />
            <span>Add Training</span>
          </button>
  
          <form className="flex flex-col md:flex-row gap-4 flex-1 md:max-w-2xl">
            <div className="relative flex-1">
              <select
                className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-md bg-white shadow-sm"
                value={selectedColumn}
                onChange={handleFilterParameterChange}
              >
                {trainings.length > 0 ? (
                  Object.keys(trainings[0])?.map((op) => (
                    <option key={op} value={op}>
                      {splitWordFunc(op)}
                    </option>
                  ))
                ) : (
                  <option>No filter property</option>
                )}
              </select>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <FaFilter className="h-4 w-4 text-gray-400" />
              </div>
            </div>
  
            <div className="relative flex-1">
              <input
                type="text"
                placeholder="Search..."
                onChange={handleSearchChange}
                className="block w-full pl-3 pr-10 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
              <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                <FiSearch className="h-5 w-5 text-gray-400" />
              </div>
            </div>
          </form>
        </div>
  
        <TrainingTable
          trainings={filteredTrainings}
          openEditModal={openEditTrainingModal}
          deleteTraining={deleteTrainingHandler}
        />
      </div>
  
      <Modal
        style={customStyles}
        isOpen={isModalOpen}
        onRequestClose={closeAddTrainingModal}
        contentLabel={editMode ? "Edit Training" : "Add Training"}
      >
        <h2 className="text-xl font-semibold text-gray-800 mb-4">
          {editMode ? "Edit Training" : "Add Training"}
        </h2>
        <TrainingForm
          formValues={newTraining}
          handleInputChange={handleInputChange}
          handleDateChange={handleDateChange}
          errors={errors}
        />
        <div className="flex justify-end mt-4">
          <button
            onClick={editMode ? updateExistingTraining : addNewTraining}
            className="bg-indigo-600 text-white px-5 py-2 rounded mr-2 transition-all duration-200 hover:bg-indigo-700"
          >
            {editMode ? "Update" : "Save"}
          </button>
          <button
            onClick={closeAddTrainingModal}
            className="border border-gray-300 text-gray-700 px-5 py-2 rounded hover:bg-gray-100 transition-all duration-200"
          >
            Cancel
          </button>
        </div>
      </Modal>
    </div>
  </Layout>
  
  );
};

export default AddTraining;
