import Modal from "react-modal";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { updatePassword } from "../../services/usersS";
import { smallFormModal } from "../../styles/smallFormModal";
import InputContainer from "../../components/surfaces/InputContainer";
import { useEffect, useState } from "react";

const ResetPasswordInDashboard = ({ editModal, closeEdit }) => {  
  const [error,setError] = useState("")
  const schema = yup.object({
    oldPassword: yup.string().required("Old Password is required"),
    newPassword: yup.string().required("Old Password is required"),
    confirmNewPassword: yup.string().required("Old Confirm Password is required").oneOf([yup.ref("newPassword"), null], "New Passwords and Confirm New Password must match"),
  });

  const {
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  
  const onSubmit = async(data)=>{
    let user = localStorage.getItem("user")
   const response = await updatePassword(JSON.parse(user).user.username,data)
      if(response.error){
        setError(response.error)
        setTimeout(()=>{
          setError("")
        },3000)
        return
      }
      return closeEdit()
  }
  
  return (
    <Modal
      style={smallFormModal}
      isOpen={editModal}
      onRequestClose={closeEdit}
      contentLabel={"Edit Role"}>
      <h2 className="subtitle2 mb-4">Change password</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        {error && (<h3 className="text-red-500">{error} !!</h3>)}
        <br/>
        <InputContainer
          disabled={false}
          error={errors.oldPassword?.message}
          name="oldPassword"
          label="Old Password"
          setValue={setValue}
          placeholder="Enter Old Password"
          type="password"
        />
          <InputContainer
          disabled={false}
          error={errors.newPassword?.message}
          name="newPassword"
          label="New Password"
          setValue={setValue}
          placeholder="Enter New Password"
          type="password"
        />
        <InputContainer
          disabled={false}
          error={errors.confirmNewPassword?.message}
          name="confirmNewPassword"
          label="Confirm New Password"
          setValue={setValue}
          placeholder="Enter Confirm New Password"
          type="password"
        />
        <div className="flex justify-end mt-4">
          <button type="submit" className="bg-primary px-5 text-white p-2 rounded mr-2">
            Save
          </button>
          <button onClick={closeEdit} className="outline outline-1 outline-primary text-primary px-5 p-2 rounded">
            Cancel
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default ResetPasswordInDashboard;
