    export const customStylesModal = {
        content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#f5f5f5', // Light background color
        borderRadius: '10px',
        padding: '30px', // Increased padding for more spacing
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
        fontFamily: 'Arial, sans-serif', // Choose a suitable font
        },
        overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
        },
    };