import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import apiClient from "../../services/apiClient";
import AuthLayout from "../../components/surfaces/authLayout";
import Button from "../../components/common/Button/Button";
import InputContainer from "../../components/surfaces/InputContainer";

const inputConfig = [
  {
    label: "New password",
    placeholder: "Enter New Password",
    type: "password",
    name: "password",
  },
];

const schema = yup
  .object({
    password: yup.string().required(),
  })
  .required();

const ChangePassword = () => {
  const [error, setError] = useState("");
  const [searchParams] = useSearchParams(); 
  const [successMessage,setSuccessMessage] = useState("")

  const token = searchParams.get("token"); 
  const navigate = useNavigate();
  const {
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });


  const onSubmit = async (data) => {
    try {
      const tokenFromUrl = token.trim();  // Trim to remove any newline or spaces

      const response = await apiClient.put("api/User/ResetPassword", {
        token: tokenFromUrl,
        newPassword: data.password
      });
      const ourData = response.data;
      setSuccessMessage(ourData)
      setTimeout(()=>{
        setSuccessMessage("")
        navigate("/")
      },3000)
      navigate("/");
    } catch (error) {
      setError("");
      setTimeout(() => {
        setError(error);
      }, 2000);
    }
  };
  return (
    <AuthLayout>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-10">
      {error && (
              <div role="alert">
                <div class="bg-red-500 text-white font-bold rounded-t px-4 py-2">
                  
                </div>
                <div class="border border-t-0 border-red-400 rounded-b bg-red-100 px-4 py-3 text-red-700">
                  <p>{error}</p>
                </div>
              </div>
            )}
            {successMessage && (
              <div role="alert">
                <div class="bg-primary text-white font-bold rounded-t px-4 py-2">
                  
                </div>
                <div class="border border-t-0 border-primary rounded-b bg-greys px-4 py-3 text-main">
                  <p>{successMessage}</p>
                  <p className='text-center'>Please check your email.</p>
                </div>
              </div>
            )}
        <div>
          <div className="flex flex-col gap-2">
            <h2 color="secondary" className="text-main subtitle1">
              Change Password
            </h2>
            <p className="text-greys body1">Please enter your new password!</p>
          </div>
        </div>
        <div className="flex flex-col gap-5">
          {inputConfig.map((field, index) => (
            <InputContainer
              error={errors[field.name]?.message}
              name={field.name}
              setValue={setValue}
              key={index}
              label={field.label}
              placeholder={field.placeholder}
              type={field.type}
            />
          ))}
          <div className="flex flex-row justify-between"></div>
          <div>
            <Button className="bg-primary text-white w-full">
              change Password
            </Button>
          </div>
          <div>
            <p className="flex flex-row gap-2">
              <span className="body2 text-main">Not registered yet?</span>
              <a
                className="bodyLarge text-primary cursor-pointer"
                href="/signup">
                Create an Account
              </a>
            </p>
          </div>
        </div>
      </form>
    </AuthLayout>
  );
};

export default ChangePassword;
