import { useEffect, useState } from "react";
import UserTable from "../../../../components/tables/usersT";

const Users = ({ Users, deleteUser, openEditModal }) => {
  const [serializedUsers,setSerializedUsers] = useState([])
 useEffect(()=>{
  
console.log(Users)

 })
  return (
    <div className="w-full">
      {/* <button class="border border-blue-500 text-blue-500 hover:bg-blue-500 hover:text-white font-bold py-2 px-4 rounded m-2">
        Add user
      </button> */}
      <UserTable
        Users={Users}
        openEditModal={openEditModal}
        deleteUser={deleteUser}
      />
    </div>
  );
};

export default Users;
