import apiClient from './apiClient';
import { logAction } from '../utils/logger';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 

const addDonor = async (DonorData) => {
  try {
    const response = await apiClient.post('/api/Donors', DonorData);
    if(response){
      logAction("Created Donor","Donor",`Created the following Donor ${JSON.stringify(response.data)}`)
    }
    return response.data;
  } catch (error) {
    toast.error('Error in addDonors:', error.response.data);
    throw error;
  }
};

const getDonorById = async (DonorID) => {
  try {
    const response = await apiClient.get(`/api/Donors/${DonorID}`);
    return response.data;
  } catch (error) {
    toast.error(`Error fetching Donors with ID ${DonorID}:`, error.response.data);
    throw error;
  }
};

const updateDonor = async (DonorID, updatedData) => {
  try {
    const response = await apiClient.put(`/api/Donors/${DonorID}`, updatedData);
    if(response){
      logAction("Updated Donor","Donor",`Updated the following Donor ${JSON.stringify(response.data)}`)
    }
    return response.data;
  } catch (error) {
    toast.error(`Error updating Donors with ID ${DonorID}:`, error.response.data);
    throw error;
  }
};

const deleteDonor = async (DonorID) => {
  try {
    const response = await apiClient.delete(`/api/Donors/${DonorID}`);
    if(response){
      logAction("Deleted Donor","Donor",`Deleted the following Donor ${JSON.stringify(response.data)}`)
    }
  } catch (error) {
    toast.error(`Error deleting Donors with ID ${DonorID}:`, error.response.data);
    throw error;
  }
};

const getDonor = async () => {
  try {
    const response = await apiClient.get('/api/Donors');
    return response.data;
  } catch (error) {
    toast.error('Error fetching Donors:', error.response.data);
    throw error;
  }
};

export { addDonor, getDonorById, updateDonor, deleteDonor, getDonor };
