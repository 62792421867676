import React from 'react';
const AuthLayout = ({children,style }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 grow min-h-screen">
     <div className={`w-full h-full flex gap-5 ${style} flex-col p-5`}>
        <div className='mt-5 mx-auto min-h-[100px] w-[100%] md:w-[70%] mb-7'>
           <img className="w-[100%]" src="/assets/newlogo.png" />
        </div>
        <div className='grow flex justify-center h-[100%]'>
        {children}
        </div>
     </div>
     <div className='hidden md:flex justify-center items-center h-full w-full bg-auth-bg-image bg-cover bg-center rounded-bl-[120px]'>
      <div className='flex flex-col justify-center items-center gap-1'>
        <p className='text-3xl text-white'>Project Management </p>
        <p className='text-8xl text-white font-bold'>Dashboard</p>
      </div>
     </div>
    </div>
  );
};

export default AuthLayout;