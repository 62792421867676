import React from 'react';
import Table from '../common/Table';
import Dropdown from "../Dropdown/dropDown"
const columns = [
  { Header: 'Level ID', accessor: 'levelID' },
  { Header: 'Level Name', accessor: 'levelName' },
  { Header: 'Duration', accessor: 'duration' },
  { Header: 'Notes', accessor: 'notes' },
];

const LevelsTable = ({ levels, openEditModal, deleteLevels }) => {

  const renderRowActions = ({ levelID }) => (
    <div className='z-50'>
    <Dropdown  onEdit={()=>openEditModal({levelID})} onDelete={()=>deleteLevels({levelID})} />
  </div>
  );

  return (
    <Table
      columns={columns}
      data={levels}
      renderRowActions={renderRowActions}
    />
  );
};

export default LevelsTable;
