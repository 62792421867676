import React from 'react';
import Table from '../common/Table';
import Dropdown from "../Dropdown/dropDown"

const columns = [
  { Header: 'Type ID', accessor: 'typeID' },
  { Header: 'Type Name', accessor: 'typeName' },
  { Header: 'Notes', accessor: 'notes' },
];

const TrainingTypeTable = ({ trainingtypes, openEditModal, deleteTrainingType }) => {
  const renderRowActions = ({ typeID }) => (
    <div className='z-50'>
    <Dropdown  onEdit={()=>openEditModal(typeID)} onDelete={()=>deleteTrainingType(typeID)} />
  </div>
  );

  return (
    <Table
      columns={columns}
      data={trainingtypes}
      renderRowActions={renderRowActions}
    />
  );
};

export default TrainingTypeTable;
