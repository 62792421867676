import React, { useState, useEffect, useMemo } from 'react';
import Table from '../common/Table';
import Dropdown from "../Dropdown/dropDown"
import { getUsersByUsername } from '../../services/usersS';


// Helper function to format date
const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toISOString().split('T')[0]; // Extracts 'YYYY-MM-DD' from the ISO string
};

const columns = [
  { Header: 'Award ID', accessor: 'awardID' },
  { Header: 'adminNumber', accessor: 'adminNumber' },
  { Header: 'Student Name', accessor: 'studentName' },
  { Header: 'Center', accessor: 'institutionName' },
  { Header: 'Level Name', accessor: 'levelName' },
  { Header: 'Start Date', accessor: 'startDate', Cell: ({ value }) => formatDate(value) },
  { Header: 'Expected End Date', accessor: 'expectedEndDate', Cell: ({ value }) => formatDate(value) },
  { Header: 'Actual End Date', accessor: 'actualEndDate', Cell: ({ value }) => formatDate(value) },
  { Header: 'Status', accessor: 'status' },
  { Header: 'Certificate No', accessor: 'certNo' },
  { Header: 'Notes', accessor: 'notes' }
];

const AwardsTable = ({ awards, openEditModal, deleteAwardsHandler }) => {
  const [userInstitution, setUserInstitution] = useState("");
  const loggedInUser = JSON.parse(localStorage.getItem("user"))?.user;

  const fetchUserInstitution = async () => {
    if (loggedInUser?.role.name === "Award Leader") {
      try {
        const userData = await getUsersByUsername(loggedInUser.username);
        if (userData?.awardCenter?.institutionName) {
          setUserInstitution(userData.awardCenter.institutionName); // Set the institutionName
        } else {
          console.warn("No institution name found in user data");
        }
      } catch (error) {
        console.error("Error fetching user institution:", error);
      }
    }
  };

  // Fetch user's institution details when component mounts
  useEffect(() => {
    fetchUserInstitution();
  }, []);

   // Filter awards based on user role and institution
   const filteredAwards = useMemo(() => {
    if (!loggedInUser || !awards) return [];
    if (loggedInUser.role.name === "Award Leader" && userInstitution) {
      // Filter awards by institution for Award Leader
      return awards.filter(award => award.institutionName === userInstitution);
    }
    // For other roles, show all awards
    return awards;
  }, [loggedInUser, awards, userInstitution]);

  const renderRowActions = ({ awardID }) => (
    <div className='z-50'>
    <Dropdown   onEdit={()=>openEditModal({awardID})}
                onDelete={()=>deleteAwardsHandler(awardID)} />
        </div>
  );

  return (
    <Table
      columns={columns}
      data={filteredAwards}
      renderRowActions={renderRowActions}
    />
  );
};

export default AwardsTable;
