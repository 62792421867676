import apiClient from '../apiClient';
import { formatDate } from './formatDate';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 

const PROGRAMS_API_URL = '/api/Programs';

export const fetchProgramsData = async () => {
  try {
    const response = await apiClient.get(PROGRAMS_API_URL);
    const formattedData = response.data.map(item => ({
      ...item,
      startDate: formatDate(item.startDate),
    }));
    return formattedData;
  } catch (error) {
    toast.error('Error fetching programs data:', error);
    throw error;
  }
};

export const programsHeaders = [
  { Header: 'Program ID', accessor: 'programID' },
  { Header: 'Program Name', accessor: 'programName' },
  { Header: 'Institution', accessor: 'institutionName' },
  { Header: 'Start Date', accessor: 'startDate' },
  { Header: 'Sub-County', accessor: 'subCounty' },
  { Header: 'County', accessor: 'county' },
  { Header: 'Description', accessor: 'description' },
  { Header: 'Coordinator', accessor: 'coordinator' },
];
