import React from 'react';
import Table from '../common/Table';
import Dropdown from "../Dropdown/dropDown"

const columns = [
  { Header: 'Level ID', accessor: 'trainingLevelID' },
  { Header: 'Level Name', accessor: 'levelName' },
  { Header: 'Notes', accessor: 'notes' },
];

const TrainingLevelTable = ({ traininglevels, openEditModal, deleteTrainingLevel }) => {
  const renderRowActions = ({ trainingLevelID }) => (
    <div className='z-50'>
    <Dropdown  onEdit={()=>openEditModal(trainingLevelID)} onDelete={()=>deleteTrainingLevel(trainingLevelID)} />
  </div>
  );

  return (
    <Table
      columns={columns}
      data={traininglevels}
      renderRowActions={renderRowActions}
    />
  );
};

export default TrainingLevelTable;
