import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { FaPlus } from "react-icons/fa6";
import { FaFilter } from "react-icons/fa";
import { splitWordFunc } from "../../utils/splitWordFunc";
import { FiSearch } from "react-icons/fi";
import { customStyles } from "../../styles/customStyles";
import Layout from "../../components/Layout/layout";
import ProjectForm from "../../components/forms/projectForm";
import ProjectTable from "../../components/tables/projectTable";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import {
  addProject as addProjectService, 
  getProjectById,
  updateProject,
  deleteProject,
  getProjects,
} from "../../services/projectService";

Modal.setAppElement("#root");

// Function to generate Project ID
const generateProjectID = (index) => {
  const currentYear = new Date().getFullYear();
  const formattedIndex = String(index).padStart(3, '0'); // Example: 001, 002, etc.
  return `PRJ-${formattedIndex}-${currentYear}`;
};

const AddProject = () => {
  const [projects, setProjects] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newProject, setNewProject] = useState({
    projectId: "",
    projectName: "",
    institutionName: "",
    institutionName2: "",
    institutionName3: "",
    region: "",
    startDate: "",
    endDate: "",
    cost: "",
    status: "",
    subCounty: "",
    county: "",
    description: "",
    coordinator: "",
    notes: "",
  });
  const [selectedColumn, setSelectedColumn] = useState("name");
  const [filteredProjects, setFilteredProjects] = useState(projects);
  const [searchTerm, setSearchTerm] = useState("");
  const [errors, setErrors] = useState({});
  const [update, setUpdate] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [nextProjectIndex, setNextProjectIndex] = useState(0);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const fetchedProjects = await getProjects();
        setProjects(fetchedProjects);
        setFilteredProjects(fetchedProjects); // Initialize filtered participants
       
        // Get the current full year (e.g., 2024)
    const currentYear = new Date().getFullYear();

    // Find the highest existing index for the current year
    const latestIndex = Math.max(
      ...fetchedProjects
        .filter((p) => p.projectID.includes(`-${currentYear}`))
        .map((p) => parseInt(p.projectID.split('-')[1], 10)), // [1] for the index part
      0 // Default to 0 if no projects are found
    );
        setNextProjectIndex(latestIndex + 1); // Increment for the next project
      } catch (error) {
        toast.error("Error fetching projects:", error.response.data);
      }
    };

    fetchProjects();
  }, [update]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewProject((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setNewProject((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  
  const addNewProject = async () => {
    try {
      const projectPayload = { ...newProject };
      const addedProject = await addProjectService(projectPayload);
      setUpdate((prev) => !prev);
      setIsModalOpen(false);
      setErrors({});
    } catch (error) {
      toast.error("Error adding project:", error.response.data);
      setErrors(error.response.data.errors || {});
      alert(
        `Failed to add project: ${error.response.data.title
        }\nDetails: ${JSON.stringify(error.response.data.errors, null, 2)}`
      );
    }
  };

  const openAddProjectModal = () => {
    setEditMode(false);
    setIsModalOpen(true);

    setNewProject({
      projectID: generateProjectID(nextProjectIndex),
      projectName: "",
      institutionName: "",
      institutionName2: "",
      institutionName3: "",
      region: "",
      startDate: "",
      endDate: "",
      cost: "",
      status: "",
      subCounty: "",
      county: "",
      description: "",
      coordinator: "",
      notes: "",
    });
  };

  const openEditProjectModal = async (project) => {
    try {
      const fetchedProject = await getProjectById(project.projectID);
      setEditMode(true); // Sets the form to edit mode
      setIsModalOpen(true); // Opens the modal
      setSelectedProjectId(project.projectID); // Sets the ID of the selected project

      setNewProject({
        ...fetchedProject,
        startDate: fetchedProject.startDate,
        endDate: fetchedProject.endDate,
      }); // Initializes form fields with fetched project data
    } catch (error) {
      toast.error(
        `Error fetching project with ID ${project.projectID}:`,
        error.message
      );
      if (error.response) {
        toast.error(error.response.data);
      } else {
        alert(
          "Failed to fetch project. Please check your network connection or CORS configuration."
        );
      }
    }
  };

  const updateExistingProject = async () => {
    try {
      const projectPayload = { ...newProject };
      const updatedProject = await updateProject(
        selectedProjectId,
        projectPayload
      );

      const updatedProjecta = await getProjects();
      setNewProject(updatedProjecta);

      setIsModalOpen(false);
      setErrors({});
    } catch (error) {
      toast.error(
        `Error updating project with ID ${selectedProjectId}:`,
        error.response.data
      );
      setErrors(error.response.data.errors || {});
      alert(
        `Failed to update project: ${error.response.data.title
        }\nDetails: ${JSON.stringify(error.response.data.errors, null, 2)}`
      );
    }
  };

  const deleteExistingProject = async (projectID) => {
    try {
      await deleteProject(projectID);
      setUpdate((prev) => !prev);
    } catch (error) {
      toast.error(
        `Error deleting project with ID ${projectID}:`,
        error.response.data
      );
      alert(`Failed to delete project: ${error.response.data.title}`);
    }
  };

  const closeAddProjectModal = () => {
    setIsModalOpen(false);
    setEditMode(false);
    setErrors({});
  };

  const deleteProjectHandler = (projectID) => {
    if (
      window.confirm(
        `Are you sure you want to delete project with ID ${projectID}?`
      )
    ) {
      deleteExistingProject(projectID);
    }
  };
  //Search
  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    filterProjects(value, selectedColumn);
  };
  const handleFilterParameterChange = (event) => {
    const value = event.target.value;
    setSelectedColumn(value);
    filterProjects(searchTerm, value);
  };

  const filterProjects = (searchTerm, column) => {
    const filtered = projects.filter((project) =>
      project[column]
        .toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
    setFilteredProjects(filtered);
  };

  return (
    <Layout>
    <div className="bg-white rounded-lg shadow-lg">
      <div className="px-6 py-4 border-b border-gray-200">
        <h1 className="text-2xl font-semibold text-gray-800">Projects</h1>
      </div>
  
      <div className="p-6">
        <div className="space-y-4 md:space-y-0 md:flex md:items-center md:justify-between mb-6">
          <button
            onClick={openAddProjectModal}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-all duration-200"
          >
            <FaPlus className="mr-2 h-4 w-4" />
            Add Project
          </button>
  
          <div className="flex flex-col md:flex-row gap-4 flex-1 md:max-w-2xl">
            <div className="relative flex-1">
              <select
                className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-md bg-white shadow-sm"
                value={selectedColumn}
                onChange={handleFilterParameterChange}
              >
                {projects.length > 0 ? (
                  Object.keys(projects[0])?.map((op) => (
                    <option key={op} value={op}>
                      {splitWordFunc(op)}
                    </option>
                  ))
                ) : (
                  <option>No filter property</option>
                )}
              </select>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <FaFilter className="h-4 w-4 text-gray-400" />
              </div>
            </div>
  
            <div className="relative flex-1">
              <input
                type="text"
                placeholder="Search projects..."
                onChange={handleSearchChange}
                className="block w-full pl-3 pr-10 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
              <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                <FiSearch className="h-5 w-5 text-gray-400" />
              </div>
            </div>
          </div>
        </div>
  
        <ProjectTable
          projects={filteredProjects}
          openEditModal={openEditProjectModal}
          deleteProject={deleteProjectHandler}
        />
      </div>
  
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeAddProjectModal}
        contentLabel={editMode ? "Edit Project" : "Add Project"}
        style={customStyles}
      >
        <h2 className="text-xl font-semibold text-gray-800 mb-4">
          {editMode ? "Edit Project" : "Add Project"}
        </h2>
        <ProjectForm
          formValues={newProject}
          handleInputChange={handleInputChange}
          handleDateChange={handleDateChange}
          errors={errors}
        />
        <div className="flex justify-end mt-4">
          <button
            onClick={editMode ? updateExistingProject : addNewProject}
            className="bg-indigo-600 text-white px-5 py-2 rounded mr-2 transition-all duration-200 hover:bg-indigo-700"
          >
            {editMode ? "Update" : "Save"}
          </button>
          <button
            onClick={closeAddProjectModal}
            className="border border-gray-300 text-gray-700 px-5 py-2 rounded hover:bg-gray-100 transition-all duration-200"
          >
            Cancel
          </button>
        </div>
      </Modal>
    </div>
  </Layout>
  
  );
};

export default AddProject;
