import React from 'react';
import Table from '../common/Table';
import Dropdown from "../Dropdown/dropDown"
const columns = [
  { Header: 'Status No', accessor: 'statusID' },
  { Header: 'Status Name', accessor: 'statusName' },
  { Header: 'Notes', accessor: 'notes' },
];

const StatusTable = ({ status, openEditModal, deleteStatus }) => {
  const renderRowActions = ({ statusID }) => (
    <div className='z-50'>
    <Dropdown  onEdit={()=>openEditModal(statusID)} onDelete={()=>deleteStatus(statusID)} />
  </div>
  );

  return (
    <Table
      columns={columns}
      data={status}
      renderRowActions={renderRowActions}
    />
  );
};

export default StatusTable;
