import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout/layout";
import AutoComplete from "../../components/common/AutoComplete/autoComplete"
import { getUsersByUsername,updateUsers } from "../../services/usersS";
import { getInstitutions } from "../../services/awardCenterS";
const Profile = () => {
  const [profile, setProfile] = useState({});
  const [options, setOptions] = useState([]);
  let userDetails = localStorage.getItem("user");
  const parsedUser = JSON.parse(userDetails);
  useEffect(() => {
    fetchUser();
    fetchAwardCenters();
  }, []);
   let adultHelper = parsedUser?.user?.role?.name.toLowerCase() == "Adult Helper".toLowerCase()
  const fetchUser = async () => {
    try {
      let user = await getUsersByUsername(parsedUser.user.username);
      localStorage.setItem("old-user", JSON.stringify(user));
      setProfile(user);
    } catch (error) { 
    }
  };
  const fetchAwardCenters = async () => {
    try {
      let awardCenters = await getInstitutions();
       setOptions(awardCenters)
    } catch (error) { 
    }
  };

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setProfile({ ...profile, [name]: value });
  };

  
  let initialUserProfile = localStorage.getItem("old-user");


const handleProfileUpdate = async(e) => {
  e.preventDefault()
 let profilePayLoad = {
  awardCenter: profile.awardCenter  ?  profile.institutionID : profile.awardCenter.institutionID,
  email: profile.email,
  idNo: profile.idNo,
  name: profile.name,
  phoneNo: profile.phoneNo
 }
 console.log(profilePayLoad)
 let updatedProfile = await updateUsers(profile.username,profilePayLoad)

 setProfile(updatedProfile)
 localStorage.setItem("old-user", JSON.stringify(updatedProfile));
}

  return (
    <Layout>
      <div className="w-full bg-white bg-opacity-50 rounded-lg shadow-lg p-6">
        <h2 className="text-2xl text-center font-myfont font-semibold text-gray-800 mb-4">
          Account Information
        </h2>
        <div className="flex justify-center mt-4 w-full mb-4">
          <img
            className="h-32 w-32 rounded-full border-4 border-white shadow-md"
            src="/assets/profile.jpeg"
            alt="Profile"
          />
        </div>
        <form onSubmit={handleProfileUpdate} className="space-y-4 grid grid-cols-1 md:grid-cols-2 gap-4 pt-5 pb-4">
          <div className="flex flex-col justify-end gap-3">
            <label className="block text-sm font-medium font-sans text-gray-600 bg-transparent">
              Username:
            </label>
            <input
              type="text"
              name="username"
              disabled
              value={profile.username}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="flex flex-col justify-end gap-3">
            <label className="block text-sm font-medium font-sans text-gray-600">Names:</label>
            <input
              type="text"
              name="name"
              onChange={handleChange}
              value={profile.name}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="flex flex-col justify-end gap-3">
            <label className="block text-sm font-medium font-sans text-gray-600">Role:</label>
            <input
              type="role"
              disabled
              name="role"
              value={profile.role?.name}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          {/* Phone */}
          <div className="flex flex-col justify-end gap-3">
            <label className="block text-sm font-medium font-sans text-gray-600">ID No:</label>
            <input
              type="text"
              name="idNo"
              onChange={handleChange}
              value={profile.idNo}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          {/* Job Title */}
          <div className="flex flex-col justify-end gap-3">
            <label className="block text-sm font-medium font-sans text-gray-600">Phone No:</label>
            <input
              type="text"
              name="phoneNo"
              onChange={handleChange}
              value={profile.phoneNo}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          {/* Location */}
          <div className="flex flex-col justify-end gap-3">
            <label className="block text-sm font-medium font-sans text-gray-600">Email:</label>
            <input
              type="text"
              name="email"
              onChange={handleChange}
              value={profile.email}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="flex flex-col justify-end gap-3">
            {/* <label className="block text-gray-600">Award Center:</label> */}
          { adultHelper &&  (<div className="flex flex-col gap-2">
            <label className="text-sm font-medium font-sans text-gray-600 ">
             Award Center
            </label>
            <select
              name="awardCenter"
              value={profile.awardCenter?.institutionID}
              onChange={handleChange}
              className="w-full  p-2 border border-gray-300 rounded">
              <option>
                {profile.awardCenter?.institutionName}
              </option>
              {options.map((option, i) => (
                <option key={i} value={option.institutionID} className="bodyLarge p-2">
                  {option.institutionName}
                </option>
              ))}
            </select>
           
          </div>)}
          </div>
          <div className="ml-auto">
            <button
              disabled={initialUserProfile == JSON.stringify(profile)}
              className={`mt-6 bg-indigo-600 text-white py-2 px-4 rounded 
              ${
                initialUserProfile == JSON.stringify(profile)
                  ? "bg-gray-400 cursor-not-allowed text-gray-700"
                  : "bg-blue-500 text-white cursor-pointer"
              }`}>
              Update
            </button>
          </div>
          <div></div>
        </form>
      </div>
    </Layout>
  );
};

export default Profile;
