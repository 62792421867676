export const customStyles = {
  overlay: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // semi-transparent dark background
    zIndex: 1000, // Ensure the modal is above other content
  },
  content: {
    top: '10%',
    left: '10%',
    right: '10%',
    bottom: '5%',
    margin: 'auto',
    padding: '20px',
    borderRadius: '8px',
    height: 'auto',
    backgroundColor: '#fff',
    boxSizing: 'border-box',
  },
  '@media (max-width: 600px)': {
    content: {
      top: '10%',
      left: '2%',
      right: '2%',
      bottom: '5%',
      width: '96%', // Fill more of the screen on smaller devices
    },
  },
};