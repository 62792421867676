import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { FaPlus } from "react-icons/fa6";
import { FaFilter } from "react-icons/fa";
import { splitWordFunc } from "../../utils/splitWordFunc";
import { FiSearch } from "react-icons/fi";
import { customStyles } from "../../styles/customStyles";
import ParticipantForm from "../../components/forms/participantF";
import ParticipantTable from "../../components/tables/participantT";
import ParticipantDetailsModal from "../participants/participantDetailsModal"
import ParticipantDetailsAndAwardsModal from "../participants/participantDetailsModal";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import { getAwards } from "../../services/participantAwardS"
import {
  addParticipant as addParticipantService,
  getParticipantById,
  updateParticipant,
  deleteParticipant,
  getParticipant,
} from "../../services/participantS";
import Layout from "../../components/Layout/layout";
import * as Yup from 'yup';
// import { participantSchema } from '../../components/forms/participantF';


Modal.setAppElement("#root");

const generateAdminNumber = (index) => {
  const currentYear = new Date().getFullYear();
  const year = currentYear % 100; // Get the last two digits of the year
  const formattedIndex = String(index).padStart(3, '0'); // Example: 001, 002, etc.
  return `PAK-P-${formattedIndex}-${year}`;
};

const AddParticipant = () => {
  const [participants, setParticipants] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newParticipant, setNewParticipant] = useState({
    adminNumber: "",
    name: "",
    dob: "",
    gender: "",
    age: "",
    religion: "",
    ethnicity: "",
    nationality: "",
    phoneNumber: "",
    email: "",
    institutionName: "",
    region: "",
    subCounty: "",
    county: "",
    guardianName: "",
    guardianContact: "",
    emergencyCName: "",
    emergencyCNumber: "",
    emergencyCRelation: "",
    paymentStatus: "",
    marginalised: "",
    atRisk: "",
    notes: "",
  });
  const [passportPhoto, setPassportPhoto] = useState(null); // New state to track the file
  const [selectedColumn, setSelectedColumn] = useState("name");
  const [filteredParticipants, setFilteredParticipants] = useState(participants);
  const [searchTerm, setSearchTerm] = useState("");
  const [update, setUpdate] = useState(false)
  const [errors, setErrors] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [selectedParticipantId, setSelectedParticipantId] = useState(null);
  const [nextIndex, setNextIndex] = useState(0); // Track next index for Admin Number
  const [isParticipantDetailsModalOpen, setIsParticipantDetailsModalOpen] = useState(false);
  const [participantDetails, setParticipantDetails] = useState(null);
  const [isDetailsAndAwardsModalOpen, setIsDetailsAndAwardsModalOpen] = useState(false);
  const [newlyAddedParticipant, setNewlyAddedParticipant] = useState(null);

  useEffect(() => {
    const fetchParticipants = async () => {
      try {
        const fetchedParticipants = await getParticipant();
        
        // Add null/empty check
        if (!fetchedParticipants || fetchedParticipants.length === 0) {
          return;
        }
        setParticipants(fetchedParticipants);
        setFilteredParticipants(fetchedParticipants);
        // Calculate the next index based on the current year
        const currentYear = new Date().getFullYear();
        const year = currentYear % 100; // Get the last two digits of the year
  
        // Add safety checks for the array operations
        const filteredNumbers = fetchedParticipants
          .filter(p => p.adminNumber && p.adminNumber.includes(`-${year}`))
          .map(p => {
            const parts = p.adminNumber.split('-');
            return parts.length >= 3 ? parseInt(parts[2], 10) : 0;
          })
          .filter(num => !isNaN(num));
  
        const latestIndex = filteredNumbers.length > 0 
          ? Math.max(...filteredNumbers)
          : 0;
  
        setNextIndex(latestIndex + 1);
  
      } catch (error) {
        // Improved error handling
        toast.error("Error fetching participants:", {
          error,
          response: error.response?.data,
          message: error.message
        });
      }
    };
  
    fetchParticipants();
  }, [update]);

  const openParticipantDetails = async (adminNumber) => {
    try {
      const participant = await getParticipantById(adminNumber);


      if (participant) {
        const awards = await getAwards();
        const participantAwards = awards.filter(award => award.adminNumber === adminNumber)
        const awardDetails = participantAwards.map(award => ({
          levelName: award.levelName,
          status: award.status,
        }));
        const awardDisplay = awardDetails
          .map(detail => `${detail.levelName} - ${detail.status} `)
          .join(', ');
        const details = {
          adminNumber: participant.adminNumber,
          institutionName: participant.institutionName,
          name: participant.name,
          dob: participant.dob,
          gender: participant.gender,
          age: participant.age,
          phoneNumber: participant.phoneNumber,
          email: participant.email,
          atRisk: participant.atRisk,
          passportPhoto: participant.passportPhoto,
          awards: awardDisplay
        };

        setNewlyAddedParticipant(participant);
        setIsParticipantDetailsModalOpen(true);
        setParticipantDetails(details);

      }
    } catch (error) {
      toast.error('Error fetching participant details:', error);
    }
  };

  const closeParticipantDetailsModal = () => {
    setIsParticipantDetailsModalOpen(false);
    setParticipantDetails(null);
  };


  const handleInputChange = async (event) => {
    const { name, value } = event.target;
    try {
      setNewParticipant((prev) => ({
        ...prev,
        [name]: value,
      }));
      setErrors({ ...errors, [name]: undefined });
    } catch (error) {
      setErrors({ ...errors, [name]: error.message }); // Update errors state with error message
    }
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setNewParticipant((prevData) => ({
      ...prevData,
      [name]: files[0], // assuming you want to handle single file uploads
    }));

    // You can add validation logic here for file type and size if needed
  };
  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setNewParticipant((prev) => ({
      ...prev,
      [name]: value,
    }));
  };


  const openAddParticipantModal = () => {
    setEditMode(false);
    setIsModalOpen(true);
    setNewParticipant({
      adminNumber: generateAdminNumber(nextIndex),
      name: "",
      dob: "",
      gender: "",
      age: "",
      religion: "",
      ethnicity: "",
      nationality: "",
      phoneNumber: "",
      email: "",
      institutionName: "",
      subCounty: "",
      county: "",
      awardLevel: "",
      guardianName: "",
      guardianContact: "",
      emergencyCName: "",
      emergencyCNumber: "",
      emergencyCRelation: "",
      paymentStatus: "",
      marginalised: "",
      atRisk: "",
      notes: "",
    });
  };

  const openEditParticipantModal = async (adminNumber) => {
    try {
      const fetchedParticipant = await getParticipantById(
        adminNumber
      );
      setEditMode(true);
      setIsModalOpen(true);
      setSelectedParticipantId(adminNumber);
      setNewParticipant({
        ...fetchedParticipant,
        dateOfBirth: fetchedParticipant.dateOfBirth,
      });
    } catch (error) {
      toast.error(
        `Error fetching participant with ID ${adminNumber}:`,
        error.response.data
      );
    }
  };
  const updateExistingParticipant = async () => {
    try {
      // Create FormData to handle file upload
      const formData = new FormData();
      Object.keys(newParticipant).forEach(key => {
        formData.append(key, newParticipant[key]);
      });
      if (passportPhoto) {
        formData.append('passportPhoto', passportPhoto); // Append file to FormData
      }

      const updatedParticipant = await updateParticipant(
        selectedParticipantId,
        formData // Send FormData to the service
      );

      setUpdate(prev => prev);
      setIsModalOpen(false);
      setErrors({});
    } catch (error) {
      setErrors(error.response.data.errors || {});
      toast(
        `Failed to update participant: ${error.response.data.title
        }\nDetails: ${JSON.stringify(error, null, 2)}`
      );
    }
  };

  const deleteExistingParticipant = async (adminNumber) => {
    try {
      await deleteParticipant(adminNumber);
      setUpdate(prev => !prev)
    } catch (error) {
      toast.error(
        `Error deleting participant with ID ${adminNumber}:`,
        error.response.data
      );
      toast(`Failed to delete participant: ${error.response.data.title}`);
    }
  };

  const closeAddParticipantModal = () => {
    setIsModalOpen(false);
    setEditMode(false);
    setErrors({});
  };

  const deleteParticipantHandler = (adminNumber) => {
    if (
      window.confirm(
        `Are you sure you want to delete participant with ID ${adminNumber}?`
      )
    ) {
      deleteExistingParticipant(adminNumber);
    }
  };
  //Search
  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    filterParticipants(value, selectedColumn);
  };
  const handleFilterParameterChange = (event) => {
    const value = event.target.value;
    setSelectedColumn(value);
    filterParticipants(searchTerm, value);
  };

  const filterParticipants = (searchTerm, column) => {
    const filtered = participants.filter((participant) =>
      participant[column]
        .toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
    setFilteredParticipants(filtered);
  };

  const closeDetailsAndAwardsModal = () => {
    setIsDetailsAndAwardsModalOpen(false);
    setNewlyAddedParticipant(null);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Create FormData to hold the participant object and files
    const formData = new FormData();

    // Append each key-value pair to the FormData object

    formData.append("Name", newParticipant.name)
    formData.append("AdminNumber", newParticipant.adminNumber)
    formData.append("County", newParticipant.county)
    formData.append("Email", newParticipant.email)
    formData.append("EmergencyCName", newParticipant.emergencyCName)
    formData.append("Age", newParticipant.age)
    formData.append("EmergencyCNumber", newParticipant.emergencyCNumber)
    formData.append("EmergencyCRelation", newParticipant.emergencyCRelation)
    formData.append("Ethnicity", newParticipant.ethnicity)
    formData.append("Gender", newParticipant.gender)
    formData.append("GuardianContact", newParticipant.guardianContact)
    formData.append("InstitutionName", newParticipant.institutionName)
    formData.append("Marginalised", newParticipant.marginalised)
    formData.append("Nationality", newParticipant.nationality)
    formData.append("Notes", newParticipant.notes)
    formData.append("PaymentStatus", newParticipant.paymentStatus)
    formData.append("PhoneNumber", newParticipant.phoneNumber)
    formData.append("Region", newParticipant.region)
    formData.append("Religion", newParticipant.religion)
    formData.append("SubCounty", newParticipant.subCounty)
    formData.append("GuardianName", newParticipant.guardianName)
    formData.append("AtRisk", newParticipant.atRisk)
    // Append files

    if (newParticipant.doc) formData.append('Doc', newParticipant.doc);
    if (newParticipant.passportPhoto) formData.append('PassportPhoto', newParticipant.passportPhoto);

    try {
      // Create the participant

      const response = await fetch(process.env.REACT_APP_API_BASE_URL + "/api/Participants", {
        method: "POST",
        body: formData // Sending the formData with both participant object and files
      });
      const responseData = await response.json();


      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      // Set newly added participant data
      setNewlyAddedParticipant(responseData);
      setIsModalOpen(false);
      setErrors({});
      setUpdate((prev) => !prev);
      setIsDetailsAndAwardsModalOpen(true);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        // Handle Yup validation errors
        const validationErrors = {};
        error.inner.forEach((err) => {
          validationErrors[err.path] = err.message;
        });
        toast.error('Validation errors:', validationErrors);
        setErrors(validationErrors);
      } else {
        toast.error('Error submitting the form:', error);
        toast(`Failed to add participant: ${error.response?.data?.title || 'Unknown error'}`);
      }
    }
  };


  return (
    <Layout>
    <div className="bg-white rounded-lg shadow-lg">
      <div className="px-6 py-4 border-b border-gray-200">
        <h1 className="text-2xl font-semibold text-gray-800">Participants</h1>
      </div>
  
      <div className="p-6">
        <div className="space-y-4 md:space-y-0 md:flex md:items-center md:justify-between">
          <button
            onClick={openAddParticipantModal}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-all duration-200"
          >
            <FaPlus className="mr-2 h-4 w-4" />
            Add Participant
          </button>
  
          <div className="flex flex-col md:flex-row gap-4 flex-1 md:max-w-2xl md:ml-6">
            <div className="relative flex-1">
              <select
                className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-md bg-white shadow-sm"
                value={selectedColumn}
                onChange={handleFilterParameterChange}
              >
                {participants.length > 0 ? (
                  Object.keys(participants[0])?.map((op) => (
                    <option key={op} value={op}>
                      {splitWordFunc(op)}
                    </option>
                  ))
                ) : (
                  <option>No filter property</option>
                )}
              </select>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <FaFilter className="h-4 w-4 text-gray-400" />
              </div>
            </div>
  
            <div className="relative flex-1">
              <input
                type="text"
                placeholder="Search participant..."
                onChange={handleSearchChange}
                className="block w-full pl-3 pr-10 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
              <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                <FiSearch className="h-5 w-5 text-gray-400" />
              </div>
            </div>
          </div>
        </div>
  
        <div className="mt-8">
          <ParticipantTable
            participants={filteredParticipants}
            openEditModal={openEditParticipantModal}
            deleteParticipant={deleteParticipantHandler}
            openParticipantDetail={openParticipantDetails}
          />
        </div>
      </div>
  
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeAddParticipantModal}
        contentLabel={editMode ? "Edit Participant" : "Add Participant"}
        style={customStyles}
      >
        <h2 className="text-xl font-semibold text-gray-800 mb-4">
          {editMode ? "Edit Participant" : "Add Participant"}
        </h2>
        <form>
          <ParticipantForm
            formValues={newParticipant}
            handleInputChange={handleInputChange}
            handleDateChange={handleDateChange}
            handleFileChange={handleFileChange}
            errors={errors}
          />
          <div className="flex justify-end mt-4">
            <button
              type="button"
              onClick={editMode ? updateExistingParticipant : handleSubmit}
              className="bg-indigo-600 px-5 text-white py-2 rounded mr-2 hover:bg-indigo-700 transition-all duration-200"
            >
              {editMode ? "Update" : "Save and Add Awards"}
            </button>
            <button
              type="button"
              onClick={closeAddParticipantModal}
              className="border border-indigo-600 px-5 text-indigo-600 py-2 rounded hover:bg-gray-100 transition-all duration-200"
            >
              Cancel
            </button>
          </div>
        </form>
      </Modal>
  
      <ParticipantDetailsModal
        isOpen={isParticipantDetailsModalOpen}
        onRequestClose={closeParticipantDetailsModal}
        participantDetails={participantDetails}
      />
  
      <ParticipantDetailsAndAwardsModal
        isOpen={isDetailsAndAwardsModalOpen}
        onRequestClose={closeDetailsAndAwardsModal}
        participantDetails={newlyAddedParticipant}
      />
    </div>
  </Layout>
  
  );
};

export default AddParticipant;
