export const  splitWordFunc = (str)=> {
    let formattedStr = str.replace(/([a-z])([A-Z])/g, '$1 $2');
    formattedStr = formattedStr.charAt(0).toUpperCase() + formattedStr.slice(1);
    return formattedStr;
  }
  export const generateNameValueConfig = (data) => {
    return Object.keys(data).map((key) => ({
      name: splitWordFunc(key),
      value: data[key],
    }));
  };
  
  export function formatDateFromFormValues(value) {
   
    if (value) {
      const date = new Date(value);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Add leading 0 for single-digit months
      const day = String(date.getDate()).padStart(2, '0'); // Add leading 0 for single-digit days
      return `${year}-${month}-${day}`;
    }
    return ""; // Return empty string if no date value exists
  }