import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { FaPlus } from "react-icons/fa6";
import { FaFilter } from "react-icons/fa";
import { splitWordFunc } from "../../utils/splitWordFunc";
import { FiSearch } from "react-icons/fi";
import { customStyles } from '../../styles/customStyles';
import ProgramForm from '../../components/forms/programF';
import ProgramTable from '../../components/tables/programT';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import { addPrograms as addProgramService, getProgramsById, updatePrograms, deletePrograms, getPrograms } from '../../services/programS';
import Layout from '../../components/Layout/layout';

Modal.setAppElement('#root');

const AddProgram = () => {
  const [programs, setPrograms] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newProgram, setNewProgram] = useState({
    programID: '',
    programName: '',
    institutionName: '',
    startDate: '',
    subCounty: '',
    county: '',
    description: '',
    coordinator: '',
  });
  const [selectedColumn, setSelectedColumn] = useState("programName");
  const [filteredPrograms, setFilteredPrograms] = useState(programs);
  const [searchTerm, setSearchTerm] = useState("");
  const [update,setUpdate] = useState(false)
  const [errors, setErrors] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [selectedProgramId, setSelectedProgramId] = useState(null);

  useEffect(() => {
    const fetchPrograms = async () => {
      try {
        const fetchedPrograms = await getPrograms();
        setPrograms(fetchedPrograms);
        setFilteredPrograms(fetchedPrograms);
      } catch (error) {
        toast.error('Error fetching Program:', error.response.data);
      }
    };

    fetchPrograms();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewProgram((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setNewProgram((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const addNewProgram = async () => {
    try {
      const budgetPayload = { ...newProgram };
      const addedProgram = await addProgramService(budgetPayload);
      setPrograms((prev) => [...prev, addedProgram]);
      setIsModalOpen(false);
      setErrors({});
    } catch (error) {
      toast.error('Error adding programs:', error.response.data);
      setErrors(error.response.data.errors || {});
      alert(`Failed to add programs: ${error.response.data.title}\nDetails: ${JSON.stringify(error.response.data.errors, null, 2)}`);
    }
  };

  const openAddProgramModal = () => {
    setEditMode(false);
    setIsModalOpen(true);
    setNewProgram({
      programID: '',
      programName: '',
      institutionName: '',
      startDate: '',
      subCounty: '',
      county: '',
      description: '',
      coordinator: '',
        
    });
  };

  const openEditProgramModal = async (programs) => {
    try {
      const fetchedProgram = await getProgramsById(programs.programID);
      setEditMode(true);
      setIsModalOpen(true);
      setSelectedProgramId(programs.programID);
      setNewProgram({
        ...fetchedProgram,
        startDate: fetchedProgram.startDate,
        endDate: fetchedProgram.endDate,
      });
    } catch (error) {
      toast.error(`Error fetching programs with ID ${programs.programID}:`, error.response.data);
    }
  };
  
  const updateExistingProgram = async () => {
    try {
      const budgetPayload = { ...newProgram };
  
      const updatedProgram = await updatePrograms(selectedProgramId, budgetPayload);
      const newPrograms = await getPrograms ()
      setPrograms(newPrograms)
      setIsModalOpen(false); 
      setErrors({});
    } catch (error) {
      toast.error(`Error updating programs with ID ${selectedProgramId}:`, error.response.data);
      setErrors(error.response.data.errors || {});
      alert(`Failed to update programs: ${error.response.data.title}\nDetails: ${JSON.stringify(error.response.data.errors, null, 2)}`);
    }
  };

  const deleteExistingProgram = async (programID) => {
    try {
      await deletePrograms(programID);
      setPrograms((prev) => prev.filter(inst => inst.programID !== programID));
    } catch (error) {
      toast.error(`Error deleting programs with ID ${programID}:`, error.response.data);
      alert(`Failed to delete programs: ${error.response.data.title}`);
    }
  };

  const closeAddProgramModal = () => {
    setIsModalOpen(false);
    setEditMode(false);
    setErrors({});
  };

  const deleteProgramsHandler = (programID) => {
    if (window.confirm(`Are you sure you want to delete programs with ID ${programID}?`)) {
      deleteExistingProgram(programID);
    }
  };

    //Search
    const handleSearchChange = (event) => {
      const value = event.target.value;
      setSearchTerm(value);
      filterPrograms(value, selectedColumn);
    };
    const handleFilterParameterChange = (event) => {
      const value = event.target.value;
      setSelectedColumn(value);
      filterPrograms(searchTerm, value);
    };
  
    const filterPrograms = (searchTerm, column) => {
      const filtered = programs.filter((program) =>
        program[column]
          .toString()
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      );
      setFilteredPrograms(filtered);
    };

  return (
    <Layout>
  <div className="bg-white rounded-lg shadow-lg">
    <div className="px-6 py-4 border-b border-gray-200">
      <h1 className="text-2xl font-semibold text-gray-800">Programs</h1>
    </div>

    <div className="p-6">
      <div className="space-y-4 md:space-y-0 md:flex md:items-center md:justify-between mb-6">
        <button
          onClick={openAddProgramModal}
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-all duration-200"
        >
          <FaPlus className="mr-2 h-4 w-4" />
          Program
        </button>

        <div className="flex flex-col md:flex-row gap-4 flex-1 md:max-w-2xl">
          <div className="relative flex-1">
            <select
              className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-md bg-white shadow-sm"
              value={selectedColumn}
              onChange={handleFilterParameterChange}
            >
              {programs.length > 0 ? (
                Object.keys(programs[0])?.map((op) => (
                  <option key={op} value={op}>
                    {splitWordFunc(op)}
                  </option>
                ))
              ) : (
                <option>No filter property</option>
              )}
            </select>
            <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <FaFilter className="h-4 w-4 text-gray-400" />
            </div>
          </div>

          <div className="relative flex-1">
            <input
              type="text"
              placeholder="Search..."
              onChange={handleSearchChange}
              className="block w-full pl-3 pr-10 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
            <div className="absolute inset-y-0 right-0 flex items-center pr-3">
              <FiSearch className="h-5 w-5 text-gray-400" />
            </div>
          </div>
        </div>
      </div>

      <ProgramTable
        programs={filteredPrograms}
        openEditModal={openEditProgramModal}
        deletePrograms={deleteProgramsHandler}
      />
    </div>

    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeAddProgramModal}
      contentLabel={editMode ? "Edit Program" : "Add Program"}
      style={customStyles}
    >
      <h2 className="text-xl font-semibold text-gray-800 mb-4">
        {editMode ? "Edit Program" : "Add Program"}
      </h2>
      <ProgramForm 
        formValues={newProgram} 
        handleInputChange={handleInputChange} 
        handleDateChange={handleDateChange} 
        errors={errors} 
      />
      <div className="flex justify-end mt-4">
        <button
          onClick={editMode ? updateExistingProgram : addNewProgram}
          className="bg-indigo-600 text-white px-5 py-2 rounded mr-2 transition-all duration-200 hover:bg-indigo-700"
        >
          {editMode ? "Update" : "Save"}
        </button>
        <button
          onClick={closeAddProgramModal}
          className="border border-gray-300 text-gray-700 px-5 py-2 rounded hover:bg-gray-100 transition-all duration-200"
        >
          Cancel
        </button>
      </div>
    </Modal>
  </div>
</Layout>

  );
};

export default AddProgram;