import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import AuthLayout from '../components/surfaces/authLayout';
import Button from "../components/common/Button/Button"
import { useNavigate } from 'react-router-dom';
import apiClient from '../services/apiClient'
import InputContainer from "../components/surfaces/InputContainer"
import Autocomplete from '../components/common/AutoComplete/autoComplete';
import { getInstitutions } from "../services/awardCenterS";
import { removeAdmins } from '../utils/removeAdmin';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 

const inputConfig = [{label: 'Username',placeholder: "Enter Username",type: "text",name: "username"}, {label: 'Full Names',placeholder: "Enter Full Names",type: "text",name: "fullNames"},{label: 'Email',placeholder: "Enter Email",type: "email",name: "email"},{label: 'Phone Number',placeholder: "Enter Phone Number",type: "number",name: "pNumber"},{label: 'ID Number',placeholder: "Enter ID Number",type: "number",name: "IdNumber"}];

const schema = yup
  .object({
    username: yup.string().required("Username is required"),
    fullNames: yup.string().required("Full Names is required"),
    awardCenter: yup.string().required("Award Center is required"),
    email: yup.string().email("Email must be a valid email").required("Email is required"),
    pNumber: yup.string().matches(/^[0-9]+$/, "Phone number must contain only digits").required("Phone Number is required"),
    IdNumber: yup.string().matches(/^[0-9]+$/, "ID number must contain only digits").required("Id Number is required"),
    role: yup.string().required("Please select a role"),
    gender: yup.string().required("Please select your gender"),
    password: yup.string().required("Password is required"),
  })
  .required()

const Signin = ()=>{
const [loading,setLoading] =useState(false)
const [registerError, setRegisterError] = useState(false)
const [roles,setRoles] = useState([])
const [institutions, setInstitutions] = useState([])
const [errorMessages,setErrorMessages] = useState([])
const navigate = useNavigate()
    const {
      setValue,
        handleSubmit,
        formState: { errors },
      } = useForm({
        resolver: yupResolver(schema),
      })

      const onSubmit = async(data) => {
        setLoading(true)
        try{
          const response =  await apiClient.post('api/User',{
            idNo: data.IdNumber.toString(),
            username: data.username,
            name: data.fullNames,
            awardCenter: data.awardCenter,
            roleID: parseInt(data.role),
            gender: data.gender,
            phoneNo: data.pNumber.toString(),
            email: data.email,
            password:  data.password,
          })
          setLoading(false)
          navigate('/dashboard')
          return response.data
        }catch(err){
          setRegisterError(true)
          setErrorMessages(Object.values(err.response.data.errors).flat())
          // setErrorMessages([...err.response.data.errors])
          setLoading(false)
          setTimeout(()=>{
            setRegisterError(err)
          },2000)
        }
      
      }

        //Fetch institutions
  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedInstitutions = await getInstitutions();
        setInstitutions(fetchedInstitutions);
      } catch (error) {
        toast.error("Error fetching institutions:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const sortedInstitutions = institutions.map(i => ({ name: i.institutionName, id: i.institutionID }));

      useEffect(()=>{
        try {
         getRoles()
         
        } catch (error) {
          
        }
      },[])

      const getRoles = async()=>{
        const response =  await apiClient.get('api/Roles')
        setRoles(removeAdmins(response.data))
      }

      return(
          <AuthLayout style={``}>
              <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col gap-5 md:gap-10 w-10/12'>
              {registerError && (
              <div role="alert">
                <div class="bg-red-500 text-white font-bold rounded-t px-4 py-2">
                  Account creation Failed !!
                </div>
                <div class="border border-t-0 border-red-400 rounded-b bg-red-100 px-4 py-3 text-red-700">
                 {errorMessages.length > 0 &&  (errorMessages?.map(m=> <p className='text-black'>{m}</p>))}
                </div>
              </div>
            )}
              <div>
              <div className='flex flex-col gap-2'>
                <h2 color='secondary' className='text-main subtitle1'>Register</h2>
                <p className='text-greys body1' >Enter your details below to sign up!</p>
              </div>
              </div>
               <div className='grid md:grid-cols-2 gap-2 w-full'>
                {inputConfig.map((field,index)=>
                <InputContainer error={errors[field.name]?.message} name={field.name} setValue={setValue} key={index} label={field.label} placeholder={field.placeholder} type={field.type} />)}
                <Autocomplete setValue={setValue} options={[{name: "Male",id: "Male"},{name: "Female",id: "Female"}]} label="Gender" name='gender' error={errors.gender?.message}/>
                <Autocomplete setValue={setValue} options={[...roles]} label="Role" name='role' error={errors.role?.message}/>
                <Autocomplete setValue={setValue} options={[...sortedInstitutions]} label="Award Center" name='awardCenter' error={errors.awardCenter?.message} />
                <InputContainer error={errors.password?.message} name='password' setValue={setValue} label='Password' type='password' placeholder='Enter Password' />
               </div>
               <div>
                <Button  className="bg-primary text-white w-full">{loading ? 'Loading...' : 'Register'}</Button>
               </div>
               <div>
                <p className='flex flex-row  gap-2'>
                    <span className='body2 text-main'>Already have an account?</span>
                    <a className='bodyLarge text-primary cursor-pointer' href='/signin'>Sign In</a>
                </p>
               </div>
              </form>
          </AuthLayout>
      )
}
    
export default Signin;