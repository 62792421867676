import apiClient from './apiClient';
import { logAction } from '../utils/logger';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 

const addTrainingType = async (TrainingTypeData) => {
  try {
    const response = await apiClient.post('/api/TrainingType', TrainingTypeData);
    if(response){
      logAction("Created Training Type","Training Type",`Created the following Training Type ${JSON.stringify(response.data)}`)
    }
    return response.data;
  } catch (error) {
    toast.error('Error in addTrainingTypes:', error.response.data);
    throw error;
  }
};

const getTrainingTypeById = async (respondentID) => {
  try {
    const response = await apiClient.get(`/api/TrainingType/${respondentID}`);
    
    return response.data;
  } catch (error) {
    toast.error(`Error fetching TrainingTypes with ID ${respondentID}:`, error.response.data);
    throw error;
  }
};

const updateTrainingType = async (respondentID, updatedData) => {
  try {
    const response = await apiClient.put(`/api/TrainingType/${respondentID}`, updatedData);
    if(response){
      logAction("Updated Training Type","Training Type",`Updated the following Training Type ${JSON.stringify(response.data)}`)
    }
    return response.data;
  } catch (error) {
    toast.error(`Error updating TrainingTypes with ID ${respondentID}:`, error.response.data);
    throw error;
  }
};

const deleteTrainingType = async (respondentID) => {
  try {
    const response = await apiClient.delete(`/api/TrainingType/${respondentID}`);
    if(response){
      logAction("Deleted Training Type","Training Type",`Deleted the following Training Type ${JSON.stringify(response.data)}`)
    }
  } catch (error) {
    toast.error(`Error deleting TrainingTypes with ID ${respondentID}:`, error.response.data);
    throw error;
  }
};

const getTrainingType = async () => {
  try {
    const response = await apiClient.get('/api/TrainingType');
    return response.data;
  } catch (error) {
    toast.error('Error fetching TrainingTypes:', error.response.data);
    throw error;
  }
};

export { addTrainingType, getTrainingTypeById, updateTrainingType, deleteTrainingType, getTrainingType };
