import apiClient from '../apiClient';
import { formatDate } from './formatDate';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 

const ACTIVITY_APPROVAL_API_URL = '/api/ActivityApproval';

export const fetchActivityApprovalData = async () => {
  try {
    const response = await apiClient.get(ACTIVITY_APPROVAL_API_URL);
    const formattedData = response.data.map(item => ({
      ...item,
      activityDate: formatDate(item.activityDate),
      applyDate: formatDate(item.applyDate),
    }));
    return formattedData;
  } catch (error) {
    toast.error('Error fetching activity approval data:', error);
    throw error;
  }
};

export const activityApprovalHeaders = [
  { Header: 'Center', accessor: 'awardCentre' },
  { Header: 'Region', accessor: 'region' },           
  { Header: 'Award Leader', accessor: 'awardLeader' },
  { Header: 'Activity Name', accessor: 'activityName' },
  { Header: 'Activity Date', accessor: 'activityDate' },
  { Header: 'Apply Date', accessor: 'applyDate' },
  { Header: 'Assessors', accessor: 'assessors' },
  { Header: 'No. of Participants', accessor: 'participantsNo' },
  { Header: 'Upload Form', accessor: 'uploadForm' },
  { Header: 'Consent', accessor: 'consent' },
  {Header: 'Approval', accessor: 'approval'},        
  { Header: 'Notes', accessor: 'notes' },
];
