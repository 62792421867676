import React, { useMemo, useState, useEffect } from 'react';
import Table from '../common/Table';
import Dropdown from "../Dropdown/dropDown";
import { getUsersByUsername } from '../../services/usersS';

const ParticipantTable = ({ participants, openEditModal, deleteParticipant, openParticipantDetail }) => {
  const [userInstitution, setUserInstitution] = useState("");
  const loggedInUser = JSON.parse(localStorage.getItem("user"))?.user;


  const fetchUserInstitution = async () => {
    
    if (loggedInUser?.role.name === "Award Leader" || loggedInUser?.role.name === "Adult Helper") {
  
      try {
        const userData = await getUsersByUsername(loggedInUser.username);
        // Check if awardCenter contains institutionName
        if (userData?.awardCenter?.institutionName) {
          setUserInstitution(userData.awardCenter.institutionName); // Set the institutionName
        } else {
          console.warn("No institution name found in user data");
        }
      } catch (error) {
        console.error("Error fetching user institution:", error);
      }
    }
  };

    // Fetch user's institution details when component mounts
    useEffect(() => {
      fetchUserInstitution();
    }, []);
    

  // Filter participants based on user role and institution
  const filteredParticipants = useMemo(() => {
    if (!loggedInUser || !participants) return [];
    if (loggedInUser.role.name === "Award Leader" || loggedInUser.role.name === "Adult Helper" && userInstitution) {
      // Filter participants based on institutionName
      return participants.filter(participant => 
        participant.institutionName === userInstitution
      );
    }
  
    // If not Award Leader, return all participants
    return participants;
  }, [loggedInUser, participants, userInstitution]); // Dependencies: ensure userInstitution is updated correctly
  

  const columns = [
    { 
      Header: 'Admin Number', 
      accessor: 'adminNumber',
      Cell: ({ value, row }) => (
        <button className="text-blue-500 underline" onClick={() => openParticipantDetail(row.original.adminNumber)}>
          {value}
        </button>
      )
    },
    { 
      Header: 'Name', accessor: 'name' },
    { Header: 'DOB', accessor: 'dob' },
    { Header: 'Gender', accessor: 'gender' },
    { Header: 'Age', accessor: 'age' },
    { Header: 'Religion', accessor: 'religion' },
    { Header: 'Ethnicity', accessor: 'ethnicity' },
    { Header: 'Nationality', accessor: 'nationality' },
    { Header: 'Phone No', accessor: 'phoneNumber' },
    { Header: 'Email', accessor: 'email' },
    { Header: 'Centre', accessor: 'institutionName' },
    { Header: 'Region', accessor: 'region' },
    { Header: 'County', accessor: 'county' },
    { Header: 'Sub-County', accessor: 'subCounty' },
    { Header: 'Guardian Name', accessor: 'guardianName' },
    { Header: 'Guardian Contact', accessor: 'guardianContact' },
    { Header: 'Emerg. Contact', accessor: 'emergencyCName' },
    { Header: 'Emerg. Number', accessor: 'emergencyCNumber' },
    { Header: 'Emerg. Relation', accessor: 'emergencyCRelation' },
    { Header: 'Status', accessor: 'paymentStatus' },
    { Header: 'Marginalised', accessor: 'marginalised' },
    { Header: 'At Risk?', accessor: 'atRisk' },
    { Header: 'Notes', accessor: 'notes' },
    { Header: 'Doc Upload', accessor: 'docUpload',
      Cell: ({ value }) => value ? (
        <a
          href={process.env.REACT_APP_API_BASE_URL + value}
          target="_blank"
          rel="noopener noreferrer"
          download
          className="text-blue-600 hover:text-blue-800 underline"
        >
          Download Form
        </a>
      ) : (
        <span className="text-gray-400">No form</span>
      )
     },
    
  ];

  const renderRowActions = ({ adminNumber }) => (
    <div className='z-50'>
      <Dropdown onEdit={() => openEditModal(adminNumber)} onDelete={() => deleteParticipant(adminNumber)} />
    </div>
  );


  return (
    <Table
      columns={columns}
      data={filteredParticipants}
      renderRowActions={renderRowActions}
    />
  );
};

export default ParticipantTable;
