import React from 'react';
import Table from '../common/Table';
import Dropdown from "../Dropdown/dropDown"

const columns = [
  { Header: 'User ID', accessor: 'userID' },
  { Header: 'User Name', accessor: 'username' },
  { Header: 'Role', accessor: 'role' },
  { Header: 'Description', accessor: 'description' },
  { Header: 'Notes', accessor: 'notes' },
];

const TestimonialsTable = ({ testimonials = [], openEditModal, deleteTestimonial }) => {
  const renderRowActions = ({ userID }) => (
    <div className='z-50'>
    <Dropdown  onEdit={()=>openEditModal(userID)} onDelete={()=>deleteTestimonial(userID)} />
  </div>
  );

  return (
    <Table
      columns={columns}
      data={testimonials}
      renderRowActions={renderRowActions}
    />
  );
};

export default TestimonialsTable;
