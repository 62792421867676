import React from 'react';
import Table from '../common/Table';
import Dropdown from "../Dropdown/dropDown"

const columns = [
  { Header: 'Name', accessor: 'name' },
  { Header: 'Notes', accessor: 'notes' }
];

const PermissionsTable = ({ permissions = [], openEditModal, deletePermission }) => {
  const renderRowActions = ({ id }) => (
    <div className='z-50'>
    <Dropdown  onEdit={()=>openEditModal(id)} onDelete={()=>deletePermission(id)} />
  </div>
  );

  return (
    <Table
      columns={columns}
      data={permissions}
      renderRowActions={renderRowActions}
    />
  );
};

export default PermissionsTable;
