import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { FaPlus } from "react-icons/fa6";
import { FaFilter } from "react-icons/fa";
import { splitWordFunc } from "../../utils/splitWordFunc";
import { FiSearch } from "react-icons/fi";
import AwardsForm from "../../components/forms/participantAwardsF";
import AwardsTable from "../../components/tables/participantsAwardT";
import { addawardsService, getAwardsById, updateAwards, deleteAwards, getAwards } from "../../services/participantAwardS";
import Layout from "../../components/Layout/layout";
import { customStyles } from "../../styles/customStyles";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 


Modal.setAppElement("#root");

const AddAwards = ({ onClose, adminNumber, studentName, institutionName }) => {
  const [awards, setAwards] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newAwards, setnewAwards] = useState({
    awardID: "",
    adminNumber: adminNumber,
    studentName: studentName,
    institutionName: institutionName,
    levelName: "",
    startDate: "",
    expectedEndDate: "",
    status: "",
    certNo: "",
    notes: "",
  });
  const [selectedColumn, setSelectedColumn] = useState("studentName");
  const [filteredAwards, setFilteredAwards] = useState(awards);
  const [searchTerm, setSearchTerm] = useState("");
  const [errors, setErrors] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [selectedawardID, setSelectedawardID] = useState(null);

  useEffect(() => {
    const fetchedawards = async () => {
      try {
        const fetchedawards = await getAwards();
        setAwards(fetchedawards);
        setFilteredAwards(fetchedawards);
      } catch (error) {
        toast.error("Error fetching awards:", error.response.data);
      }
    };

    fetchedawards();
  }, []);

  const generateAwardID = (levelName, currentId) => {
    const prefix = {
      Bronze: 'B',
      Silver: 'S',
      Gold: 'G'
    }[levelName] || '';
    return `${prefix}${String(currentId).padStart(3, '0')}`;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setnewAwards((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (name === 'levelName') {
      const newId = awards.filter(award => award.levelName === value).length + 1;
      const newAwardID = generateAwardID(value, newId);
      setnewAwards(prev => ({
        ...prev,
        awardID: newAwardID
      }));
    }
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setnewAwards((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const addnewAwards = async () => {
    try {
      // Check if the participant already has an award at the same level
      const hasSameLevelAward = awards.some(
        award =>
          award.adminNumber === newAwards.adminNumber &&
          award.levelName === newAwards.levelName
      );
  
      if (hasSameLevelAward) {
        toast('This admission number already has an award for the same level.');
        return;
      }
  
      // Check if the participant has an incomplete award
      const hasIncompleteAward = awards.some(
        award =>
          award.adminNumber === newAwards.adminNumber &&
          award.levelName !== newAwards.levelName &&
          award.status !== 'Completed'
      );
  
      if (hasIncompleteAward) {
        toast('You cannot enroll in a new award level until all current awards are completed.');
        return;
      }
  
      const awardsPayload = {
        awardID: String(newAwards.awardID), // Ensure awardID is a string
        adminNumber: newAwards.adminNumber,
        studentName: newAwards.studentName,
        institutionName: newAwards.institutionName,
        levelName: newAwards.levelName,
        startDate: newAwards.startDate,
        expectedEndDate: newAwards.expectedEndDate,
        actualEndDate: newAwards.actualEndDate,
        status: newAwards.status,
        certNo: newAwards.certNo,
        notes: newAwards.notes,
      };
  
      const addedawards = await addawardsService(awardsPayload);
      setAwards((prev) => [...prev, addedawards]);
      setIsModalOpen(false);
    } catch (error) {
      toast.error("Error adding awards:", error.response.data);
      setErrors(error.response.data.errors || {});
      toast(
        `Failed to add awards: ${
          error.response.data.title
        }\nDetails: ${JSON.stringify(error.response.data.errors, null, 2)}`
      );
    }
  };
  
    const openAddawardsModal = () => {
        setEditMode(false);
        setIsModalOpen(true);
        setnewAwards({
            awardID: '',
            adminNumber: '',
            studentName: '',
            institutionName: '',
            levelName: '',
            startDate: '',
            expectedEndDate: '',
            actualEndDate: '',
            status: '',
            certNo: "",
            notes: '',
        });
    };

  const openEditawardsModal = async (awards) => {
    try {
      const fetchedawards = await getAwardsById(String(awards.awardID));
      setEditMode(true);
      setIsModalOpen(true);
      setSelectedawardID(awards.awardID);
      setnewAwards({
        ...fetchedawards,
      });
    } catch (error) {
      toast.error(
        `Error fetching awards with ID ${awards.awardID}:`,
        error.response.data
      );
    }
  };

  const updateExistingawards = async () => {
    try {
      const awardsPayload = { ...newAwards };

      const updatedawards = await updateAwards(selectedawardID, awardsPayload);
      setAwards((prev) =>
        prev.map((inst) =>
          inst.awardID === selectedawardID ? updatedawards : inst
        )
      );
      setIsModalOpen(false);
    } catch (error) {
      toast.error(
        `Error updating awards with ID ${selectedawardID}:`,
        error.response.data
      );
      setErrors(error.response.data.errors || {});
      toast(
        `Failed to update awards: ${
          error.response.data.title
        }\nDetails: ${JSON.stringify(error.response.data.errors, null, 2)}`
      );
    }
  };

  const deleteExistingawards = async (awardID) => {
    try {
      await deleteAwards(awardID);
      setAwards((prev) => prev.filter((inst) => inst.awardID !== awardID));
    } catch (error) {
      toast.error(
        `Error deleting awards with ID ${awardID}:`,
        error.response.data
      );
      toast(`Failed to delete awards: ${error.response.data.title}`);
    }
  };

  const closeAddawardsModal = () => {
    setIsModalOpen(false);
    setEditMode(false);
    setErrors({});
  };

  const deleteawardsHandler = (awardID) => {
    if (
      window.confirm(
        `Are you sure you want to delete awards with ID ${awardID}?`
      )
    ) {
      deleteExistingawards(awardID);
    }
  };
  //Search
  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    filterAwards(value, selectedColumn);
  };
  const handleFilterParameterChange = (event) => {
    const value = event.target.value;
    setSelectedColumn(value);
    filterAwards(searchTerm, value);
  };

  const filterAwards = (searchTerm, column) => {
    const filtered = awards.filter((award) =>
      award[column]
        .toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
    setFilteredAwards(filtered);
  };

  return (
    <Layout>
    <div className="bg-white rounded-lg shadow-lg">
      <div className="px-6 py-4 border-b border-gray-200">
        <h1 className="text-2xl font-semibold text-gray-800">Participant Awards</h1>
      </div>
  
      <div className="p-6">
        <div className="space-y-4 md:space-y-0 md:flex md:items-center md:justify-between">
          <button
            onClick={openAddawardsModal}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-all duration-200"
          >
            <FaPlus className="mr-2 h-4 w-4" />
            Add Awards
          </button>
  
          <div className="flex flex-col md:flex-row gap-4 flex-1 md:max-w-2xl md:ml-6">
            <div className="relative flex-1">
              <select
                className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-md bg-white shadow-sm"
                value={selectedColumn}
                onChange={handleFilterParameterChange}
              >
                {awards.length > 0 ? (
                  Object.keys(awards[0])?.map((op) => (
                    <option key={op} value={op}>
                      {splitWordFunc(op)}
                    </option>
                  ))
                ) : (
                  <option>No filter property</option>
                )}
              </select>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <FaFilter className="h-4 w-4 text-gray-400" />
              </div>
            </div>
  
            <div className="relative flex-1">
              <input
                type="text"
                placeholder="Search awards..."
                onChange={handleSearchChange}
                className="block w-full pl-3 pr-10 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
              <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                <FiSearch className="h-5 w-5 text-gray-400" />
              </div>
            </div>
          </div>
        </div>
  
        <div className="mt-8">
          <AwardsTable
            awards={filteredAwards}
            openEditModal={openEditawardsModal}
            deleteawards={deleteawardsHandler}
          />
        </div>
      </div>
  
      <Modal
        isOpen={isModalOpen}
        onRequestClose={onClose}
        contentLabel={editMode ? "Edit Awards" : "Add Awards"}
        style={customStyles}
      >
        <h2 className="text-xl font-semibold text-gray-800 mb-4">
          {editMode ? "Edit Awards" : "Add Awards"}
        </h2>
        <AwardsForm
          formValues={newAwards}
          handleInputChange={handleInputChange}
          handleDateChange={handleDateChange}
          errors={errors}
        />
        <div className="flex justify-end mt-4">
          <button
            onClick={closeAddawardsModal}
            className="border border-indigo-600 px-5 text-indigo-600 py-2 rounded hover:bg-gray-100 transition-all duration-200"
          >
            Cancel
          </button>
        </div>
      </Modal>
    </div>
  </Layout>
  
  );
};

export default AddAwards;
