import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { splitWordFunc } from '../../utils/splitWordFunc';
import { FiSearch } from "react-icons/fi";
import { FaFilter } from "react-icons/fa";
import { FaPlus } from "react-icons/fa6";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import { customStyles } from "../../styles/customStyles";
import CTypeForm from "../../components/forms/awardCTypeF";
import CTypeTable from "../../components/tables/awardCTypeT";
import {
  addCType as addCTypeService,
  getCTypeById,
  updateCType,
  deleteCType,
  getCType,
} from "../../services/awardCTypeS";
import Layout from "../../components/Layout/layout";

Modal.setAppElement("#root");

const AddCType = () => {
  const [CType, setCType] = useState([]);
  const [update,setUpdate] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newCType, setNewCType] = useState({
    awardCTypeID: "",
    centerName: "",
    notes: "",
  });
  const [selectedColumn, setSelectedColumn] = useState("centerName");
  const [filteredCategories, setFilteredCategories] = useState(CType);
  const [searchTerm, setSearchTerm] = useState('');
  const [errors, setErrors] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [selectedID, setSelectedID] = useState(null);

  useEffect(() => {
    const fetchedCType = async () => {
      try {
        const fetchedCType = await getCType();
        setCType(fetchedCType);
        setFilteredCategories(fetchedCType);
      } catch (error) {
        toast.error("Error fetching CType:", error.response.data);
      }
    };

    fetchedCType();
  }, [update]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewCType((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setNewCType((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const addNewCType = async () => {
    try {
      const CTypePayload = {
        awardCTypeID: newCType.awardCTypeID,
        centerName: newCType.centerName,
        notes: newCType.notes,
      };

      
      const addedCType = await addCTypeService(CTypePayload);
      setUpdate(prev=>!prev)
      setIsModalOpen(false);
    } catch (error) {
      toast.error("Error adding CType:", error.response.data);
      setErrors(error.response.data.errors || {});
      alert(
        `Failed to add CType: ${
          error.response.data.title
        }\nDetails: ${JSON.stringify(error.response.data.errors, null, 2)}`
      );
    }
  };
  

  const openAddCTypeModal = () => {
    setEditMode(false);
    setIsModalOpen(true);
    setNewCType({
      awardCTypeID: "",
      centerName: "",
      notes: "",
    });
  };

  const openEditCTypeModal = async (awardCTypeID) => {
    try {
      const fetchedCType = await getCTypeById(String(awardCTypeID)); // Changed to lowercase
      setEditMode(true);
      setSelectedID(awardCTypeID);
      setNewCType({
        ...fetchedCType,
      });
      setIsModalOpen(true); // Move after setting state
    } catch (error) {
      toast.error(
        `Error fetching CType with ID ${awardCTypeID}:`,
        error.response.data
      );
    }
  };

  const updateExistingCType = async () => {
    try {
      const CTypePayload = { ...newCType };

      const updatedCType = await updateCType(selectedID, CTypePayload);
      const newCTypes =  await getCType()
      setCType(newCTypes);
      setIsModalOpen(false);
    } catch (error) {
      toast.error(
        `Error updating CType with ID ${selectedID}:`,
        error.response.data
      );
      setErrors(error.response.data.errors || {});
      alert(
        `Failed to update CType: ${
          error.response.data.title
        }\nDetails: ${JSON.stringify(error.response.data.errors, null, 2)}`
      );
    }
  };

  const deleteExistingCType = async (awardCTypeID) => { // Changed to lowercase
    try {
      await deleteCType(awardCTypeID);
      setCType((prev) => prev.filter((inst) => inst.awardCTypeID !== awardCTypeID));
    } catch (error) {
      toast.error(`Error deleting CType with ID ${awardCTypeID}:`, error);
      alert(`Failed to delete CType: ${error.response?.data?.title}`);
    }
  };

  const closeAddCTypeModal = () => {
    setIsModalOpen(false);
    setEditMode(false);
    setErrors({});
  };

  const deleteCTypeHandler = (AwardCTypeID) => {
    if (
      window.confirm(`Are you sure you want to delete CType with ID ${AwardCTypeID}?`)
    ) {
      deleteExistingCType(AwardCTypeID);
    }
  };

   //Search 
   const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    filterCategories(value, selectedColumn);
  };
  const handleFilterParameterChange = (event) => {
    const value = event.target.value;
    setSelectedColumn(value);
    filterCategories(searchTerm, value);
  };

  const filterCategories = (searchTerm, column) => {
    const filtered = CType.filter(category => 
      category[column].toString().toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredCategories(filtered);
  };
  


  return (
    <Layout>
  <div className="bg-white rounded-lg shadow-lg">
    <div className="px-6 py-4 border-b border-gray-200">
      <h1 className="text-2xl font-semibold text-gray-800">
        Center Types
      </h1>
    </div>

    <div className="p-6">
      <div className="space-y-4 md:space-y-0 md:flex md:items-center md:justify-between">
        <button
          onClick={openAddCTypeModal}
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-all duration-200"
        >
          <FaPlus className="mr-2 h-4 w-4" />
          Add Type
        </button>

        <div className="flex flex-col md:flex-row gap-4 flex-1 md:max-w-2xl md:ml-6">
          <div className="relative flex-1">
            <select
              className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-md bg-white shadow-sm"
              value={selectedColumn}
              onChange={handleFilterParameterChange}
            >
              {CType.length > 0 ? (
                Object.keys(CType[0])?.map((op) => (
                  <option key={op} value={op}>
                    {splitWordFunc(op)}
                  </option>
                ))
              ) : (
                <option>No filter property</option>
              )}
            </select>
            <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <FaFilter className="h-4 w-4 text-gray-400" />
            </div>
          </div>

          <div className="relative flex-1">
            <input
              type="text"
              placeholder="Search type..."
              onChange={handleSearchChange}
              className="block w-full pl-3 pr-10 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
            <div className="absolute inset-y-0 right-0 flex items-center pr-3">
              <FiSearch className="h-5 w-5 text-gray-400" />
            </div>
          </div>
        </div>
      </div>

      <div className="mt-8">
        <CTypeTable
          CType={filteredCategories}
          openEditModal={openEditCTypeModal}
          deleteCType={deleteCTypeHandler}
        />
      </div>
    </div>

    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeAddCTypeModal}
      contentLabel={editMode ? "Edit Award Center Type" : "Add Award Center Type"}
      style={customStyles}
    >
      <h2 className="text-xl font-semibold text-gray-800 mb-4">
        {editMode ? "Edit Award Center Type" : "Add Award Center Type"}
      </h2>
      <CTypeForm
        formValues={newCType}
        handleInputChange={handleInputChange}
        handleDateChange={handleDateChange}
        errors={errors}
      />
      <div className="flex justify-end mt-4">
        <button
          onClick={editMode ? updateExistingCType : addNewCType}
          className="bg-indigo-600 px-5 text-white py-2 rounded mr-2 hover:bg-indigo-700 transition-all duration-200"
        >
          {editMode ? "Update" : "Save"}
        </button>
        <button
          onClick={closeAddCTypeModal}
          className="border border-indigo-600 px-5 text-indigo-600 py-2 rounded hover:bg-gray-100 transition-all duration-200"
        >
          Cancel
        </button>
      </div>
    </Modal>
  </div>
</Layout>

  );
};

export default AddCType;