import React from 'react';
import Table from '../common/Table';
import Dropdown from "../Dropdown/dropDown"
const columns = [
 
  { Header: 'Category', accessor: 'centerName' },
  { Header: 'Notes', accessor: 'notes' },
];

const CTypeTable = ({ CType, openEditModal, deleteCType }) => {
  const renderRowActions = ({ awardCTypeID }) => (
    <div className='z-50'>
    <Dropdown  onEdit={()=>openEditModal(awardCTypeID)} onDelete={()=>deleteCType(awardCTypeID)} />
  </div>
  );

  return (
    <Table
      columns={columns}
      data={CType}
      renderRowActions={renderRowActions}
    />
  );
};

export default CTypeTable;
