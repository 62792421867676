import apiClient from './apiClient';
import { logAction } from '../utils/logger';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 

const addLevels = async (levelData) => {
  try {
    const response = await apiClient.post('/api/ParticipantLevel', levelData);
    if(response){
      logAction("Created Levels","Levels",`Created the following Levels ${JSON.stringify(response.data)}`)
    }
    return response.data;
  } catch (error) {
    toast.error('Error in addLevels:', error.response.data);
    throw error;
  }
};

const getLevelsById = async (levelId) => {
  try {
    const response = await apiClient.get(`/api/ParticipantLevel/${levelId}`);
    return response.data;
  } catch (error) {
    toast.error(`Error fetching institution with ID ${levelId}:`, error.response.data);
    throw error;
  }
};

const updateLevels = async (levelId, updatedData) => {
  try {
    const response = await apiClient.put(`/api/ParticipantLevel/${levelId}`, updatedData);
    if(response){
      logAction("Updated Levels","Levels",`Updated the following Levels ${JSON.stringify(response.data)}`)
    }
    return response.data;
  } catch (error) {
    toast.error(`Error updating institution with ID ${levelId}:`, error.response.data);
    throw error;
  }
};

const deleteLevels = async (levelId) => {
  try {
    const response = await apiClient.delete(`/api/ParticipantLevel/${levelId}`);
    if(response){
      logAction("Deleted Levels","Levels",`Deleted the following Levels ${JSON.stringify(response.data)}`)
    }
  } catch (error) {
    toast.error(`Error deleting institution with ID ${levelId}:`, error.response.data);
    throw error;
  }
};

const getLevels = async () => {
  try {
    const response = await apiClient.get('/api/ParticipantLevel');
    return response.data;
  } catch (error) {
    toast.error('Error fetching institutions:', error.response.data);
    throw error;
  }
};

export { addLevels, getLevelsById, updateLevels, deleteLevels, getLevels };
