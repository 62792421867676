import React from 'react';
import Table from '../common/Table';
import Dropdown from "../Dropdown/dropDown"

const columns = [
  { Header: 'Partner No', accessor: 'partnerID' },
  { Header: 'Partner Name', accessor: 'partnerName' },
  { Header: 'Email', accessor: 'partnerEmail' },
  { Header: 'Phone Number', accessor: 'phoneNo' },
  { Header: 'Partner Type', accessor: 'partnerType' },
];

const PartnershipTable = ({ partnerships, openEditModal, deletePartnership }) => {
  const renderRowActions = ({ partnerID }) => (
    <div className='z-50'>
    <Dropdown  onEdit={()=>openEditModal(partnerID)} onDelete={()=>deletePartnership(partnerID)} />
  </div>
  );

  return (
    <Table
      columns={columns}
      data={partnerships}
      renderRowActions={renderRowActions}
    />
  );
};

export default PartnershipTable;
