import React, { useState, useEffect } from 'react';
import Table from '../common/Table';
import Dropdown from "../Dropdown/dropDown"

const columns = [
  { Header: 'Center No', accessor: 'institutionID' },
  { Header: 'Center Name', accessor: 'institutionName' },
  { Header: 'Stage', accessor: 'stage' },
  { Header: 'Status', accessor: 'status' },
  { Header: 'Email', accessor: 'institutionEmail' },
  { Header: 'Contact', accessor: 'institutionContact' },
  { Header: 'Region', accessor: 'region'  },
  { Header: 'County', accessor: 'county'  },
  { Header: 'Sub-County', accessor: 'subCounty' },
  { Header: 'Contact Person', accessor: 'contactPerson' },
  { Header: 'Contact Number', accessor: 'contactNumber' },
  { Header: 'License Enddate', accessor: 'licenseEndDate' },
  { Header: 'Centre Type', accessor: 'awardCType' },
  { Header: 'Source', accessor: 'source' },
  { Header: 'Marginalised', accessor: 'marginalised' },
];

const InstitutionTable = ({ institutions, openEditModal, openViewModal, deleteInstitution }) => {

  

  const renderRowActions = ({ institutionID }) => (
    <div className='z-30'>
      <Dropdown
        onEdit={() => openEditModal(institutionID)}
        onDelete={() => deleteInstitution(institutionID)}
        onView={() => openViewModal(institutionID)}
      />
    </div>
  );

  return (
    <Table
      columns={columns}
      data={institutions} // Use filtered institutions based on the user's role
      renderRowActions={renderRowActions}
      keyField="institutionID" // Ensure you have a unique key for each row
    />
  );
};



export default InstitutionTable;