import Modal from "react-modal";
import { getPermisionById, deletePermission } from "../../../../../services/permissionsS";
import { smallFormModal } from "../../../../../styles/smallFormModal";
import { useEffect,useState } from "react";
const Delete = ({ deleteModal, closeDelete,update }) => {
    const [permission, SetPermission] = useState({});


  useEffect(() => {
    getSinglePermission();
  }, [deleteModal]);

  const getSinglePermission = async () => {
    const fetchedPermission = await getPermisionById(deleteModal);
    SetPermission(fetchedPermission);
  };

 const deletePermissions = async()=>{
    const logs = await deletePermission(deleteModal)
    console.log(logs)
    closeDelete()
    update()
 }

  return (
    <>
      <Modal
        style={smallFormModal}
        isOpen={deleteModal}
        onRequestClose={closeDelete}
        contentLabel={"Add User"}>
        <h2 className="subtitle2 mb-4">Are you sure yo want to delete Permission; <span className="text-primary">{permission?.name}</span> </h2>
        <div className="flex justify-end mt-4">
            <button
            onClick={deletePermissions}
              className="bg-primary px-5 text-white p-2 rounded mr-2">
              Delete
            </button>
            <button
              onClick={closeDelete}
              className="outline outline-1 outline-primary text-primary px-5 p-2 rounded">
              Cancel
            </button>
          </div>
      </Modal>
    </>
  );
};

export default Delete;
