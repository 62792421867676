import RolesT from "../../../../components/tables/rolesT";
import Modal from "react-modal";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { addRole } from "../../../../services/rolesS";
import Edit from "./Edit";
import Delete from "./Delete";
import { smallFormModal } from "../../../../styles/smallFormModal";
import InputContainer from "../../../../components/surfaces/InputContainer";
import { useState } from "react";

const Roles = ({ roles, deleteRole, update }) => {
  const [modalState, setModalState] = useState(false);
  const [editModal, setEditModal] = useState("");
  const [deleteModal, setDeleteModal] = useState("");

  const closeModal = () => {
    setModalState(false);
  };
  
  const openModal = () => {
    setModalState(true);
  };
  
  const openEditModal = (id) => {
    setEditModal(id);
  };
  
  const openDeleteModal = (id) => {
    setDeleteModal(id);
  };
  
  const closeEdit = () => {
    setEditModal("");
  };
  
  const closeDelete = () => {
    setDeleteModal("");
  };

  const schema = yup.object({
    name: yup.string().required("Role name is required"),
  });

  const {
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const inputConfig = [{
    disabled: false,
    type: "text",
    element: "input",
    label: "Role Name",
    name: "name",
    placeholder: "Enter Role Name",
  }];

  const onSubmit = async (data) => {
    await addRole(data);
    update();
    closeModal();
  };

  return (
    <>
      <button onClick={openModal} className="border border-blue-500 text-blue-500 hover:bg-blue-500 hover:text-white font-bold py-2 px-4 rounded m-2">
        Add Role
      </button>
      <RolesT roles={roles} openEditModal={openEditModal} deleteRole={openDeleteModal} />
      
      <Modal
        style={smallFormModal}
        isOpen={modalState}
        onRequestClose={closeModal}
        contentLabel={"Add Role"}>
        <h2 className="subtitle2 mb-4">Add Role</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          {inputConfig.map((field, index) => (
            <InputContainer
              disabled={field.disabled}
              error={errors[field.name]?.message}
              name={field.name}
              key={index}
              label={field.label}
              setValue={setValue}
              placeholder={field.placeholder}
              type={field.type}
            />
          ))}
          <div className="flex justify-end mt-4">
            <button type="submit" className="bg-primary px-5 text-white p-2 rounded mr-2">
              Save
            </button>
            <button onClick={closeModal} className="outline outline-1 outline-primary text-primary px-5 p-2 rounded">
              Cancel
            </button>
          </div>
        </form>
      </Modal>
      
      <Edit editModal={editModal} update={update} closeEdit={closeEdit} />
      <Delete deleteModal={deleteModal} update={update} closeDelete={closeDelete} />
    </>
  );
};

export default Roles;
