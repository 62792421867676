import React from 'react';
import Table from '../common/Table';
import Dropdown from "../Dropdown/dropDown"

const columns = [
  { Header: 'Respondent', accessor: 'respondentID' },
  { Header: 'Title', accessor: 'titleName' },
  { Header: 'Description', accessor: 'description' },
  { Header: 'Type', accessor: 'respondentType' },
  { Header: 'Phone Number', accessor: 'phoneNo' },
  { Header: 'Email', accessor: 'respondentEmail' },
];

const FeedbackTable = ({ feedback, openEditModal, deleteFeedback }) => {
  const renderRowActions = ({ respondentID }) => (
    <div className='z-50'>
    <Dropdown  onEdit={()=>openEditModal(respondentID)} onDelete={()=>deleteFeedback(respondentID)} />
  </div>
  );

  return (
    <Table
      columns={columns}
      data={feedback}
      renderRowActions={renderRowActions}
    />
  );
};

export default FeedbackTable;
