import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 

const GeneralSettings = () => {
  const [settings, setSettings] = useState({
    logo: "",
    profile: "",
    legalName: "",
    address: "",
    email: "",
    taxNo: "",
    contact: "",
    footer: "",
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/Settings`
      );
      setSettings(response.data[0]);
      setLoading(false);
    } catch (error) {
      setError("Failed to fetch company settings");
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    setSettings({ ...settings, [e.target.name]: e.target.value });
  };
  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setSettings((prevData) => ({
      ...prevData,
      [name]: files[0], // assuming you want to handle single file uploads
    }));
  }

  const handleSettingsUpdate = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("LegalName", settings.legalName);
    formData.append("Address", settings.address);
    formData.append("Email", settings.email);
    formData.append("TaxNo", settings.taxNo);
    formData.append("Contact", settings.contact);
    formData.append("Footer", settings.footer);
    formData.append("Logo", settings.logo);
    formData.append("Profile", settings.profile);
    
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/Settings/${settings.id}`, {
        method: "PUT",
        body: formData // Sending the formData with both participant object and files
      });
      const responseData = await response.json();

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
      alert("Settings updated successfully");
    } catch (error) {
      toast.error("Error updating settings", error);
    }
  };

  return (
    <div className="w-full max-w-4xl mx-auto">
      <h2 className="text-2xl font-Satoshi font-semibold text-white  bg-primary p-4 mb-6 rounded-lg">
        General Setting
      </h2>

      <div className="max-w-6xl mx-auto my-12 p-6 bg-white shadow-lg rounded-lg">
        {/* Display loading indicator */}
        {loading && <p>Loading settings...</p>}

        {/* Display error message */}
        {error && <p className="text-red-500">{error}</p>}

        <form
          onSubmit={handleSettingsUpdate}
          className="space-y-4 grid grid-cols-1 md:grid-cols-2 gap-4">
          {/* Logo Upload */}
          <div className="flex flex-col justify-end gap-3">
          <div className="flex flex-col">
          <img src={process.env.REACT_APP_API_BASE_URL + settings.logo}className="w-auto max-h-52 rounded-[5]" />
          <br />
           <div className="flex flex-col gap-3 col-span-3">
           <label className="block text-gray-600">Upload Logo:</label>
            <input
              type="file"
              name="logo"
              accept="image/*"
              onChange={handleFileChange}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
           </div>
            
          </div>
          </div>
          <div className="flex flex-col justify-end gap-3">
            <label className="block text-gray-600">Profile:</label>
            <input
             type="text"
              name="profile"
              value={settings.profile}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          {/* Legal Name */}
          <div className="flex flex-col justify-end gap-3">
            <label className="block text-gray-600">Legal Name:</label>
            <input
              type="text"
              name="legalName"
              onChange={handleChange}
              value={settings.legalName}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          {/* Address */}
          <div className="flex flex-col justify-end gap-3">
            <label className="block text-gray-600">Address:</label>
            <input
              type="text"
              name="address"
              onChange={handleChange}
              value={settings.address}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          {/* Email */}
          <div className="flex flex-col justify-end gap-3">
            <label className="block text-gray-600">Email:</label>
            <input
              type="email"
              name="email"
              onChange={handleChange}
              value={settings.email}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          {/* Tax Number */}
          <div className="flex flex-col justify-end gap-3">
            <label className="block text-gray-600">Tax No:</label>
            <input
              type="text"
              name="taxNo"
              onChange={handleChange}
              value={settings.taxNo}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          {/* Contact */}
          <div className="flex flex-col justify-end gap-3">
            <label className="block text-gray-600">Contact:</label>
            <input
              type="text"
              name="contact"
              onChange={handleChange}
              value={settings.contact}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          {/* Footer */}
          <div className="flex flex-col justify-end gap-3">
            <label className="block text-gray-600">Footer:</label>
            <textarea
              name="footer"
              rows="3"
              onChange={handleChange}
              value={settings.footer}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          {/* Submit Button */}
          <div className="w-full px-4 py-2  justify-end align-left ">
            <button
              type="submit"
              className="mt-6 bg-indigo-600 text-white py-2 px-4 rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500">
              Update Settings
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default GeneralSettings;
