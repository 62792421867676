import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout/layout";
import { formatPDFReport } from "../../utils/pdfFormatting";
import { formatExcelReport } from "../../utils/excelFormatting";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import {
  fetchProjectData,
  projectHeaders,
} from "../../services/reportsServices/reportProjectsS";
import {
  Printer,
  FileSpreadsheet,
  FileText,
  RefreshCw,
} from "lucide-react";

const ProjectsR = () => {
  const [selectedReport, setSelectedReport] = useState("");
  const [reportData, setReportData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [uniqueOptions, setUniqueOptions] = useState({});
  const [filters, setFilters] = useState({
    projectID: "",
    projectName: "",
    institutionName: "",
    subCounty: "",
    county: "",
    dateRange: ["", ""],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (selectedReport === "Projects") {
      fetchData();
    }
  }, [selectedReport]);

  const fetchData = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const data = await fetchProjectData();
      setReportData(data);
      setFilteredData(data);
      extractUniqueOptions(data);
    } catch (error) {
      setError("Error fetching data. Please try again.");
      toast.error("Error fetching Projects data:", error);
    }
    setIsLoading(false);
  };

  const extractUniqueOptions = (data) => {
    const options = {};
    const filterFields = ["projectID", "projectName", "institutionName", "subCounty", "county"];

    filterFields.forEach((field) => {
      options[field] = [...new Set(data.map((item) => item[field]))].filter(
        (option) => option !== undefined && option !== null && option !== ""
      );
    });
    setUniqueOptions(options);
  };

  useEffect(() => {
    applyFilters();
  }, [filters, reportData]);

  const applyFilters = () => {
    let filtered = reportData;

    Object.entries(filters).forEach(([key, value]) => {
      if (value) {
        if (key === "dateRange") {
          const [startDate, endDate] = value;
          if (startDate && endDate) {
            filtered = filtered.filter((item) => {
              const itemStartDate = new Date(item.startDate);
              const itemEndDate = new Date(item.endDate);
              return (
                itemStartDate >= new Date(startDate) &&
                itemEndDate <= new Date(endDate)
              );
            });
          }
        } else {
          filtered = filtered.filter((item) => {
            if (typeof item[key] === "string") {
              return item[key].toLowerCase() === value.toLowerCase();
            }
            return item[key] === value;
          });
        }
      }
    });

    setFilteredData(filtered);
  };

  const handleExport = async (format) => {
    if (filteredData.length === 0) {
      alert("No data available for export.");
      return;
    }

    const headers = projectHeaders;

    if (format === "PDF") {
      try {
        const pdfBlob = await formatPDFReport("Projects", filteredData, headers);
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl, "_blank");
      } catch (error) {
        toast.error("Error exporting to PDF:", error);
        alert("Failed to export to PDF. Please try again.");
      }
    } else if (format === "Excel") {
      try {
        await formatExcelReport("Projects", filteredData, headers);
      } catch (error) {
        toast.error("Error exporting to Excel:", error);
        alert("Failed to export to Excel. Please try again.");
      }
    }
  };

  const handlePrint = async () => {
    if (filteredData.length === 0) {
      alert("No data available to print.");
      return;
    }

    try {
      const headers = projectHeaders;
      const pdfBlob = await formatPDFReport("Projects", filteredData, headers);

      const pdfUrl = URL.createObjectURL(pdfBlob);
      window.open(pdfUrl, "_blank");

      const printFrame = document.createElement("iframe");
      printFrame.style.display = "none";
      document.body.appendChild(printFrame);

      printFrame.src = pdfUrl;

      printFrame.onload = () => {
        try {
          printFrame.contentWindow.print();
        } catch (error) {
          toast.error("Printing failed:", error);
          alert(
            "Printing failed. Please try again or use the browser print function from the preview tab."
          );
        }

        setTimeout(() => {
          document.body.removeChild(printFrame);
          URL.revokeObjectURL(pdfUrl);
        }, 1000);
      };
    } catch (error) {
      toast.error("Error during print:", error);
      alert("An error occurred while trying to print. Please try again.");
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  const handleDateRangeChange = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({
      ...prev,
      dateRange:
        name === "startDate"
          ? [value, prev.dateRange[1]]
          : [prev.dateRange[0], value],
    }));
  };

  const clearFilters = () => {
    setFilters({
      projectID: "",
      projectName: "",
      institutionName: "",
      subCounty: "",
      county: "",
      dateRange: ["", ""],
    });
  };

  const renderFilters = () => (
    <div className="grid grid-cols-4 gap-4 mb-4">
      {["projectID", "projectName", "institutionName", "subCounty", "county"].map((field) => (
        <div key={field}>
          <label
            className="block text-gray-700 text-sm font-bold mb-1"
            htmlFor={field}
          >
            {field === "projectID"
              ? "Project ID"
              : field === "projectName"
              ? "Project Name"
              : field.charAt(0).toUpperCase() + field.slice(1)}
          </label>
          <select
            name={field}
            id={field}
            value={filters[field]}
            onChange={handleFilterChange}
            className="w-full px-2 py-1 text-sm border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-200"
          >
            <option value="">All</option>
            {uniqueOptions[field]?.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
      ))}
      <div>
        <label
          className="block text-gray-700 text-sm font-bold mb-1"
          htmlFor="startDate"
        >
          Start Date (From)
        </label>
        <input
          type="date"
          name="startDate"
          id="startDate"
          value={filters.dateRange[0]}
          onChange={handleDateRangeChange}
          className="w-full px-2 py-1 text-sm border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-200"
        />
      </div>
      <div>
        <label
          className="block text-gray-700 text-sm font-bold mb-1"
          htmlFor="endDate"
        >
          End Date (To)
        </label>
        <input
          type="date"
          name="endDate"
          id="endDate"
          value={filters.dateRange[1]}
          onChange={handleDateRangeChange}
          className="w-full px-2 py-1 text-sm border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-200"
        />
      </div>
    </div>
  );

  return (
    <Layout>
      <div className="reports-page p-4">
        <h1 className="text-3xl font-bold mb-6">Reports</h1>

        <div className="bg-white shadow-md rounded-lg p-4 mb-6">
          <h2 className="text-xl font-bold mb-4 border-b-2 border-blue-300 pb-2">
            Available Reports
          </h2>
          <ul className="space-y-2">
            <li>
              <button
                onClick={() => setSelectedReport("Projects")}
                className={`w-full text-left p-2 flex items-center ${
                  selectedReport === "Projects" ? "bg-gray-200" : "bg-white"
                } hover:bg-gray-100`}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/assets/checked.png`}
                  alt="Checkmark"
                  className={`w-6 h-6 ${
                    selectedReport === "Projects" ? "block" : "hidden"
                  } mr-3`}
                />
                <span
                  className={`font-semibold ${
                    selectedReport === "Projects"
                      ? "text-blue-600"
                      : "text-gray-800"
                  }`}
                >
                  Projects 
                </span>
              </button>
            </li>
          </ul>
        </div>

        {selectedReport === "Projects" && (
          <div className="bg-white shadow-md rounded-lg p-4 mb-6">
            <h2 className="text-xl font-bold mb-4">Projects Report</h2>
            {isLoading ? (
              <p>Loading data...</p>
            ) : error ? (
              <p className="text-red-500">{error}</p>
            ) : (
              <>
                <div className="flex flex-col sm:flex-row justify-between items-center mb-4">
                  <h3 className="text-lg font-semibold mb-2 sm:mb-0">
                    Filters
                  </h3>
                  <div className="flex flex-wrap justify-center sm:justify-end gap-2">
                    <button
                      onClick={clearFilters}
                      className="bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400 flex items-center"
                    >
                      <RefreshCw className="w-4 h-4 mr-2" /> Clear Filters
                    </button>
                    <button
                      onClick={() => handleExport("PDF")}
                      className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700 flex items-center"
                    >
                      <FileText className="w-4 h-4 mr-2" /> Export to PDF
                    </button>
                    <button
                      onClick={() => handleExport("Excel")}
                      className="bg-green-600 text-white px-4 py-2 rounded hover:bg-green-700 flex items-center"
                    >
                      <FileSpreadsheet className="w-4 h-4 mr-2" /> Export to
                      Excel
                    </button>
                    <button
                      onClick={handlePrint}
                      className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 flex items-center"
                    >
                      <Printer className="w-4 h-4 mr-2" /> Print
                    </button>
                  </div>
                </div>
                {renderFilters()}
                <div className="overflow-x-auto">
                  <h3 className="text-lg font-bold mb-2">Filtered Results</h3>
                  <p className="text-red-500 font-semibold text-2xl">
                    Total Records: {filteredData.length}
                  </p>
                  <div className="max-h-96 overflow-y-auto">
                    <table className="w-full bg-white border border-gray-300">
                      <thead className="sticky top-0 bg-white">
                        <tr>
                          {projectHeaders.map((header, index) => (
                            <th key={index} className="py-2 px-4 border-b">
                              {header.Header}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {filteredData.map((item, index) => (
                          <tr key={index}>
                            {projectHeaders.map((header, idx) => (
                              <td
                                key={idx}
                                className="py-2 px-4 border-b whitespace-nowrap"
                              >
                                {item[header.accessor]}
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </Layout>
  );
};

export default ProjectsR;