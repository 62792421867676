import React from 'react';
import Table from '../common/Table';
 import Dropdown from '../Dropdown/dropDown';

// Define the columns for the table. The accessor property should match the key in your data array.
const columns = [
  { Header: 'Donor ID', accessor: 'donorID' },
  { Header: 'Donor Name', accessor: 'donorName' },
  { Header: 'Contact', accessor: 'contact' },
  { Header: 'Notes', accessor: 'notes' },
];

const DonorTable = ({ Donors, openEditModal, deleteDonor }) => {
    const renderRowActions = ({ DonorID }) => (
        <div className='z-50'>
        <Dropdown onEdit={()=>openEditModal({DonorID})} onDelete={()=>deleteDonor({DonorID})} />
      </div>
    );

    // Conditional rendering to handle undefined or empty Donor array
    if (!Donors) {
        return <div>Loading...</div>; // or handle loading state
    }

    return (
        <Table
            columns={columns}
            data={Donors}
            renderRowActions={renderRowActions}
        />
    );
};


export default DonorTable;
