import apiClient from './apiClient';
import { logAction } from '../utils/logger';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 

const isFormData = (data) => data instanceof FormData;

const handleError = (error, operation) => {
  const errorMessage = error.response?.data || error.message;
  toast.error(`Error in ${operation}:`, errorMessage);
  throw error;
};

const addAjApproval = async (AjApprovalData) => {
  try {
    // Don't set Content-Type for FormData - browser will handle it
    const config = isFormData(AjApprovalData) ? {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    } : {};

    const response = await apiClient.post('/api/ActivityApproval', AjApprovalData, config);
    
    if (response?.data) {
      // Don't stringify FormData - it's not serializable
      const logData = isFormData(AjApprovalData) 
        ? 'Form Data (including files)'
        : JSON.stringify(response.data);
        
      logAction(
        "Created AJ Approval",
        "AJ Approval",
        `Created the following AJ Approval: ${logData}`
      );
      return response.data;
    }
    return null;
  } catch (error) {
    handleError(error, 'addAjApproval');
  }
};

const getAjApprovalById = async (ID) => {
  try {
    const response = await apiClient.get(`/api/ActivityApproval/${ID}`);
    return response.data;
  } catch (error) {
    toast.error(`Error fetching AjApproval with ID ${ID}:`, error.response.data);
    throw error;
  }
};

const updateAjApproval = async (ID, updatedData) => {
  try {
    const response = await apiClient.put(`/api/ActivityApproval/${ID}`, updatedData);
    if(response){
      logAction("Updated AJ Approval","AJ Approval",`Updated the following Aj Approval ${JSON.stringify(response.data)}`)
    }
    return response.data;
  } catch (error) {
    toast.error(`Error updating AjApproval with ID ${ID}:`, error.response.data);
    throw error;
  }
};

const deleteAjApproval = async (ID) => {
  try {
   const response= await apiClient.delete(`/api/ActivityApproval/${ID}`); 
   if(response){
      logAction("Deleted AJ","AJ",`Deleted the following AjApproval ${JSON.stringify(response.data)}`)
    }
  } catch (error) {
    toast.error(`Error deleting AjApproval with ID ${ID}:`, error.response.data);
    throw error;
  }
};

const getAjApproval = async () => {
  try {
    const response = await apiClient.get('/api/ActivityApproval');
    return response?.data || [];
  } catch (error) {
    handleError(error, 'getAjApproval');
  }
};


export { 
  addAjApproval, 
  getAjApprovalById, 
  updateAjApproval, 
  deleteAjApproval, 
  getAjApproval 
};
