import apiClient from './apiClient';
import { logAction } from '../utils/logger';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 

const addFundingType = async (fundingtypeData) => {
  try {
    const response = await apiClient.post('/api/FundingType', fundingtypeData);
    if(response){
      logAction("Created Funding Type","Funding Type",`Created the following Funding Type ${JSON.stringify(response.data)}`)
    }
    return response.data;
  } catch (error) {
    toast.error('Error in addFundingType:', error.response.data);
    throw error;
  }
};

const getFundingTypeById = async (fundingID) => {
  try {
    const response = await apiClient.get(`/api/FundingType/${fundingID}`);
    return response.data;
  } catch (error) {
    toast.error(`Error fetching fundingtype with ID ${fundingID}:`, error.response.data);
    throw error;
  }
};

const updateFundingType = async (fundingID, updatedData) => {
  try {
    const response = await apiClient.put(`/api/FundingType/${fundingID}`, updatedData);
    if(response){
      logAction("Updated Funding Type","Funding Type",`Updated the following Funding Type ${JSON.stringify(response.data)}`)
    }
    return response.data;
  } catch (error) {
    toast.error(`Error updating fundingtype with ID ${fundingID}:`, error.response.data);
    throw error;
  }
};

const deleteFundingType = async (fundingID) => {
  try {
    const response = await apiClient.delete(`/api/FundingType/${fundingID}`);
    if(response){
      logAction("Deleted Funding Type","Funding Type",`Deleted the following Funding Type ${JSON.stringify(response.data)}`)
    }
  } catch (error) {
    toast.error(`Error deleting fundingtype with ID ${fundingID}:`, error.response.data);
    throw error;
  }
};

const getFundingType = async () => {
  try {
    const response = await apiClient.get('/api/FundingType');
    return response.data;
  } catch (error) {
    toast.error('Error fetching fundingtypes:', error.response.data);
    throw error;
  }
};

export { addFundingType, getFundingTypeById, updateFundingType, deleteFundingType, getFundingType };
