import React, { useState, useEffect } from "react";
import Input from "../common/Input";
import styles from "../../styles/modal.module.css";
import { getInstitutions } from "../../services/awardCenterS";
import { getCounty, getSubCounty } from "../../services/countiesS";
import { getUsersByUsername } from '../../services/usersS';

const ParticipantForm = ({
  formValues,
  handleFileChange,
  handleInputChange,
  handleDateChange,
  errors,
}) => {
  const [institutions, setInstitutions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [counties, setCounties] = useState([]);
  const [subCounties, setSubCounties] = useState([]);
 const [ageError,setAgeError] = useState("")
 const [dobError,setDobError] = useState("")

  // Fetch Institutions in alphabetical order
useEffect(() => {
    const fetchData = async () => {
      const loggedInUser = JSON.parse(localStorage.getItem("user"))?.user;
      
      try {
        if (loggedInUser?.role.name === "Award Leader" || loggedInUser?.role.name === "Adult Helper") {
          // Fetch only the institution of the logged-in Award Leader
          const userData = await getUsersByUsername(loggedInUser.username);
          
          if (userData?.awardCenter?.institutionName) {
            // Set only the Award Leader's institution
            setInstitutions([userData.awardCenter]); // Wrap in an array for consistency
          } else {
            console.warn("No institution found for the logged-in user");
          }
        } else {
          // Fetch all institutions for other roles
          const fetchedInstitutions = await getInstitutions();
          const sortedInstitutions = fetchedInstitutions.sort((a, b) =>
            a.institutionName.localeCompare(b.institutionName)
          );
          setInstitutions(sortedInstitutions);
        }
      } catch (error) {
        console.error("Error fetching institutions:", error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, []);

  useEffect(() => {
    const fetchCounties = async () => {
      try {
        const countyData = await getCounty();
        setCounties(countyData);
      } catch (error) {
        console.error("Error fetching counties:", error);
      }
    };
    fetchCounties();
  }, []);

  const handleCountyChange = async (event) => {
    const county = event.target.value;
    const countyName = counties.find((c) => c.county === county)?.county || "";
    console.log("Selected County ID:", county); // Log the countyID for debugging

    // Update form values with selected county ID and county name
    handleInputChange({ target: { name: "county", value: county } });
    handleInputChange({ target: { name: "countyID", value: county } });
    if (county) {
      try {
        const subCountyData = await getSubCounty(county);
        setSubCounties(subCountyData);
        console.log("Sub-counties fetched:", subCountyData);
      } catch (error) {
        console.error("Error fetching sub-counties:", error);
        setSubCounties([]); // Clear sub-counties in case of an error
      }
    } else {
      setSubCounties([]);
    }
  };
  const checkAge = (age)=>{
    if (age > 23){
    return  setAgeError(prev=>"Age at registration must be below 23 years")
    }
    if (age < 14){
      return  setAgeError("Age at registration must be above 14 years")
    }
    return setAgeError("")
  }

  const blurDOB = (e)=>{
    const targetDate = new Date(e.target.value);
    // Get today's date
    const today = new Date();
  
    // Calculate the difference in milliseconds
    const differenceInMilliseconds =   today - targetDate;
    
   // Convert milliseconds to years
const differenceInYears = differenceInMilliseconds / (1000 * 60 * 60 * 24 * 365.25); // Using 365.25 to account for leap years

switch (true) {
  case (differenceInYears < 14):
    setDobError("Participant is too early to join.");
    break;
  case (differenceInYears > 23):
    setDobError("Participant is too late to join.");
    break;
  default:
    setDobError("");
    break;
}


  }
  return (
    <form className={styles.form}>
      <div className="grid grid-cols-1 gap-2  md:grid-cols-2">
        {/* <div>
          <label htmlFor="adminNumber">Admin Number:</label>
          <Input
            name="adminNumber"
            placeholder="Admin Number"
            value={formValues.adminNumber}
            onChange={handleInputChange}
            disabled
          />
          {errors.adminNumber && <p className="text-red-500">{errors.adminNumber[0]}</p>}
        </div> */}
        <div>
          <label htmlFor="name">Name:</label>
          <Input
            name="name"
            placeholder="Name"
            value={formValues.name}
            onChange={handleInputChange}
          />
          {errors.name && <p className="text-red-500">{errors.name[0]}</p>}
        </div>
        <div>
          <label htmlFor="dob">Date of Birth:</label>
          <Input
            name="dob"
            placeholder="Date of Birth"
            type="date"
            value={formValues.dob}
            onChange={handleDateChange}
            onBlur={(e)=>blurDOB(e)}
          />
          {dobError && <p className="text-red-500">{dobError}</p>}
        </div>
        <div>
          <label htmlFor="gender">Gender:</label>
          <select
            className="w-full p-2 border border-gray-300 rounded"
            name="gender"
            value={formValues.gender}
            onChange={handleInputChange}>
            <option value="">Select Gender</option>
            <option value="Rather Not Say">Rather Not Say</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Other">Other</option>
          </select>
          {errors.gender && <p className="text-red-500">{errors.gender[0]}</p>}
        </div>

        <div>
          <label htmlFor="age">Age at registration:</label>
          <Input
          type="number"
            name="age"
            placeholder="Age at registration"
            value={formValues.age}
            onChange={handleInputChange}
            onBlur={(e)=>checkAge(e.target.value)}
            // min="14" // Minimum age is 14
            // max="23" // Maximum age is 23
          />
          {ageError && <p className="text-red-500">{ageError}</p>}
        </div>

        <div>
          <label htmlFor="religion">Religion:</label>
          <select
            name="religion"
            value={formValues.religion}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded">
            <option value="">Select Religion</option>
            <option value="Christianity">Christianity</option>
            <option value="Islam">Islam</option>
            <option value="Hinduism">Hinduism</option>
            <option value="Buddhism">Buddhism</option>
            <option value="None">None</option>
            <option value="Other">Other</option>
          </select>
          {errors.religion && (
            <p className="text-red-500">{errors.religion[0]}</p>
          )}
        </div>

        <div>
          <label htmlFor="ethnicity">Ethnicity:</label>
          <select
            name="ethnicity"
            value={formValues.ethnicity}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded">
            <option value="">Select Ethnicity</option>{" "}
            <option value="None">None</option>{" "}
            <option value="Banyala">Banyala</option>{" "}
            <option value="Banyole">Banyole</option>{" "}
            <option value="Bajuni">Bajuni </option>
            <option value="Burji tribe">Burji tribe</option>{" "}
            <option value="Bukusu">Bukusu</option>{" "}
            <option value="Boorana">Boorana</option>{" "}
            <option value="Dorobo">Dorobo</option>{" "}
            <option value="Embu">Embu</option>
            <option value="El Molo ">El Molo</option>{" "}
            <option value="Gabra ">Gabra</option>{" "}
            <option value="Giriama ">Giriama</option>{" "}
            <option value="Idakho">Idakho</option>{" "}
            <option value="Ilchamus ">Ilchamus </option>
            <option value="Indians in Kenya">Indians in Kenya</option>{" "}
            <option value="Kalenjin ">Kalenjin</option>{" "}
            <option value="Kamba ">Kamba</option>{" "}
            <option value="Kikuyu ">Kikuyu</option>
            <option value="Kipsigis ">Kipsigis </option>{" "}
            <option value="Kisii ">Kisii </option>{" "}
            <option value="Konso ">Konso </option>{" "}
            <option value="Kuria ">Kuria </option>{" "}
            <option value="Luhya ">Luhya </option>
            <option value="Maasai ">Maasai </option>{" "}
            <option value="Maragoli">Maragoli</option>{" "}
            <option value="Mbeere ">Mbeere </option>{" "}
            <option value="Mijikenda ">Mijikenda </option>{" "}
            <option value="Nandi ">Nandi </option>
            <option value="Nilotic ">Nilotic </option>{" "}
            <option value="Oromo ">Oromo </option>{" "}
            <option value="Pokemo ">Pokemo </option>{" "}
            <option value="Pokot ">Pokot </option>{" "}
            <option value="Rendille ">Rendille </option>
            <option value="Sabaot ">Sabaot </option>{" "}
            <option value="Samburu ">Samburu </option>{" "}
            <option value="Sengwer ">Sengwer </option>{" "}
            <option value="Somali ">Somali </option>{" "}
            <option value="Sakuye ">Sakuye </option>
            <option value="Taita ">Taita </option>{" "}
            <option value="Terik ">Terik </option>{" "}
            <option value="Teso ">Teso </option>{" "}
            <option value="Tugen ">Tugen </option>{" "}
            <option value="Turkana ">Turkana </option>
            <option value="Other">Other</option>
          </select>
          {errors.ethnicity && (
            <p className="text-red-500">{errors.ethnicity[0]}</p>
          )}
        </div>

        <div>
          <label htmlFor="Nationality">Nationality:</label>
          <Input
            name="nationality"
            placeholder="Nationality"
            value={formValues.nationality}
            onChange={handleInputChange}
          />
          {errors.nationality && (
            <p className="text-red-500">{errors.nationality[0]}</p>
          )}
        </div>

        <div>
          <label htmlFor="phoneNumber">Phone Number:</label>
          <Input
            name="phoneNumber"
            placeholder="Phone Number"
            value={formValues.phoneNumber}
            onChange={handleInputChange}
          />
          {errors.phoneNumber && (
            <p className="text-red-500">{errors.phoneNumber[0]}</p>
          )}
        </div>
        <div>
          <label htmlFor="email">Email:</label>
          <Input
            name="email"
            placeholder="Email"
            value={formValues.email}
            onChange={handleInputChange}
          />
          {errors.email && <p className="text-red-500">{errors.email[0]}</p>}
        </div>
        <div>
          <label htmlFor="institutionID">Institution:</label>
          {loading ? (
            <p>Loading institutions...</p>
          ) : (
            <select
              name="institutionName"
              value={formValues.institutionName}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded">
              <option value="">Select Institution</option>
              {institutions.map((institution) => (
                <option
                  key={institution.institutionName}
                  value={institution.institutionName}>
                  {institution.institutionName}
                </option>
              ))}
            </select>
          )}
          {errors.institutionID && (
            <p className="text-red-500">{errors.institutionID[0]}</p>
          )}
        </div>

        <div>
          <label htmlFor="region">Region:</label>
          <select
            className="w-full p-2 border border-gray-300 rounded"
            name="region"
            value={formValues.region}
            onChange={handleInputChange}>
            <option value="">Select a region</option>{" "}
            <option value="Central">Central</option>{" "}
            <option value="Coast">Coast</option>{" "}
            <option value="Lower Eastern">Lower Eastern</option>
            <option value="Nairobi">Nairobi</option>{" "}
            <option value="North Eastern">North Eastern</option>{" "}
            <option value="North Rift">North Rift</option>{" "}
            <option value="Nyanza">Nyanza</option>
            <option value="South Rift">South Rift</option>{" "}
            <option value="Upper Eastern">Upper Eastern</option>{" "}
            <option value="Western">Western</option>
          </select>
          {errors.region && <p className="text-red-500">{errors.region[0]}</p>}
        </div>

        <div>
          <label htmlFor="county">County:</label>
          <select
            id="county"
            name="county"
            value={formValues.countyID}
            onChange={handleCountyChange}
            className="w-full p-2 border border-gray-300 rounded">
            <option value="">Select County</option>
            {counties.map((county) => (
              <option key={county.countyID} value={county.countyID}>
                {county.countyName}
              </option>
            ))}
          </select>
          {errors.county && <p className="text-red-500">{errors.county[0]}</p>}
        </div>

        <div>
          <label htmlFor="subCounty">Sub-County:</label>
          <select
            id="subCounty"
            name="subCounty"
            value={formValues.subCountyID}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded">
            <option value="">Select Sub-County</option>
            {subCounties.length === 0 ? (
              <option value="">Loading Sub-Counties...</option>
            ) : (
              subCounties.map((subCounty) => (
                <option
                  key={subCounty.SubCountyID}
                  value={subCounty.SubCountyName}>
                  {subCounty.SubCountyName}
                </option>
              ))
            )}
          </select>
        </div>

        <div>
          <label htmlFor="guardianName">Guardian Name:</label>
          <Input
            name="guardianName"
            placeholder="Guardian Name"
            value={formValues.guardianName}
            onChange={handleInputChange}
          />
          {errors.guardianName && (
            <p className="text-red-500">{errors.guardianName[0]}</p>
          )}
        </div>

        <div>
          <label htmlFor="guardianContact">Guardian Contact:</label>
          <Input
            name="guardianContact"
            placeholder="Guardian Contact"
            value={formValues.guardianContact}
            onChange={handleInputChange}
          />
          {errors.guardianContact && (
            <p className="text-red-500">{errors.guardianContact[0]}</p>
          )}
        </div>

        <div>
          <label htmlFor="emergencyCName">Emergency Contact Name:</label>
          <Input
            name="emergencyCName"
            placeholder="Emergency Contact Name"
            value={formValues.emergencyCName}
            onChange={handleInputChange}
          />
          {errors.emergencyCName && (
            <p className="text-red-500">{errors.emergencyCName[0]}</p>
          )}
        </div>

        <div>
          <label htmlFor="emergencyCNumber">Emergency Contact Number:</label>
          <Input
            name="emergencyCNumber"
            placeholder="Emergency Contact Number"
            value={formValues.emergencyCNumber}
            onChange={handleInputChange}
          />
          {errors.emergencyCNumber && (
            <p className="text-red-500">{errors.emergencyCNumber[0]}</p>
          )}
        </div>

        <div>
          <label htmlFor="emergencyCRelation">
            Emergency Contact Relation:
          </label>
          <select
            name="emergencyCRelation"
            value={formValues.emergencyCRelation}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded">
            <option value="">Select Relation</option>
            <option value="Parent">Parent</option>
            <option value="Sibling">Sibling</option>
            <option value="Spouse">Spouse</option>
            <option value="Child">Child</option>
            <option value="Friend">Friend</option>
            <option value="Relative">Relative</option>
          </select>
          {errors.emergencyCRelation && (
            <p className="text-red-500">{errors.emergencyCRelation[0]}</p>
          )}
        </div>

        <div>
          <label htmlFor="paymentStatus">Payment Status:</label>
          <select
            className="w-full p-2 border border-gray-300 rounded"
            name="paymentStatus"
            value={formValues.paymentStatus}
            onChange={handleInputChange}
            required>
            <option value="">Payment Status</option>
            <option value="Completed">Completed</option>
            <option value="Partially Paid">Partially Paid</option>
            <option value="Pending">Pending</option>
          </select>
          {errors.paymentStatus && (
            <p className="text-red-500">{errors.paymentStatus[0]}</p>
          )}
        </div>

        <div>
          <label htmlFor="marginalised Group">Marginalised Group:</label>
          <select
            className="w-full p-2 border border-gray-300 rounded"
            name="marginalised"
            value={formValues.marginalised}
            onChange={handleInputChange}
            required>
            <option value="">
              Are You from a Vulnerable Marginalised Group?
            </option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
          {errors.marginalised && (
            <p className="text-red-500">{errors.marginalised[0]}</p>
          )}
        </div>

        <div>
          <label htmlFor="atRisk">Any Risks?</label>
          <select
            className="w-full p-2 border border-gray-300 rounded"
            name="atRisk"
            value={formValues.atRisk}
            onChange={handleInputChange}
            required>
            <option value="">Any Risks?</option>
            <option value="None">None</option>
            <option value="Young people not in employment, education, or training">
              Young people not in employment, education, or training (NEET)
            </option>
            <option value="HIV Positive">Young people living with HIV</option>
            <option value="Adolescent Mothers">Adolescent mothers</option>
            <option value="Young people who use Alcohol & Drugs">
              Young people who use Alcohol & Drugs
            </option>
            <option value="Young people in Correctional Centers">
              Young people in Correctional Centers
            </option>
            <option value="Mental Health Issues">
              Young people with mental health issues
            </option>
            <option value="Orphans">Orphans</option>
            <option value="Young People from Low Income Households">
              Young People from Low Income Households
            </option>
            <option value="Heads of Households">
              Adolescent heads of households
            </option>
            <option value="Married Adolescents">Married adolescents</option>
            <option value="Disabilities">Young people with disabilities</option>
            <option value="Caregivers for Disabilities">
              Young people caring for persons with disabilities
            </option>
            <option value="Young Refugees">Young Refugees</option>
            <option value="Survivors of Violence">
              Young survivors of sexual violence, trafficking, and other forms
              of gender-based violence
            </option>
            <option value="Young People in Informal Settings (Slums)">
              Young People in informal settings (Slums)
            </option>
            <option value="Displaced Young People">
              Displaced Young People
            </option>
            <option value="Marginalised Groups">
              Minority linguistic, religious, and ethnic groups that are
              marginalised
            </option>
          </select>
          {errors.atRisk && <p className="text-red-500">{errors.atRisk[0]}</p>}
        </div>

        <div>
          <label htmlFor="passportPhoto">Passport Photo:</label>
          <Input
            name="passportPhoto"
            type="file"
            onChange={handleFileChange}
          />
          {errors.passportPhoto && (
            <p className="text-red-500">{errors.passportPhoto[0]}</p>
          )}
        </div>

        <div>
          <label htmlFor="doc">Consent Upload:</label>
          <Input name="doc" type="file" onChange={handleFileChange} />
          {errors.doc && (
            <p className="text-red-500">{errors.docUpload[0]}</p>
          )}
        </div>

        <div>
          <label htmlFor="notes">Notes:</label>
          <Input
            name="notes"
            placeholder="Notes"
            value={formValues.notes}
            onChange={handleInputChange}
          />
          {errors.notes && <p className="text-red-500">{errors.notes[0]}</p>}
        </div>
      </div>
    </form>
  );
};

export default ParticipantForm;

