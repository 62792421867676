import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { FaFilter } from "react-icons/fa";
import  {customStyles} from "../../styles/customStyles"
import { FaPlus } from "react-icons/fa6";
import { splitWordFunc } from "../../utils/splitWordFunc";
import { FiSearch } from "react-icons/fi";
import TrainingCategoryForm from '../../components/forms/trainingCategoryF';
import TrainingCategoryTable from '../../components/tables/trainingCategoryT';
import { addTrainingCategory as addTrainingCategoryService, getTrainingCategoryById, updateTrainingCategory, deleteTrainingCategory, getTrainingCategory } from '../../services/trainingCategoryS';
import Layout from '../../components/Layout/layout';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 

Modal.setAppElement('#root');
const AddTrainingCategory = () => {
  const [trainingcategory, setTrainingCategory] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newTrainingCategory, setNewTrainingCategory] = useState({
    categoryID: '',
    categoryName: '',
    notes: '',
  });
  const [selectedColumn, setSelectedColumn] = useState("name");
  const [filteredTrainingCategory, setFilteredTrainingCategory] = useState(trainingcategory);
  const [searchTerm, setSearchTerm] = useState("");
  const [update,setUpdate] = useState(false)
  const [errors, setErrors] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [selectedTrainingCategoryID, setSelectedTrainingCategoryID] = useState(null);

  useEffect(() => {
    const fetchedtrainingCategory = async () => {
      try {
        const fetchedTrainingCategory = await getTrainingCategory();
        setTrainingCategory(fetchedTrainingCategory);
        setFilteredTrainingCategory(fetchedTrainingCategory); // Initialize filtered participants
      } catch (error) {
        toast.error('Error fetching trainingCategory:', error.response.data);
      }
    };

    fetchedtrainingCategory();
  }, [update]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewTrainingCategory((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setNewTrainingCategory((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const addNewTrainingCategory = async () => {
    try {
      const trainingCategoryPayload = {
        categoryID: String(newTrainingCategory.categoryID), // Ensure TrainingCategoryID is a string
        categoryName: newTrainingCategory.categoryName,
        notes: newTrainingCategory.notes,
      };
  
      const addedtrainingCategory = await addTrainingCategoryService(trainingCategoryPayload);
      setUpdate((prev)=>!prev)
      setIsModalOpen(false);
    } catch (error) {
      toast.error('Error adding trainingCategory:', error.response.data);
      setErrors(error.response.data.errors || {});
      alert(`Failed to add trainingCategory: ${error.response.data.title}\nDetails: ${JSON.stringify(error.response.data.errors, null, 2)}`);
    }
  };
  

  const openAddTrainingCategoryModal = () => {
    setEditMode(false);
    setIsModalOpen(true);
    setNewTrainingCategory({
      categoryID: '',
      categoryName: '',
      notes: '',
    });
  };

  const openEditTrainingCategoryModal = async (categoryID) => {
    try {
      const fetchedTrainingCategory = await getTrainingCategoryById(String(categoryID));
      setEditMode(true);
      setIsModalOpen(true);
      setSelectedTrainingCategoryID(categoryID);
      setNewTrainingCategory({
        ...fetchedTrainingCategory,
      });
    } catch (error) {
      toast.error(`Error fetching trainingCategory with ID ${categoryID}:`, error.response.data);
    }
  };

  const updateExistingTrainingCategory = async () => {
    try {
      const trainingCategoryPayload = { ...newTrainingCategory };

      await updateTrainingCategory(selectedTrainingCategoryID, trainingCategoryPayload);
      setUpdate((prev)=>!prev)
      setIsModalOpen(false);
    } catch (error) {
      toast.error(`Error updating trainingCategory with ID ${selectedTrainingCategoryID}:`, error.response.data);
      setErrors(error.response.data.errors || {});
      alert(`Failed to update trainingCategory: ${error.response.data.title}\nDetails: ${JSON.stringify(error.response.data.errors, null, 2)}`);
    }
  };

  const deleteExistingTrainingCategory = async (categoryID) => {
    try {
      await deleteTrainingCategory(categoryID);
      setUpdate((prev)=>!prev) 
    } catch (error) {
      toast.error(`Error deleting trainingCategory with ID ${categoryID}:`, error.response.data);
      alert(`Failed to delete trainingCategory: ${error.response.data.title}`);
    }
  };

  const closeAddTrainingCategoryModal = () => {
    setIsModalOpen(false);
    setEditMode(false);
    setErrors({});
  };

  const deleteTrainingCategoryHandler = (categoryID) => {
    if (window.confirm(`Are you sure you want to delete trainingCategory with ID ${categoryID}?`)) {
      deleteExistingTrainingCategory(categoryID);
    }
  };

  //Search
  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    filterTrainingCategory(value, selectedColumn);
  };
  const handleFilterParameterChange = (event) => {
    const value = event.target.value;
    setSelectedColumn(value);
    filterTrainingCategory(searchTerm, value);
  };

  const filterTrainingCategory = (searchTerm, column) => {
    const filtered = trainingcategory.filter((trainingCategory) =>
      trainingCategory[column]
        .toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
    setFilteredTrainingCategory(filtered);
  };

  return (
    <Layout>
  <div className="bg-white rounded-lg shadow-lg">
    <div className="px-6 py-4 border-b border-gray-200">
      <h1 className="text-2xl font-semibold text-gray-800">Category Types</h1>
    </div>

    <div className="p-6">
      <div className="space-y-4 md:space-y-0 md:flex md:items-center md:justify-between mb-6">
        <button
          onClick={openAddTrainingCategoryModal}
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-all duration-200"
        >
          <FaPlus className="mr-2 h-4 w-4" />
          <span>Add Category</span>
        </button>

        <form className="flex flex-col md:flex-row gap-4 flex-1 md:max-w-2xl">
          <div className="relative flex-1">
            <select
              className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-md bg-white shadow-sm"
              value={selectedColumn}
              onChange={handleFilterParameterChange}
            >
              {trainingcategory.length > 0 ? (
                Object.keys(trainingcategory[0])?.map((op) => (
                  <option key={op} value={op}>
                    {splitWordFunc(op)}
                  </option>
                ))
              ) : (
                <option>No filter property</option>
              )}
            </select>
            <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <FaFilter className="h-4 w-4 text-gray-400" />
            </div>
          </div>

          <div className="relative flex-1">
            <input
              type="text"
              placeholder="Search..."
              onChange={handleSearchChange}
              className="block w-full pl-3 pr-10 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
            <div className="absolute inset-y-0 right-0 flex items-center pr-3">
              <FiSearch className="h-5 w-5 text-gray-400" />
            </div>
          </div>
        </form>
      </div>

      <TrainingCategoryTable
        trainingcategory={filteredTrainingCategory}
        openEditModal={openEditTrainingCategoryModal}
        deleteTrainingCategory={deleteTrainingCategoryHandler}
      />
    </div>

    <Modal
      style={customStyles}
      isOpen={isModalOpen}
      onRequestClose={closeAddTrainingCategoryModal}
      contentLabel={editMode ? "Edit Category Types" : "Add Training Category"}
    >
      <h2 className="text-xl font-semibold text-gray-800 mb-4">
        {editMode ? "Edit Category Types" : "Add Category Types"}
      </h2>
      <TrainingCategoryForm
        formValues={newTrainingCategory}
        handleInputChange={handleInputChange}
        handleDateChange={handleDateChange}
        errors={errors}
      />
      <div className="flex justify-end mt-4">
        <button
          onClick={editMode ? updateExistingTrainingCategory : addNewTrainingCategory}
          className="bg-indigo-600 text-white px-5 py-2 rounded mr-2 transition-all duration-200 hover:bg-indigo-700"
        >
          {editMode ? "Update" : "Save"}
        </button>
        <button
          onClick={closeAddTrainingCategoryModal}
          className="border border-gray-300 text-gray-700 px-5 py-2 rounded hover:bg-gray-100 transition-all duration-200"
        >
          Cancel
        </button>
      </div>
    </Modal>
  </div>
</Layout>

  );
};

export default AddTrainingCategory;
