import React from 'react';
import Input from '../common/Input';
import styles from '../../styles/modal.module.css';

const CTypeForm = ({ formValues, handleInputChange, handleDateChange, errors }) => {
  return (
    <form className={styles.form}>
      <div className="space-y-4">
      <div>
          <label htmlFor="ID">ID:</label>
          <Input
            name="awardCTypeID"
            placeholder="ID"
            value={formValues.awardCTypeID}
            onChange={handleInputChange}
          />
          {errors.awardCTypeID && <p className="text-red-500">{errors.awardCTypeID[0]}</p>}
        </div>
        <div>
          <label htmlFor="centerName">Category:</label>
          <Input
            name="centerName"
            placeholder="Center Name"
            value={formValues.centerName}
            onChange={handleInputChange}
          />
          {errors.centerName && <p className="text-red-500">{errors.centerName[0]}</p>}
        </div>
        <div>
          <label htmlFor="notes">Notes:</label>
          <Input
            name="notes"
            placeholder="Notes"
            value={formValues.notes}
            onChange={handleInputChange}
          />
          {errors.notes && <p className="text-red-500">{errors.notes[0]}</p>}
        </div>
      </div>
    </form>
  );
};

export default CTypeForm;
