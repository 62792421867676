import apiClient from './apiClient';
import { logAction } from '../utils/logger';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 

const addProject = async (projectData) => {
  try {
    const response = await apiClient.post('/api/Projects', projectData);
    if(response){
      logAction("Created Project","Project",`Created the following Project ${JSON.stringify(response.data)}`)
    }
    return response.data;
  } catch (error) {
    if (error.response) {
      toast.error('Error in addProject:', error.response.data);
      toast.error('Validation Errors:', error.response.data.errors);
    } else {
      toast.error('Error in addProject:', error.message);
    }
    throw error;
  }
};

const getProjectById = async (projectID) => {
  try {
    const response = await apiClient.get(`/api/Projects/${projectID}`);
    return response.data;
  } catch (error) {
    toast.error(`Error fetching project with ID ${projectID}:`, error.response.data);
    throw error;
  }
};

const updateProject = async (projectID, projectData) => {
  try {
    const response = await apiClient.put(`/api/Projects/${projectID}`, projectData);
    if(response){
      logAction("Update Project","Project",`Update the following Project ${JSON.stringify(response.data)}`)
    }
    return response.data;
  } catch (error) {
    if (error.response) {
      toast.error('Error in updateProject:', error.response.data);
      toast.error('Validation Errors:', error.response.data.errors);
    } else {
      toast.error('Error in updateProject:', error.message);
    }
    throw error;
  }
};

const deleteProject = async (projectID) => {
  try {
    const response = await apiClient.delete(`/api/Projects/${projectID}`);
    if(response){
      logAction("Deleted Project","Project",`Deleted the following Project ${JSON.stringify(response.data)}`)
    }
    return response.data;
  } catch (error) {
    if (error.response) {
      toast.error('Error in deleteProject:', error.response.data);
      toast.error('Validation Errors:', error.response.data.errors);
    } else {
      toast.error('Error in deleteProject:', error.message);
    }
    throw error;
  }
};

const getProjects = async () => {
  try {
    const response = await apiClient.get('/api/Projects');
    return response.data;
  } catch (error) {
    if (error.response) {
      toast.error('Error in getProjects:', error.response.data);
      toast.error('Validation Errors:', error.response.data.errors);
    } else {
      toast.error('Error in getProjects:', error.message);
    }
    throw error;
  }
};

export { addProject, getProjectById, updateProject, deleteProject, getProjects };
