import React, { useState, useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import Layout from "../../components/Layout/layout";
import { formatPDFReport } from "../../utils/pdfFormatting";
import { formatExcelReport } from "../../utils/excelFormatting";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import {
  fetchTrainingsData,
  trainingsHeaders,
} from "../../services/reportsServices/reportTrainingsS";
import { Printer, FileSpreadsheet, FileText, RefreshCw } from "lucide-react";

const TrainingR = () => {
  const [selectedReport, setSelectedReport] = useState("");
  const [reportData, setReportData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [uniqueOptions, setUniqueOptions] = useState({});
  const [filters, setFilters] = useState({
    trainingName: "",
    institutionName: "",
    venue: "",
    dateRange: ["", ""],
    categories: "",
    subCounty: "",
    county: "",
    coordinator: "",
    trainingLevel: "",
    trainingType: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (selectedReport === "Training") {
      fetchData();
    }
  }, [selectedReport]);

  const fetchData = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const data = await fetchTrainingsData();
      setReportData(data);
      setFilteredData(data);
      extractUniqueOptions(data);
    } catch (error) {
      setError("Error fetching data. Please try again.");
      toast.error("Error fetching Training data:", error);
    }
    setIsLoading(false);
  };

  const extractUniqueOptions = (data) => {
    const options = {};
    const filterFields = [
      "trainingName",
      "institutionName",
      "venue",
      "categories",
      "subCounty",
      "county",
      "coordinator",
      "trainingLevel",
      "trainingType",
    ];
    filterFields.forEach((field) => {
      options[field] = [...new Set(data.map((item) => item[field]))];
    });
    setUniqueOptions(options);
  };

  useEffect(() => {
    applyFilters();
  }, [filters, reportData]);

  const applyFilters = () => {
    let filtered = reportData;

    Object.entries(filters).forEach(([key, value]) => {
      if (value) {
        if (key === "dateRange") {
          const [startDate, endDate] = value;
          if (startDate && endDate) {
            filtered = filtered.filter((item) => {
              const itemDate = new Date(item.date);
              return (
                itemDate >= new Date(startDate) && itemDate <= new Date(endDate)
              );
            });
          }
        } else {
          filtered = filtered.filter((item) => item[key] === value);
        }
      }
    });

    setFilteredData(filtered);
  };

  const handleExport = async (format) => {
    if (filteredData.length === 0) {
      alert("No data available for export.");
      return;
    }

    const headers = trainingsHeaders;
    if (format === "PDF") {
      const pdfBlob = await formatPDFReport(
        selectedReport,
        filteredData,
        headers
      );
      const pdfUrl = URL.createObjectURL(pdfBlob);
      window.open(pdfUrl, "_blank");
    } else if (format === "Excel") {
      formatExcelReport(selectedReport, filteredData, headers);
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  const handleDateRangeChange = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({
      ...prev,
      dateRange:
        name === "startDate"
          ? [value, prev.dateRange[1]]
          : [prev.dateRange[0], value],
    }));
  };

  const clearFilters = () => {
    setFilters({
      trainingName: "",
      institutionName: "",
      venue: "",
      dateRange: ["", ""],
      categories: "",
      subCounty: "",
      county: "",
      coordinator: "",
      trainingLevel: "",
      trainingType: "",
    });
  };

  const handlePrint = async () => {
    if (filteredData.length === 0) {
      alert("No data available to print.");
      return;
    }

    try {
      const headers = trainingsHeaders;
      const pdfBlob = await formatPDFReport(
        selectedReport,
        filteredData,
        headers
      );

      const pdfUrl = URL.createObjectURL(pdfBlob);
      window.open(pdfUrl, "_blank");

      const printFrame = document.createElement("iframe");
      printFrame.style.display = "none";
      document.body.appendChild(printFrame);

      printFrame.src = pdfUrl;

      printFrame.onload = () => {
        try {
          printFrame.contentWindow.print();
        } catch (error) {
          toast.error("Printing failed:", error);
          alert(
            "Printing failed. Please try again or use the browser print function from the preview tab."
          );
        }

        setTimeout(() => {
          document.body.removeChild(printFrame);
          URL.revokeObjectURL(pdfUrl);
        }, 1000);
      };
    } catch (error) {
      toast.error("Error during print:", error);
      alert("An error occurred while trying to print. Please try again.");
    }
  };

  return (
    <Layout>
      <div className="reports-page p-4">
        <h1 className="text-3xl font-bold mb-6">Reports</h1>

        <div className="bg-white shadow-md rounded-lg p-4 mb-6">
          <h2 className="text-xl font-bold mb-4 border-b-2 border-blue-300 pb-2">
            Available Reports
          </h2>
          <ul className="space-y-2">
            <li>
              <button
                onClick={() => setSelectedReport("Training")}
                className={`w-full text-left p-2 flex items-center ${
                  selectedReport === "Training" ? "bg-gray-200" : "bg-white"
                } hover:bg-gray-100`}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/assets/checked.png`}
                  alt="Checkmark"
                  className={`w-6 h-6 ${
                    selectedReport === "Training" ? "block" : "hidden"
                  } mr-3`}
                />
                <span
                  className={`font-semibold ${
                    selectedReport === "Training"
                      ? "text-blue-600"
                      : "text-gray-800"
                  }`}
                >
                  Trainings
                </span>
              </button>
            </li>
          </ul>
        </div>

        {selectedReport === "Training" && (
          <div className="bg-white shadow-md rounded-lg p-4 mb-6">
            <h2 className="text-xl font-bold mb-4">Training Report</h2>
            {isLoading ? (
              <p>Loading data...</p>
            ) : error ? (
              <p className="text-red-500">{error}</p>
            ) : (
              <>
                <div className="flex flex-col sm:flex-row justify-between items-center mb-4">
                  <h3 className="text-lg font-semibold mb-2 sm:mb-0">
                    Filters
                  </h3>
                  <div className="flex flex-wrap justify-center sm:justify-end gap-2">
                    <button
                      onClick={clearFilters}
                      className="bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400 flex items-center"
                    >
                      <RefreshCw className="w-4 h-4 mr-2" /> Clear Filters
                    </button>
                    <button
                      onClick={() => handleExport("PDF")}
                      className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700 flex items-center"
                    >
                      <FileText className="w-4 h-4 mr-2" /> Export to PDF
                    </button>
                    <button
                      onClick={() => handleExport("Excel")}
                      className="bg-green-600 text-white px-4 py-2 rounded hover:bg-green-700 flex items-center"
                    >
                      <FileSpreadsheet className="w-4 h-4 mr-2" /> Export to
                      Excel
                    </button>
                    <button
                      onClick={handlePrint}
                      className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 flex items-center"
                    >
                      <Printer className="w-4 h-4 mr-2" /> Print
                    </button>
                  </div>
                </div>
                <div className="grid grid-cols-3 gap-4 mb-4">
                  {[
                    "trainingName",
                    "institutionName",
                    "venue",
                    "categories",
                    "subCounty",
                    "county",
                    "coordinator",
                    "trainingLevel",
                    "trainingType",
                  ].map((field) => (
                    <div key={field}>
                      <label
                        className="block text-gray-700 text-sm font-bold mb-1"
                        htmlFor={field}
                      >
                        {field.charAt(0).toUpperCase() + field.slice(1)}
                      </label>
                      <select
                        name={field}
                        id={field}
                        value={filters[field]}
                        onChange={handleFilterChange}
                        className="w-full px-2 py-1 text-sm border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-200"
                      >
                        <option value="">All</option>
                        {uniqueOptions[field]?.map((option, index) => (
                          <option key={index} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                  ))}
                  <div>
                    <label
                      className="block text-gray-700 text-sm font-bold mb-1"
                      htmlFor="startDate"
                    >
                      Date (From)
                    </label>
                    <input
                      type="date"
                      name="startDate"
                      id="startDate"
                      value={filters.dateRange[0]}
                      onChange={handleDateRangeChange}
                      className="w-full px-2 py-1 text-sm border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-200"
                    />
                  </div>
                  <div>
                    <label
                      className="block text-gray-700 text-sm font-bold mb-1"
                      htmlFor="endDate"
                    >
                      Date (To)
                    </label>
                    <input
                      type="date"
                      name="endDate"
                      id="endDate"
                      value={filters.dateRange[1]}
                      onChange={handleDateRangeChange}
                      className="w-full px-2 py-1 text-sm border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-200"
                    />
                  </div>
                </div>
                <div className="overflow-x-auto">
                  <h3 className="text-lg font-bold mb-2">Filtered Results</h3>
                  <p className="text-red-500 font-semibold text-2xl">
                    Total Records: {filteredData.length}
                  </p>
                  <div className="max-h-96 overflow-y-auto">
                    <table className="w-full bg-white border border-gray-300">
                      <thead className="sticky top-0 bg-white">
                        <tr>
                          {trainingsHeaders.map((header, index) => (
                            <th key={index} className="py-2 px-4 border-b">
                              {header.Header}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {filteredData.map((item, index) => (
                          <tr key={index}>
                            {trainingsHeaders.map((header, idx) => (
                              <td
                                key={idx}
                                className="py-2 px-4 border-b whitespace-nowrap"
                              >
                                {item[header.accessor]}
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </Layout>
  );
};

export default TrainingR;